import { Card, Table, Select, Input, Button, Badge, Menu, notification, Spin, Tag } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductListData from "assets/data/product-list.data.json"
import { AUTH_TOKEN } from 'constants/StorageConstant';
import network from 'network/network';
import helperFunc from 'helpers/helperFunc';
import tools from 'tools'
import { Helmet } from 'react-helmet';
import Flex from 'components/shared-components/Flex';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined,RightOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import globalConstant from 'constants/globalConstant';
import NumberFormat from 'react-number-format';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectProductList } from 'utils/redux/actions/productAction';


  const { Option } = Select

  const Product = (props) => {
    let navigate = useNavigate();
    const [list, setList] = useState(ProductListData)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loadingPanel, setLoadingPanel] = useState(false)
    const [searchValue, setSearchValue] = useState("");
  
    useEffect(() => {
  
  
      selectProductList();
    }, []);
  
    const selectProductList = () => {
  
      var params = {
        authToken: localStorage.getItem(AUTH_TOKEN)
      }
      props.selectProductListAction(params);
    }
  
  
    const addProduct = () => {
      navigate(`/product/add/0`)
    }
  
    const viewDetails = row => {
      navigate(`/product/add/${row.productid}`)
    }
  
  
  
    const deleteRowConfirm = async (row) => {
  
  
  
      setLoadingPanel(true);
      var params = {
        productId: row.productid,
        authToken: localStorage.getItem(AUTH_TOKEN)
      }
  
      var result = await network.get("/admin/Products/deleteProduct", params, false);
  
      setTimeout(() => {
        setLoadingPanel(false);
      }, 250);
  
      if (result.isSuccess) {
  
        var data = result.data;
        if (data.isSuccess) {
  
          notification.success({
            message: `Başarılı`,
            description: "Ürün silme işlemi başarılı bir şekilde gerçekleştirildi",
            placement: "topRight",
          });
          selectProductList();
  
        } else {
          notification.error({
            message: `Hata!`,
            description: data.errorMessage,
            // placement:"topRight",
          });
        }
      } else {
        notification.error({
          message: `Hata!`,
          description: result.errorMessage,
          placement: "topRight",
        });
      }
    }
    const deleteRowPrevious = (row) => {
  
      helperFunc.showConfirm({
        content: `Ürün kalıcı olarak silinecek. Devam edilsin mi?`,
        title: "Hatırlatma", onConfirm: () => deleteRowConfirm(row)
      });
    }
    const changeProductStatusPreview =(row)=>{
  
      helperFunc.showConfirm({
        content: `Ürün durumu ${row.status ? "Pasif":"Aktif"} olarak değiştirilecek. Devam edilsin mi?`,
        title: "Hatırlatma", onConfirm: () => changeProductStatus(row)
      });
    }
    const changeProductStatus =async(row)=>{
  
      setLoadingPanel(true);
      var params = {
        productId: row.productid,
        status : !row.status
      }
  
      var result = await network.get("/admin/Products/changeProductStatus", params, false);
  
      setTimeout(() => {
        setLoadingPanel(false);
      }, 250);
  
      if (result.isSuccess) {
  
        var data = result.data;
        if (data.isSuccess) {
  
          notification.success({
            message: `Başarılı`,
            description: "Ürün durumu başarılı bir şekilde değiştirildi.",
            placement: "topRight",
          });
          selectProductList();
  
        } else {
          notification.error({
            message: `Hata!`,
            description: data.errorMessage,
            // placement:"topRight",
          });
        }
      } else {
        notification.error({
          message: `Hata!`,
          description: result.errorMessage,
          placement: "topRight",
        });
      }
    }
  
    const onSearch = e => {
      const value = e.currentTarget.value
  
      setSearchValue(value);
    }
  
    const handleShowCategory = value => {
      if (value !== 'All') {
        const key = 'category'
        const data = tools.filterArray(ProductListData, key, value)
        setList(data)
      } else {
        setList(ProductListData)
      }
    }
  
  
    const dropdownMenu = row => {
      
        return [
            {
              label:"Ürün Detayı",
              key: '0',
              icon: <EyeOutlined />,
              onClick: ()=> viewDetails(row)
            },
            {
              label: (<span style={{color:row.status ? "red" : "green"}}>{row.status ? "Pasife Al": "Aktife Al"}</span>),
              key: '1',
              icon: <RightOutlined />,
              onClick : () => changeProductStatusPreview(row)
            },
            {
              label: (<span >{selectedRows.length > 0 ? `Delete (${selectedRows.length})` : 'Sil'}</span>),
              key: '3',
              icon: <DeleteOutlined />,
              onClick: () => deleteRowPrevious(row)
            }
            ];
      }
    const tableColumns = [
      {
        title: 'id',
        dataIndex: 'productid'
      },
      {
        title: 'Ürün Adı',
        dataIndex: 'productname',
        render: (_, record) => (
          <div className="d-flex">
            {/* <AvatarStatus size={60} type="square" src={"https://www.vhv.rs/dpng/d/457-4570014_amazon-products-png-transparent-png.png"} name={record.PRODUCTNAME}/> */}
            <AvatarStatus size={30}
              src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.productImage.type, record.productimage)}
              name={record.productname} />
          </div>
        ),
        sorter: (a, b) => tools.antdTableSorter(a, b, 'productname')
      },
      {
        title: 'Kategori',
        dataIndex: 'groupname',
        sorter: (a, b) => tools.antdTableSorter(a, b, 'groupname')
      },
      {
        title: 'Fiyat',
        dataIndex: 'productprice',
        render: price => (
          <div>
            <NumberFormat
              displayType={'text'}
              value={(Math.round(price * 100) / 100).toFixed(2)}
              prefix={'₺'}
              thousandSeparator={true}
            />
          </div>
        ),
        sorter: (a, b) => tools.antdTableSorter(a, b, 'productprice')
      }, 
      {
        title: 'Durumu',
        dataIndex: 'status',
        render: (_, record) => (
          <><Tag color={record.status ?"green" : "red"}>{record.status ? "Aktif" :"Pasif"}</Tag></>
        )
      },
      {
        title: '',
        dataIndex: 'actions',
        render: (_, elm) => (
          <div className="text-right">
            <EllipsisDropdown menu={{items:dropdownMenu(elm)}} />
          </div>
        )
      },
    
    ];
  
  
    var data = JSON.parse(JSON.stringify(props.productList));
    if (searchValue.length>0) {
      data = data.filter(function (el) {
        return (el.productname.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 || 
        (el.productid ||"").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
        (el.groupname ||"").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1)
        });
    }
  
   
  
    return (
      <Spin spinning={loadingPanel} indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} >
        <Helmet title='Ürün Listesi' />
        <Card>
          <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
            <Flex className="mb-1" mobileFlex={false}>
              <div className="mr-md-3 mb-3">
                <Input placeholder="Ürünlerde Ara" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
              </div>
              {/* <div className="mb-3">
              <Select 
                defaultValue="All" 
                className="w-100" 
                style={{ minWidth: 180 }} 
                onChange={handleShowCategory} 
                placeholder="Category"
              >
                <Option value="All">All</Option>
                {
                  categories.map(elm => (
                    <Option key={elm} value={elm}>{elm}</Option>
                  ))
                }
              </Select>
            </div> */}
            </Flex>
            <div>
              <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>Ürün Ekle</Button>
            </div>
          </Flex>
          <div className="table-responsive">
            <Table
              columns={tableColumns}
              loading={props.isLoading}
              dataSource={data}
              rowKey='id'
  
            />
          </div>
        </Card>
      </Spin>
    )
  }
  
  
  function mapStateToProps(state) {
  
    return {
      isLoading: state.productReducer.isLoading,
      productList: state.productReducer.productList,
      hasError: state.productReducer.hasError,
      errorMessage: state.productReducer.errorMessage,
  
  
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      selectProductListAction: bindActionCreators(selectProductList, dispatch),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Product);