import React, { Component, useEffect, useState } from 'react'
import network from 'network/network';
import Flex from 'components/shared-components/Flex';
import { ArrowUpOutlined, SortDescendingOutlined, SortAscendingOutlined, RollbackOutlined, ArrowDownOutlined, ArrowRightOutlined, EyeOutlined, EditOutlined, SearchOutlined, PlusCircleOutlined, MessageOutlined, DeleteOutlined, CopyOutlined, FieldTimeOutlined, TeamOutlined, CheckOutlined, ReadOutlined, LoadingOutlined, CalendarOutlined, WhatsAppOutlined, CheckCircleOutlined, FolderAddOutlined, FormOutlined, LineChartOutlined } from '@ant-design/icons';
import { Modal, Col, Card, Table, Select, Input, Button, Badge, Menu, notification, Spin, Tag, Row, Tooltip } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { Progress } from 'antd';
import helperFunc from 'helpers/helperFunc';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';

const Campaigns = (props) => {

    const { t, i18n } = useTranslation();
    const [control, setControl] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [selectStatusValue, setSelectStatusValue] = useState("");
    const [datas, setDatas] = useState([]);
    const [loadingPanel, setLoadingPanel] = useState(true)
    const [campaignDetail, setCampaignDetail] = useState();
    const [totalCredit, setTotalCredit] = useState(0);
   

    useEffect(()=>{
        selectTotalCredit();
    }, []);
    const onSearch = e => {
        const value = e.currentTarget.value
        setSearchValue(value);
    } 
    const onSelectFilter = e => {
        const value = e.value
        setSelectValue(value);

    }
    
    const selectTotalCredit =async()=>{

        const result = await network.get("/admin/campaign/total-credit");
        if (result.isSuccess) {
            setTotalCredit(result.data.creditCount);
        }

    }
    const hoursDate = (date) => {
        var t = new Date(date);
        var hours = t.getHours();
        var minutes = t.getMinutes().toString().padStart(2, "0");
        return <>{(hours + " : " + minutes)}</>
    }
    const SplitDate = (date) => {
        const months = i18n.language === 'tr' ?
            ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Agustos", "Eylül", "Ekim", "Kasım", "Aralık"] :
            ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const tDate = new Date(date); //Dile göre dil değişikliği
        const day = tDate.getDate();
        const month = tDate.getMonth();
        const year = tDate.getFullYear();

        return (
            <>
                {`${day} ${months[month]} ${year}`}
            </>
        );

    }

    useEffect(() => {
        responseData();

    }, [])

    const responseData = async () => {
        setLoadingPanel(true);
        var result = await network.get("/admin/Campaign/selectCampaignList", false);
        if (result.isSuccess) {
            var data = result.data;

            setDatas(data.data?.campaigns);
            setCampaignDetail(data.data?.campaignStatistics);
            console.log(data.data?.campaignStatistics);
            setControl(true);
        } else {
            alert(result.errorMessage);
        }

        setLoadingPanel(false);

    }


    const info = (campaign) => {
        Modal.info({
            title: 'Gönderilen Mesaj Türü : ' + campaign.templateType,
            content: (
                <Col style={{ background: "#e5ddd5", borderRadius: 10 }} className='mt-1' >
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={20} >

                            <div className=" mt-4 mr-2 p-3 " style={{ background: "#d3eebe", borderRadius: 10 }}>
                                {/* <div align="center" className='mb-1' >
                                    <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
                                </div> */}
                                <div className=' ml-2 mr-2 p-1 d-flex align-items-center justify-content-center' >
                                </div>
                                {campaign.data}
                            </div>
                            <div className='my-1 '></div>
                            {/* <Col span={24}>
                                <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                    <a>Ödeme Yap<RollbackOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                </div>
                                <div className='my-1 '></div>
                                <div className='mr-2 p-1 d-flex align-items-center justify-content-center mb-3' style={{ background: "#d3eebe", borderRadius: 5 }} >
                                    <a>Evet<ArrowRightOutlined className='ml-2' style={{ fontSize: '18px' }} /></a>
                                </div>
                            </Col> */}
                        </Col>

                    </Row>

                </Col>
            ),
            onOk() { },
        });
    };

    const deleteRowPrevious = (row) => {

        helperFunc.showConfirm({
            content: `Ürün kalıcı olarak silinecek. Devam edilsin mi?`,
            title: "Hatırlatma", onConfirm: () => deleteRowConfirm(row)
        });
    }
    const deleteRowConfirm = async (row) => {


        setLoadingPanel(true);
        var params = {
            id: row.campaignID
        }

        var result = await network.get("/admin/Campaign/DeleteById", params);
        responseData();
        setTimeout(() => {
            setLoadingPanel(false);
        }, 400);

        if (result.isSuccess) {

            var data = result.data;
            if (data.isSuccess) {

                notification.success({
                    message: `Başarılı`,
                    description: "Ürün silme işlemi başarılı bir şekilde gerçekleştirildi",
                    placement: "topRight",
                });

            } else {
                notification.error({
                    message: `Hata!`,
                    description: data.errorMessage,
                    // placement:"topRight",
                });
            }
        } else {
            notification.error({
                message: `Hata!`,
                description: result.errorMessage,
                placement: "topRight",
            });
        }
    }

    function formatNumber(num) {
        if (Number.isInteger(num)) {
            // Sayı tam ise, olduğu gibi döndür
            return num;
        } else {
            // Sayı virgüllü (ondalıklı) ise, toFixed kullanarak formatla
            return num.toFixed(2); // 2 basamağa yuvarlar
        }
    }
    const getCircleBar = (record) => {

        const numberOfPercent = isNaN(Math.round((record.statistics.deliveredCount) / (record.statistics.totalPersonCount) * 100)) ? 0
            : Math.round((record.statistics.deliveredCount) / (record.statistics.totalPersonCount) * 100);
        const percent = formatNumber(numberOfPercent);
        const successPercent = percent;
        let circleColor = "rgb(82, 196, 26)";
        if (numberOfPercent < 50) {
            circleColor = "#b82a23";
        } else if (numberOfPercent < 80) {
            circleColor = "rgb(243, 198, 35)";
        }

        return (
            <Progress
                percent={percent}
                success={{
                    percent: successPercent,
                    strokeColor: circleColor,
                }}
                status='normal'
                trailColor='rgba(0, 0, 0, 0.06)'
                size={70}
                type="circle"
            />
        );
    }
    const [columns, setColumns] = useState([
        {
            title: t('campaignDetails'),
            dataIndex: 'campaignsName',
            key: 'campaignsName',
            width: '180px',
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item mb-2 px-2 pb-3 font-weight-bold">{record.campaignName}
                            {/* <Tag className="ml-1" color={getStatusColor(record.campaignStatus)}>{getStatusText(record.campaignStatus)}</Tag> */}
                        </div>

                        <div className="item my-1 p-1 ml-1">
                            <Tooltip title={`${record.firmBusinessNumber}`} >
                                {record.firmName}
                            </Tooltip>
                        </div>
                        <div className="item mt-1 mb-1 p-1">
                            <span style={{ fontSize: '16px' }} className='ml-1 mr-1'><CalendarOutlined /> </span>

                            {SplitDate(record.insertDate)}
                            <span style={{ fontSize: '16px' }} className='ml-1 mr-1'> <FieldTimeOutlined />   </span>

                            {hoursDate(record.insertDate)}
                        </div>
                        {/* <div className="item mt-1 mb-1 p-1">
                            <span style={{ fontSize: '16px' }} className='ml-1 mr-1'> <FieldTimeOutlined />   </span>

                            {hoursDate(record.insertDate)}

                        </div> */}

                    </div>
                </div>
            )
        },
        {
            title: t('sentGroups'),
            dataIndex: 'sendGrup',
            width: '180px',
            key: 'sendGrup',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'createDate'),
            align: 'center',
            render: (_, record) => (
                <div>

                    {record.campaignGroups.map(index => <Tag className='m-1' color={"blue"}>{index.groupname} </Tag>)}

                </div>
            )
        },
        {
            title: t('sentDetails'),
            dataIndex: 'recipients',
            key: 'recipients',
            width: '450px',
            align: 'center',

            render: (_, record) => (
                <div className='w-full d-flex  flex-row align-items-center justify-content-center'>
                    <Flex gap="middle" alignItems='center' justifyContent='around'  >

                        <div className="d-inline">
                            <div className="d-inline-block  mt-2 " style={{ width: "80px", height: "50px" }}>
                                <div className='d-block ' style={{ fontSize: '20px' }}><TeamOutlined /></div>
                                <div style={{ fontSize: '14px' }}>{record.statistics.totalPersonCount} {t('recipient')}</div>
                            </div>
                            <div className="d-inline-block  " style={{ width: "100px", height: "50px" }}>
                                <span className='d-block ' style={{ fontSize: '20px' }}>
                                    <h2 style={{ margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2></span>
                                <span className='d-block' style={{ fontSize: '14px' }} >{record.statistics.deliveredCount} {t('delivered')} </span>
                            </div>
                            <div className="d-inline-block  " style={{ width: "80px  ", height: "50px" }}>
                                <span className='d-block ' style={{ fontSize: '20px' }}>   <h2 style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2></span>
                                <span className='d-block ' style={{ fontSize: '14px' }} >{record.statistics.readCount} {t('read')}</span>
                            </div>
                        </div>
                        <div className='mt-2 mb-1 ml-32'>
                            <Tooltip className=''
                                title={
                                    <>
                                        {t('recipient')}/{t('delivered')}: %{formatNumber(Math.round((record.statistics.deliveredCount) / (record.statistics.totalPersonCount) * 100) || 0)}
                                        <br />
                                        {t('recipient')}/{t("read")}: %{formatNumber(Math.round((record.statistics.readCount) / (record.statistics.totalPersonCount) * 100) || 0)}
                                    </>
                                }
                            >
                                {getCircleBar(record)}
                            </Tooltip>
                        </div>
                    </Flex>
                </div>
            )
        },
        {
            title: '',
            dataIndex: 'id',
            width: 200,
            align: 'center',
            render: (_, elm) => {
                return (
                    <div  >
                        <span>
                            <Tooltip title={t('showCampaignDetail')}>
                                <Link to={{
                                    pathname: `/campaign-detail/${elm.campaignID}`
                                }} aria-label="Favorite" >
                                    <div className=' d-flex  justify-content-center'  >
                                        {/* <EyeOutlined style={{ fontSize: '19px' }} twoToneColor='red' /> */}
                                        <LineChartOutlined style={{ fontSize: '25px' }} />
                                        <span className='d-block ml-2' style={{ fontSize: '14px' }} >{t("statistics")} </span>
                                    </div>
                                </Link>
                            </Tooltip>
                        </span>
                    </div>


                )
            }

        },

    ])
    var data = JSON.parse(JSON.stringify(datas));
    if (searchValue.length > 0) {
        data = data.filter(function (el) {
            return (el.campaignName.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
                (el.campaignID || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1
            )

        });
    }

    if (selectStatusValue != "0") {
        data = data.filter(function (el) {
            return (
                el.campaignStatus.toString().toUpperCase().indexOf(selectStatusValue.toUpperCase()) != -1
            )
        });

    }
    if (selectValue == "0") {

        data = data.sort(function (a, b) {
            var firstValue = a.campaignName.toUpperCase();
            var secondValue = b.campaignName.toUpperCase();
            if (firstValue < secondValue) { return -1; }
            if (firstValue > secondValue) { return 1; }
            return 0;
        });
    }
    if (selectValue == "1") {
        data = data.sort(function (a, b) {
            var firstValue = a.campaignName.toUpperCase();
            var secondValue = b.campaignName.toUpperCase();
            if (firstValue < secondValue) { return 1; }
            if (firstValue > secondValue) { return -1; }
            return 0;
        });
    }
    if (selectValue == "2") {
        data = data.sort(function (a, b) {
            var firstValue = a.insertDate;
            var secondValue = b.insertDate;
            if (firstValue < secondValue) { return 1; }
            if (firstValue > secondValue) { return -1; }
            return 0;
        });
    }
    if (selectValue == "3") {
        data = data.sort(function (a, b) {
            var firstValue = a.insertDate;
            var secondValue = b.insertDate;
            if (firstValue < secondValue) { return -1; }
            if (firstValue > secondValue) { return 1; }
            return 0;
        });
    }

    const getDeliveredPercent = () => {
        const percent = campaignDetail?.receiverCount == 0 ? 0 :
            Math.round(((campaignDetail?.deliveredCount || 0) / (campaignDetail?.receiverCount || 1)) * 100).toFixed(0) || 0;

        return percent;
    }
 

    return (
        <div>
            <Spin spinning={loadingPanel} indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} >

                <Card title={t('menu.campaigns')}
                className='pt-3 mb-2 pb-2 '
                headStyle={{ fontSize: "1.5rem", fontWeight: "bold", color: "#333", margin: 0 }}
                >
                    <Row justify="space-between" gutter={[16, 16]}>
                        <Col flex="1" key={1}>
                            <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='mr-1'>
                                    <Col span={19}><h4 className='ml-1 ' >{t('totalRecipent')}</h4>
                                    </Col>
                                    <Col span={4} >
                                        <TeamOutlined className='mt-1 ml-2 d-flex align-items-center justify-content-center' style={{ fontSize: '21px', color: "#52c41a" }} />
                                    </Col>
                                </Row>
                                <br></br>
                                <h1 className=' ml-2 mb-3'>{campaignDetail?.receiverCount || 0} </h1>
                                <Row className='ml-1 mr-2'>
                                    <Col span={18}>
                                        <Progress strokeColor={"#52c41a"} className="mt-1 mr-1 mb-1 " percent={campaignDetail?.receiverCount * 100 || 0} showInfo={false} />
                                    </Col>
                                    <Col span={6}>
                                        <h4 className='mt-1 ml-2'>%100</h4>
                                    </Col>
                                </Row>
                                {/* <Progress strokeColor={"#52c41a"} className=" mb-1 " percent={99} />*/}
                            </Card>
                        </Col>
                        <Col flex="1" key={2}>
                            <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='mr-1'>
                                    <Col span={19}><h4 className='ml-1 ' >{t('totalSent')}</h4>
                                    </Col>
                                    <Col span={4} >

                                        <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 12 11" height="19" width="19" preserveAspectRatio="xMidYMid meet" className="" fill="none"><title>msg-check</title><path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor"></path></svg> </h2>
                                    </Col>
                                </Row>
                                <br></br>
                                <h1 className=' ml-2 mb-3'>{campaignDetail?.sendedCount || 0}</h1>
                                <Row className='ml-1 mr-2'>
                                    <Col span={18}>
                                        <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={(campaignDetail?.sendedCount / campaignDetail?.receiverCount) * 100 || 0} showInfo={false} />
                                    </Col>
                                    <Col span={6}>
                                        <h4 className='mt-1 ml-2'>%{(campaignDetail?.receiverCount || 0) == 0 ? 0 : Math.round((campaignDetail?.sendedCount / (campaignDetail?.receiverCount || 0)) * 100).toFixed(0) || 0}</h4>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col flex="1" key={3}>
                            <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='mr-1'>
                                    <Col span={19}><h4 className='ml-1 ' >{t('totalDelivered')}</h4>
                                    </Col>
                                    <Col span={4} >

                                        <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>
                                    </Col>
                                </Row >
                                <br></br>
                                <h1 className=' ml-2 mb-3'>{campaignDetail?.deliveredCount || 0}</h1>
                                <Row className='ml-1 mr-2' >
                                    <Col span={18}>
                                        <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={((campaignDetail?.deliveredCount || 0) / campaignDetail?.receiverCount || 0) * 100 || 0} showInfo={false} />
                                    </Col>
                                    <Col span={6}>
                                        <h4 className='mt-1 ml-2'>%{getDeliveredPercent()}</h4>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col flex="1" key={4}>
                            <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='mr-1'>
                                    <Col span={19}><h4 className='ml-1 ' >{t('totalRead')} </h4>
                                    </Col>
                                    <Col span={4} >
                                        <h2 className='ml-3' style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>

                                    </Col>
                                </Row >
                                <br></br>
                                <h1 className=' ml-2 mb-3'>{campaignDetail?.readedCount || 0
                                }</h1>
                                <Row className='ml-1 mr-2'>
                                    <Col span={18}>
                                        <Progress strokeColor={"#53bdea"} className="mt-1 mr-1 mb-1 " percent={
                                            (campaignDetail?.readedCount || 0
                                                / campaignDetail?.receiverCount || 0) * 100 || 0
                                        } showInfo={false} />
                                    </Col>
                                    <Col span={6}>
                                        <h4 className='mt-1 ml-2'>%{campaignDetail?.receiverCount == 0 ? 0 : Math.round(((campaignDetail?.readedCount || 0)
                                            / campaignDetail?.receiverCount || 0) * 100).toFixed(0) || 0
                                        }</h4>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col flex="1" key={5}>
                            <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                <Row className='mr-1'>
                                    <Col span={19}><h4 className='ml-1 ' >{t('totalNotSent')} </h4>
                                    </Col>
                                    <Col span={4} >
                                        <h2 className='ml-3' style={{ color: "red", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 16 15" height="22" width="22" preserveAspectRatio="xMidYMid meet" className="" version="1.1" x="0px" y="0px" enable-background="new 0 0 16 15"><title>msg-time</title><path fill="currentColor" d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z"></path></svg></h2>

                                    </Col>
                                </Row>
                                <br></br>
                                <h1 className=' ml-2 mb-3'>{campaignDetail?.notDeliveredCount || 0}</h1>
                                <Row className='ml-1 mr-2'>
                                    <Col span={19}>
                                        <Progress strokeColor={"red"} className="mt-1 mr-1 mb-1 " percent={((campaignDetail?.notDeliveredCount || 0) / (campaignDetail?.receiverCount || 0)) * 100 || 0} showInfo={false} />
                                    </Col>
                                    <Col span={5}>
                                        <h4 className='mt-1 ml-2'>%{campaignDetail?.receiverCount == 0 ? 0 : Math.round(((campaignDetail?.notDeliveredCount || 0) / (campaignDetail?.receiverCount || 1)) * 100).toFixed(0) || 0}</h4>
                                    </Col>
                                </Row>

                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>

                    </Row>
                </Card>
                <Card>

                    <div className="d-flex align-items-center justify-content-between mt-2 pt-3 mb-2 pb-2 ml-2" style={{}}>
                        {/* Sol taraf: Başlık */}
                        <h1 style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#333", margin: 0 }}>{t('campaignList')}</h1>
 
                    </div>

                    <Flex className="mt-4" alignItems="center" justifyContent="between" mobileFlex={false}>
                        <Flex className="mb-1" mobileFlex={false}>
                            <div className="mr-md-3 mb-3">
                                <Input placeholder={t('searchInCampaign')} prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                            </div>

                            <div className="mr-md-3 mb-3">
                                <Select
                                    onChange={e => onSelectFilter(e)}

                                    labelInValue
                                    placeholder={t('filter')}
                                    style={{ width: 180 }}
                                >
                                    <Select.Option value="0">
                                        <div>
                                            <SortAscendingOutlined className='mr-1' style={{ fontSize: '16px' }} />   {t('campaignName')}
                                        </div>
                                    </Select.Option>
                                    <Select.Option value="1">
                                        <div >
                                            <SortDescendingOutlined className='mr-1' style={{ fontSize: '18px' }} />   {t('campaignName')}
                                        </div>
                                    </Select.Option>
                                    <Select.Option value="2">
                                        <div>
                                            <ArrowDownOutlined className='mr-1' style={{ fontSize: '18px' }} />    {t('createdDate')}
                                        </div> </Select.Option>
                                    <Select.Option value="3">
                                        <div>
                                            <ArrowUpOutlined className='mr-1' style={{ fontSize: '18px' }} />    {t('createdDate')}
                                        </div>
                                    </Select.Option>
                                </Select>
                            </div>
                            <div className="mb-3 mr-8">
                                <ReusableButton
                                    variant="campaign"
                                    text={t('newCampaign')}
                                    href="campaign-create"
                                    icon={<PlusCircleOutlined />}
                                />
                            </div>
                            {/* <div className="mr-md-3 mb-3" style={{ marginLeft: 10 }}>
                                <ReusableButton
                                    variant="template"
                                    text={t('newTemplate')}
                                    href="template-create"
                                    icon={<FormOutlined />}
                                />
                            </div> */}
                        </Flex>
                        <Flex className="mb-1" mobileFlex={false}>
                        </Flex>
                        <Flex className="mr-md-3 mb-3" alignItems="center" mobileFlex={false}>
                            <div
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    padding: "5px 20px",
                                    borderRadius: "8px",
                                    backgroundColor: "#E9F8ED",
                                    fontWeight: "bold",
                                    color: "#25D366",
                                    fontSize: "1rem",
                                    margin: 0,
                                    gap: "12px",
                                }}
                            >
                                <div style={{ display: "inline-flex", alignItems: "center" }}>
                                    <WhatsAppOutlined style={{ fontSize: "1.5rem", marginRight: "8px" }} />
                                    <span>{t('remainingWhatsappMessage')}:</span>
                                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>{totalCredit}</span>
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                    <div className="table-responsive mt-4">
                        <Table
                            columns={columns}
                            dataSource={data}
                            rowKey='id'
                        />
                    </div>
                </Card>
            </Spin>
        </div>
    );

}

export default Campaigns 