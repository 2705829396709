import './App.css';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { THEME_CONFIG } from "./configs/AppConfig"
import store from "./utils/redux/store"
import { Provider } from 'react-redux';
import Router from "./router"
import { ConfigProvider } from 'antd';
import enUs from 'antd/locale/en_US';
import trTR from 'antd/locale/tr_TR';
import { useTranslation } from 'react-i18next';
import { Quill } from 'react-quill';
import { Emoji } from 'emoji-picker-react';


const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducers, composeEnhancers(applyMiddleware()))

function App() {
    const { t, i18n } = useTranslation();
    Quill.register("modules/emoji",Emoji);
   
  return (
    <div className="App">
   
        <Provider store={store}>
          <ConfigProvider locale={i18n.language =="tr" ?trTR: enUs }>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
            <Router />
          </ThemeSwitcherProvider>
          </ConfigProvider>
        </Provider> 
    </div>
  );
}

export default App;
