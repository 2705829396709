import React from 'react'
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'

const EllipsisDropdown = ({
	trigger = 'click', 
	placement = 'bottomRight', 
	menu = <Menu/> 
   }) => { 
	return (
	  <Dropdown
	    menu={menu} 
	    placement={placement} 
	    trigger={[trigger]}
	  >
	    <div className="ellipsis-dropdown">
		 <EllipsisOutlined />
	    </div>
	  </Dropdown>
	)
   }
   
   EllipsisDropdown.propTypes = {
	trigger: PropTypes.string,
	placement: PropTypes.string,
	menu: PropTypes.node // menu'nün bir React node (JSX) olduğunu belirtiyoruz
   }
   
   export default EllipsisDropdown;