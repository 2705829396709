import React, { Component, useEffect, useState } from 'react'
import { PrinterOutlined } from '@ant-design/icons';
import { Card, Table, Button } from 'antd';
import NumberFormat from 'react-number-format';
import { AUTH_TOKEN } from 'constants/StorageConstant';
import network from 'network/network';
import { Helmet } from 'react-helmet';
import { invoiceData } from 'pages/invoice/invoiceData';
import { useParams } from 'react-router-dom';

const { Column } = Table;

const OrderDetail = (props) => {


	const { id } = useParams();

	const [orderDetailState, setOrderDetailState] = useState({
		customerInfo: {},
		orderMasterInfo: {},
		productList: [],
		firmInfo: {}
	});

	useEffect(() => {

		selectOrderDetail();
	}, []);

	const selectOrderDetail = async () => {

		var params = {
			userToken: localStorage.getItem(AUTH_TOKEN),
			orderId: parseInt(id)
		}

		var result = await network.get("/admin/Orders/selectOrderDetail", params, false);


		const {data} = result;
		if (result.isSuccess && data.isSuccess) {

	 

			var resultData = JSON.parse(data.data)
			var orderMasterInfo = (resultData[0][0]);
			var firmInfo = (resultData[1][0]);
			var productList = (resultData[2]);
			var customerInfo = (resultData[3][0]);
		
		
		
			setOrderDetailState({
				customerInfo: customerInfo,
				orderMasterInfo: orderMasterInfo,
				productList: productList,
				firmInfo: firmInfo
			});
		}
	}

	const total = () => {
		let total = 0;
		invoiceData.forEach((elm) => {
			total += elm.price;
		})
		return orderDetailState.orderMasterInfo.TOTALAMOUNT
	}
	const printClick = () => {
		var printContents = document.getElementById("printArea").innerHTML;
		var originalContents = document.body.innerHTML;

		document.body.innerHTML = printContents;

		window.print();

		document.body.innerHTML = originalContents;

	}

 /*
 ITEMID: 81
ORDERID: 81
PRICE: 1
PRODUCTID: 1
QUANTITY: 2
 */
 
	return (
		<div className="container" id='printArea'>
			<Helmet title='Sipariş Detayı'/>
			<Card>
				<div className="d-md-flex justify-content-md-between">
					<div>
						<h1>
							{orderDetailState.customerInfo.SELLERNAME}
						</h1>
						<address>
							<p>
								<span className="font-weight-semibold text-dark font-size-md">{orderDetailState.firmInfo.FIRMNAME}</span><br />
								{/* <span>9498 Harvard Street</span><br />
								<span>Fairfield, Chicago Town 06824</span><br /> */}
							
							</p>
						</address>
					</div>
					<div className="mt-3 text-right">
						<h2 className="mb-1 font-weight-semibold">Sipariş #{orderDetailState.orderMasterInfo.ORDERID}</h2>
						<p>{orderDetailState.orderMasterInfo.ORDERDATEFORMAT}</p>
						<address>
							<p>
								<span className="font-weight-semibold text-dark font-size-md">{orderDetailState.customerInfo.CUSTOMERNAME}</span><br />
								<span>{orderDetailState.customerInfo.CUSTOMERADDRESS} </span><br />
								{/* <span>Niagara Falls, New York 14304</span> */}
								<abbr className="text-dark" title="Telefon Numarası">Telefon: </abbr>
								<span> {orderDetailState.customerInfo.CUSTOMERNUMBER}</span>
							</p>
						</address>
					</div>
				</div>
				<div className="mt-4">
					<Table dataSource={orderDetailState.productList} pagination={false} className="mb-5">
						<Column title="id" dataIndex="ITEMID" key="ITEMID" />
						<Column title="Ürün Adı" dataIndex="PRODUCTNAME" key="PRODUCTNAME" />
						<Column title="Kalem Adedi" dataIndex="QUANTITY" key="QUANTITY" />
						<Column title="Tutar"
							render={(text) => (
								<NumberFormat
									displayType={'text'}
									value={(Math.round(text.PRICE * 100) / 100).toFixed(2)}
									prefix={'₺'}
									thousandSeparator={true}
								/>
							)}
							key="PRICE"
						/>
						<Column
							title="Toplam"
							render={(text) => (
								<NumberFormat
									displayType={'text'}
									value={(Math.round((text.PRICE * text.QUANTITY) * 100) / 100).toFixed(2)}
									prefix={'₺'}
									thousandSeparator={true}
								/>
							)}
							key="total"
						/>
					</Table>
					<div className="d-flex justify-content-end">
						<div className="text-right ">
							{/* <div className="border-bottom">
								<p className="mb-2">
									<span>Alt Toplam: </span>
									<NumberFormat
										displayType={'text'}
										value={(Math.round((total()) * 100) / 100).toFixed(2)}
										prefix={'₺'}
										thousandSeparator={true}
									/>
								</p>
								<p>Kdv : {(Math.round(((total() / 100) * 10) * 100) / 100).toFixed(2)}</p>
							</div> */}
							<h2 className="font-weight-semibold mt-3">
								<span className="mr-1">Toplam Tutar: </span>
								<NumberFormat
									displayType={'text'}
									value={ orderDetailState.orderMasterInfo.TOTALAMOUNT}
									prefix={'₺'}
									fixedDecimalScale
									decimalScale={2}
									thousandSeparator={true}
								/>
							</h2>
						</div>
					</div>

				</div>
				<hr className="d-print-none" />
				{/* <div className="text-right d-print-none">
						<Button type="primary" onClick={() =>printClick()}>
							<PrinterOutlined  type="printer" />
							<span className="ml-1">Yazdır</span>
						</Button>
					</div> */}
			</Card>
		</div>
	);
}

export default OrderDetail
