import { Card, Button, Spin, Upload, message, Select, } from 'antd';
import React from 'react'
import { DownloadOutlined, FileExcelOutlined, InboxOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectCustomerList } from 'utils/redux/actions/customerActions';
import { useNavigate } from 'react-router-dom';


import { LoadingOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { changeActivityStatus } from 'utils/redux/actions/activityStatusActions'
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import * as XLSX from 'xlsx';
import Dragger from 'antd/es/upload/Dragger';
import network from 'network/network';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />




const Customers = (props) => {

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();


	const [loadingPanel, setLoadingPanel] = useState(false)
	const [headerColumns, setHeaderColumns] = useState([]);
	const [excelData, setExcelData] = useState([]);
	const [selectedMatchColumns, setSelectedMatchColumns] = useState([]);
	const [isFullMatchCustomer, setIsFullMatchCustomer] = useState(false);
	const [uploadedFileInfo, setUploadedFileInfo] = useState({
		fileName: "",
		totalRecord: 0,
		missingDataCount: 0,

	});
	const [loadingVisible, setLoadingVisible] = useState(false);

	const customerColumns = [
		{ value: t('customerName'), label: t('customerName') },
		{ value: t('countryCode'), label: t('countryCode') },
		{ value: t('customerNumber'), label: t('customerNumber') },
		{ value: t('Email'), label: t('Email') },
		{ value: t('customerGroup'), label: t('customerGroup') },
	
		
	];



	const matchExcelColumnToCustomerCOlumn = (headCols) => {

		const matchedColumns = customerColumns.filter((column) =>
			headCols.some((excelColumn) => excelColumn.value === column.value)
		);
		const matchedColumnList = [];

		let index = 0;
		for (const element of matchedColumns) {
			matchedColumnList.push({
				excelCol: element.value,
				customerCol: element.value,
				index: index
			});
			index++;
		}
		setSelectedMatchColumns(matchedColumnList);
		setIsFullMatchCustomer(matchedColumnList.length == customerColumns.length);
	}

	const downloadExcel = () => {
		const sampleData = [
			{
				customerName: t("sampleExcelData.customerName"),
				countryCode: t("sampleExcelData.countryCode"),
				customerNumber: t("sampleExcelData.phone"),
				email: t("sampleExcelData.email"),
				customerGroup: t("sampleExcelData.customerGroup"),
			
			},
		];
		const columnMapping = {
			customerName: t('customerName'),
			countryCode: t('countryCode'),
			customerNumber: t('customerNumber'),
			email: t('Email'),
			customerGroup: t('customerGroup'),
		
		};
		const mappedData = sampleData.map((item) => {
			const mappedItem = {};
			Object.keys(item).forEach((key) => {
				mappedItem[columnMapping[key] || key] = item[key]; // Eğer mapping yoksa orijinal key kullanılır
			});
			return mappedItem;
		});

		// JSON verisini bir sheet'e dönüştür
		const worksheet = XLSX.utils.json_to_sheet(mappedData);

		// Workbook oluştur
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

		// Excel dosyasını oluştur ve indirme işlemini başlat
		XLSX.writeFile(workbook, t("sampleExcelFileName") + ".xlsx");
	}

	const getMatchColByName = (name) => {
		var matchedColumnIndex = selectedMatchColumns.findIndex(w => w.customerCol == name);
		return selectedMatchColumns[matchedColumnIndex].excelCol
	}

	const onSubmitExcel = async (data) => {

		if (customerColumns.length != selectedMatchColumns.length) {
			message.error(t("uploadMatchError"));
		}



		setLoadingPanel(true);

		const postData = [];
		for (const element of excelData) {

			const customerNameCol = getMatchColByName(t('customerName'));
			const customerPhoneCol = getMatchColByName(t('customerNumber'));
			const customerEmailCol = getMatchColByName(t('Email'));
			const customerGroupCol = getMatchColByName(t('customerGroup'));
			const countryCodeCol = getMatchColByName(t('countryCode'));
			postData.push({
				CustomerName: element[customerNameCol] + "",
				CustomerNumber: element[customerPhoneCol] +"",
				Email: element[customerEmailCol]+ "",
				CustomerGroup: element[customerGroupCol]+ "",
				CountryCode:element[countryCodeCol]+ "",
			});
		}


		const params = {
			Customers: postData
		}
		const result = await network.post("/admin/client/import-excel", params);
		if (result.isSuccess) {

			message.success(t("excelSaveSuccess"));

			navigate("/campaign-customer");
		} else {
			message.error(t("excelSaveError"));

		}
		setLoadingPanel(false);
	}


	const clearExcelData = () => {
		setExcelData([]);
		setHeaderColumns([]);
		setIsFullMatchCustomer(false);
	}

	const handleFileUpload = async (file) => {
		setLoadingVisible(true);
		try {
			const data = await file.arrayBuffer(); // Dosya içeriğini okuyun
			const workbook = XLSX.read(data, { type: 'array' }); // Excel dosyasını okuyun
			const sheetName = workbook.SheetNames[0]; // İlk sheet'i alın
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet); // Sheet'i JSON'a çevirin
			console.log('Excel Data:', jsonData); // JSON verisini konsola yazdırın


			if (jsonData?.length > 0) {
				const headers = jsonData[0];
				let headerList = [];
				// const isHeaderValid = exampleHeaders.every((header, index) => header.label === headers[index]);

				for (const key in headers) {
					headerList.push({
						value: key,
						label: key
					});
				}
				setHeaderColumns(headerList);
				// jsonData.shift();
				setExcelData(jsonData);

				matchExcelColumnToCustomerCOlumn(headerList);
				message.success(t("excelSuccessLoad"));
				setUploadedFileInfo({
					fileName: file.name,
					missingDataCount: 0,
					totalRecord: jsonData.length
				});
			} else {
				message.error(t("emptyExcelError"));
				clearExcelData();
			}

		} catch (error) {
			message.error(t("emptyExcelError"));
		}

		setTimeout(() => {
			setLoadingVisible(false);
		}, 0);

	};
	const handleChange = (value, index, isExcelCol) => {

		const matchedColumns = JSON.parse(JSON.stringify(selectedMatchColumns));

		const listIndex = selectedMatchColumns.findIndex(w => w.index == index);
		if (listIndex != -1) {
			if (isExcelCol) {
				matchedColumns[listIndex].excelCol = value;
			} else {
				matchedColumns[listIndex].customerCol = value;
			}

		} else {
			matchedColumns.push({
				excelCol: isExcelCol ? value : "",
				customerCol: isExcelCol ? "" : value,
				index: index
			});
		}
		setSelectedMatchColumns(matchedColumns);

	};



	const getMatchView = () => {



		return (

			<div className='mt-4'>
				<Card title={t("uploadFileDetailTitle")} size='default'  >

					<div className='mb-2'>
						<label >
							<b>{t("fileName")}: </b> {uploadedFileInfo.fileName}
						</label>
					</div>

					<div>
						<label>
							<b>{t("totalRecord")}: </b> {uploadedFileInfo.totalRecord}
						</label>
					</div>

					{!isFullMatchCustomer && (
						<div className='mt-4'>
							<h4>
								{t("matchExcelColumns")}
							</h4>
							<div className='row-align-center'>
								<h4 style={{ width: 250 }} className='mr-5'>
									{t("excelColumn")}
								</h4>
								<h4>
									{t("matchedExcelColumn")}
								</h4>
							</div>
							{customerColumns.map((item, index) => {
								const matchIndex = selectedMatchColumns.findIndex(w => w.index == index);
								let excelColumn = "", customerColumn = "";
								if (matchIndex != -1) {
									excelColumn = selectedMatchColumns[matchIndex].excelCol;
									customerColumn = selectedMatchColumns[matchIndex].customerCol;
								}

								return (
									<div className='row-align-center mb-4' key={index + "headerColumns"}>
										<Select
											defaultValue={excelColumn}
											style={{ width: 250 }}
											onChange={(value) => handleChange(value, index, true)}
											options={headerColumns}
											className='mr-5'
										/>

										<Select
											defaultValue={customerColumn}
											style={{ width: 250 }}
											onChange={(value) => handleChange(value, index, false)}
											options={customerColumns}
										/>
									</div>
								);
							})}
						</div>
					)}

					<div className='row-align-center'>
						<div className={"mt-4"}>
							<ReusableButton
								variant="campaign"
								text={t('saveExcelCustomersButton')}
								onClick={onSubmitExcel}
								icon={<SaveOutlined />}

							/>
						</div>
					</div>
				</Card>
			</div>
		);
	}
	return (
		<Spin spinning={loadingPanel} indicator={Icon} >

			<Helmet>
				<title>{("importExcel")}</title>
			</Helmet>
			<Card>

				<div className='row-align-center'>
					<div className={"mb-4"}>
						<ReusableButton
							variant="campaign"
							text={t('downloadSampleExcel')}
							onClick={downloadExcel}
							icon={<DownloadOutlined />}

						/>
					</div>
				</div>
				<Spin spinning={loadingVisible}>
					<Dragger
						
						multiple={false}
						accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
						customRequest={({ file, onSuccess, }) => {
							// Upload işlemini kontrol edin
							setTimeout(() => {
								handleFileUpload(file);
								onSuccess("ok");
							}, 100); // Simüle edilen bir gecikme
						}}
					 
						showUploadList={false}
					>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">{t("excelDraggerTitle")}</p>
						<p className="ant-upload-hint">
						{t("excelDraggerContent")}
						</p>
					</Dragger>
				</Spin>
				{(headerColumns?.length > 0) && getMatchView()}

			</Card>
		</Spin>
	)
}

function mapStateToProps(state) {

	return {
		isLoading: state.customerReducer.isLoading,
		customerList: state.customerReducer.customerList,
		hasError: state.customerReducer.hasError,
		activityStatusList: state.activityStatusReducer.activityList,
		activityFilterList: state.activityStatusReducer.activityFilterList,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectCustomerListAction: bindActionCreators(selectCustomerList, dispatch),
		changeActivityStatusAction: bindActionCreators(changeActivityStatus, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);