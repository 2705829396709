import React, { useEffect } from "react"
import { Button } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  selectTransactionOrderPercent } from "utils/redux/actions/defaultDashboardActions";
import { useHistory, useNavigate } from "react-router-dom";
import GoalWidget from "components/shared-components/GoalWidget";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const Message_TransactionPercent = (props) => {

    const navigate = useNavigate();

    useEffect(() => {

        props.selectTransactionOrderPercentAction();
    }, []);
 
    const goToMessageList = () => {
        navigate(`chat`)
      }

    return (
        <GoalWidget
            title="Mesaj-Sipariş Oranı"
            value={parseFloat(props.transactionOrderPercent)}
            subtitle="Başlatılan mesajların kaç tanesinde sipariş verildi"
            extra={<Button type="primary" onClick={goToMessageList}>Mesajları Gör</Button>}
        />
    );
}


function mapStateToProps(state) {

    return {

        transactionOrderPercent: state.dashboardReducer.transactionOrderPercent,
        transactionOrderPercentLoading: state.dashboardReducer.transactionOrderPercentLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectTransactionOrderPercentAction: bindActionCreators(selectTransactionOrderPercent, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message_TransactionPercent);
