

export const AUTH_TOKEN = "auth_token"
export const FIRM_ID = "firm"
export const BRANCH_ID = "branch"
export const USER_IMAGE = "user_image"
export const FIRM_USER_ID = "firm_userId"
export const NAME_SURNAME = "name_surname"
export const FIRM_NAME = "firm_name"
export const INITIAL_ROUTE_NAME ="initialRouteName"
export const LEFT_MENU_LIST = "leftMenuList"
export const ISFIRMADMIN = "isFirmAdmin"
export const LANGUAGE="Language"
export const ISSTAMTPRODUCTSELECT="Isstampproductselect"
export const FIRMSTAMPCOUNT="firmStampCount"
export const ISINFORMATIONSELECT="isInformationSelect"
 