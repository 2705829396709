import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Alert, Card, Row, Col, message } from "antd";

import PropTypes from 'prop-types';
import { hideAuthMessage, loginUser } from 'utils/redux/actions/loginAction';
import { INITIAL_ROUTE_NAME } from 'constants/StorageConstant';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import network from 'network/network';

const ForgotPassword = props => {

	const { t, i18n } = useTranslation();
 
	const initialCredential = {
		userName: ''
	}

	const [resetPasswordError, setResetPasswordError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		i18n.changeLanguage(navigator.language);
		 
	}, []);
	const onLogin = async (values) => {

		setLoading(true);
		const result = await network.post("/admin/account/forgot-pass?userEmail=" + values.userName);
		setLoading(false);
		if (result.isSuccess) {

			const { isSuccess, errorType } = result.data;

			if (isSuccess) {
				setResetPasswordError("");
				message.success(t("forgotPassSuccess"));
			} else {
				if (errorType == "nouser") {
					setResetPasswordError(t("noUserError"));
				} else
					setResetPasswordError(t("emailSendError"));
			}
		} else {
			setResetPasswordError(t("networkError"));
		}
	};


	const backgroundStyle = {
		backgroundImage: 'url(/img/others/img-17.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	}
	return (
		<div className='auth-container'>
			<div className="h-100" style={backgroundStyle}>
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Col xs={20} sm={20} md={20} lg={7}>
							<Card>
								<div className="my-4">
									<div className="text-center">
										<img className="img-fluid" src={`/img/${'logo.png'}`} alt="" />
										<p> </p>
									</div>
									<Row justify="center">
										<Col xs={24} sm={24} md={20} lg={20}>
											{resetPasswordError?.length > 0 && (
												<motion.div
													initial={{ opacity: 0, marginBottom: 0 }}
													animate={{
														opacity: 1,
														marginBottom: 20
													}}>
													<Alert type="error" showIcon message={(resetPasswordError)}></Alert>
												</motion.div>
											)}

											<Form
												layout="vertical"
												name="login-form"
												initialValues={initialCredential}
												onFinish={onLogin}
											>
												<Form.Item
													name="userName"
													label="Email"
													rules={[
														{
															required: true,
															message: t('WrongEmail'),
														},
														{
															type: 'email',
															message: t('WrongEmailFormat')
														}
													]}>
													<Input prefix className="text-primary" />
												</Form.Item>

												<Form.Item>
													<Button type="primary" htmlType="submit" block loading={loading} >
														{t("resetPassword")}
													</Button>
												</Form.Item>
												<div className='text-right'>
													<Button htmlType='button' href='login' className='mr-0 pr-0' type="link">{t("Login")}</Button>
												</div>

											</Form>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</div>

	)
}



export default ForgotPassword;

