import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import Icon from "./util-components/Icon";
// import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import Tools from 'tools'
import { onMobileNavToggle } from "utils/redux/actions/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as iconList from "@ant-design/icons"
import helperFunc from "helpers/helperFunc";
import IntlMessage from "./util-components/IntlMessage";
import orderMenu from 'assets/data/order.json';
import appointmentMenu from 'assets/data/appointment.json';
import reservationMenu from 'assets/data/reservation.json';
import coffeeprojectMenu from 'assets/data/coffeeproject.json';
import campaignMenu from 'assets/data/campaign.json';
import eventMenu from 'assets/data/eventMenu.json';
import campaignextraitems from 'assets/data/campaignextraitems.json';
import { t } from "i18next";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, selectedValue } = props;

  // const selectedValue = "campaign";


  const getMenuData = () => {

    let result;
    switch (selectedValue) {
      case 'order':
        result = orderMenu;
        break;
      case 'reservation':
        result = reservationMenu;
        break;
      case 'appointment':
        result = appointmentMenu;
        break;
      case 'coffeeproject':
        result = coffeeprojectMenu;
        break;
      case 'campaign':
        result = campaignMenu;
        break;
        case 'event':
          result = eventMenu;
          break;
      default:
        result = campaignMenu;
        break;
    }
    const returnResult = JSON.parse(JSON.stringify(result))
    
 
    if (selectedValue != "campaign" && selectedValue) {
      returnResult.menuItems.push(campaignMenu.menuItems[0]);
    } else {
      returnResult.menuItems[0].items.push(...campaignextraitems);
    }

    return returnResult;
  };



  const menuItems = getMenuData();

  // if (selectedValue != "campaign") {
  //   menuItems.menuItems.push(campaignMenu.menuItems[0]);
  // } else {
  //   menuItems.menuItems[0].items.push(...campaignextraitems);
  // }
 

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {menuItems.menuItems.map((group) => (
        <Menu.ItemGroup key={group.key} title={t(`${group.title}`)}>
          {group.items.map((item) => (
            <Menu.Item key={item.key}>
              {item.icon && (
                <Icon type={iconList[item.icon]} />
              )}
              <span>{t(`${item.title}`)}</span>
              <Link to={item.link} ></Link>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  const navigationConfig = helperFunc.getLeftMenuList();

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={iconList[menu?.icon]} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={iconList[subMenuFirst?.icon]} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={iconList[subMenuFirst?.icon]} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={iconList[menu?.icon]} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
