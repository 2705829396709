import React, { useEffect, useRef, useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex' 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectHomePageNotificationList, selectUnreadNotificationCount } from 'utils/redux/actions/notiificationActions';
import { useNavigate } from 'react-router-dom';

export const NavNotification = (props) => {
	let navigate = useNavigate();

  const isInitialRef = useRef(true);
  const [visible, setVisible] = useState(false);


  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  useEffect(() => {

    if (isInitialRef.current) {
      isInitialRef.current = false;
      selectNotificationList();
      selectUnreadNotificationCount();
    }

  }, []);

  const selectNotificationList = async () => {

    props.selectHomePageNotificationListActions();
  
  }
  const selectUnreadNotificationCount = async () => {

    props.selectUnreadNotificationCountActions();
  
  }

  const gotoNotification =()=>{

    setVisible(false);
     navigate(`/notifications`)
  }

  const getIcon = icon => {
    switch (icon) {
      case 'mail':
        return <MailOutlined />;
      case 'alert':
        return <WarningOutlined />;
      case 'check':
        return <CheckCircleOutlined />
      default:
        return <MailOutlined />;
    }
  }


  const getNotificationBody = list => {
 
    return list.length > 0 ?
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={item => (
          <List.Item className="list-clickable">
            <Flex alignItems="start" justifyContent='between' className='w-100' >
              <div className="pr-3">
                <Avatar className={`ant-avatar-info`} icon={getIcon("mail")} />
              </div>
              <div className="mr-3">
                <span className="font-weight-bold text-dark">{item.header} </span>
                <br></br>
                <span className="text-gray-light" style={{
                  overflow:"hidden",
                  textOverflow:"ellipsis",
                  display:"-webkit-box",
                  WebkitLineBreak:2,
                  lineClamp: 2,
                  WebkitLineClamp: 2,
                  WebkitBoxOrient:"vertical"
                }}>
                  {item.text}
                 </span>
              </div>
              <small className="ml-auto">{item.senddateformat}</small>
            </Flex>
          </List.Item>
        )}
      />
      :
      <div className="empty-notification">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
        <p className="mt-3">Bildiriminiz bulunmamaktadır</p>
      </div>;
  }

  const notificationView = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Bildirimler</h4>
        {/* <Button className="text-primary" type="text" onClick={() => setData([])} size="small">Clear </Button> */}
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(props.homePageNotifyList)}
      </div>
      {
        props.homePageNotifyList.length > 0 ?
          <div className="nav-notification-footer">
            <a onClick={gotoNotification} className="d-block" href="">Tümünü Göster</a>
          </div>
          :
          null
      }
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      menu={notificationView}
      onOpenChange={handleVisibleChange}
      open={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={props.unreadNotificationCount}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

 
function mapStateToProps(state) {

	return {
		isLoading: state.notificationReducer.isLoading,
		homePageNotifyList: state.notificationReducer.homePageNotifyList,
		hasError: state.notificationReducer.hasError,
		errorMessage: state.notificationReducer.errorMessage,
		unreadNotificationCount: state.notificationReducer.unreadNotificationCount,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectHomePageNotificationListActions: bindActionCreators(selectHomePageNotificationList, dispatch),
		selectUnreadNotificationCountActions: bindActionCreators(selectUnreadNotificationCount, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
