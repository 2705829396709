
import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card, Menu, Upload, InputNumber, Select, Checkbox } from 'antd';
import Flex from 'components/shared-components/Flex'
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import { SaveOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
const ADD = 'ADD'

const GroupsCreate = props => {
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const { mode = ADD, param } = props

	const [form] = Form.useForm();

	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [firmSellerList, setFirmSellerList] = useState([]);
	const [selectedFirmSeller, setSelectedFirmSeller] = useState(-1);

	const { id } = useParams();

	const [groupState, setGroupState] = useState({
		groupId: id,
		groupName: "",
		groupDesc: "",
		status: true,
	});

	useEffect(() => {
		if (id > 0) {
			selectGroupDetail();
		} else {
			setGetDetailLoading(false);
		}

	}, [form, mode, param, props]);

	const selectGroupDetail = async () => {

		setGetDetailLoading(true);
		var result = await network.get("/admin/Group/selectGroupList", false);

		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		const { data } = result.data;

		var index = data.findIndex(w => w.groupid == id);
		if (result.isSuccess) {
			setGroupState({
				groupId: id,
				groupName: data[index].groupname,
				groupDesc: data[index].groupdesc,
				status: data[index].status,
			});
		}
	}

	const onFinish = async () => {

		if (groupState.groupName.length == 0) {
			message.error(t('requiredFields'));
			return;
		}
		setSubmitLoading(true)

		var params = {
			groupid: parseInt(groupState.groupId),
			groupname: groupState.groupName,
			groupDesc: groupState.groupDesc,
			status: groupState.status
		}

		var method = "insertGroup";
		if (params.groupid > 0) {
			method = "updateGroup";
		}

		var result = await network.post(`/admin/Group/${method}`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {

				message.success(t('groupSavedSuccess'));

				setTimeout(() => {
					navigate(`/groups`)
				}, 250);

			} else {
				alert(postResult.errorMessage);
			}

		} else {
			alert(result.errorMessage);
		}


	};

	if (getDetailLoading) {
		return (
			<div>

			</div>
		);
	}


	const tabItems = [
		{
			key: "1",
			label: "Genel Bilgiler",
			children: (
				<Row gutter={16}>
					<Col xs={24} sm={24} md={17}>
						<Card title="Grup Bilgileri">
							<div className='ant-col ant-form-item-label'>
								<label className='ant-form-item-required' htmlFor="customerName">Grup Adı</label>
							</div>

							<Input
								value={groupState.groupName}
								defaultValue={groupState.groupName}
								onChange={(val) => {
									setGroupState({
										...groupState,
										groupName: val.target.value,
									});
								}}
								placeholder="Grup Adı"
							/>
							<div className='m-4'></div>
						</Card>
					</Col>
				</Row>
			),
		},
	];

	return (
		<>
			<Helmet title={id > 0 ? t('updateGroup') : t('addGroup')} />
			<Form layout="vertical" name="advanced_search" className="ant-advanced-search-form">
				{/* <PageHeaderAlt className="border-bottom" overlap>
					<div className="">
						<Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
							<h3 className="mb-3">{id > 0 ? t('updateGroup') : t('addGroup')}</h3>
						</Flex>
					</div>
				</PageHeaderAlt> */}
				<div>
					<Row gutter={24} style={{ marginTop: 20 }}>
						<Col xs={24} sm={24} md={24}>
							<Card title={t('groupInfo')}>
								<Col span={10} className="mb-3">
									<div className="ant-col ant-form-item-label">
										<label className="ant-form-item-required" htmlFor="customerName">{t('groupName')}</label>
									</div>
									<Input
										value={groupState.groupName}
										defaultValue={groupState.groupName}
										onChange={(val) => {
											setGroupState({
												...groupState,
												groupName: val.target.value,
											});
										}}
										placeholder={t('groupNamePlaceholder')}
									/>
								</Col>
								<Col span={10} className="mb-3">
									<div className="ant-col ant-form-item-label">
										<label className="ant-form-item-required" htmlFor="customerName">{t('groupDesc')}</label>
									</div>
									<Input
										value={groupState.groupDesc}
										defaultValue={groupState.groupDesc}
										onChange={(val) => {
											setGroupState({
												...groupState,
												groupDesc: val.target.value,
											});
										}}
										placeholder={t('groupDescPlaceholder')}
									/>
								</Col>
								<Col span={10} className="mb-3">
									<div className="row">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="customerName" style={{ marginRight: '10px' }}>{t('active')}</label>
											<Checkbox
												checked={groupState.status}
												onChange={(val) => {
													setGroupState({
														...groupState,
														status: val.target.checked,
													});
												}}
											/>
										</div>
									</div>
								</Col>
								<Col span={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
									{/* <Button
										type="primary"
										onClick={() => onFinish()}
										htmlType="submit"
										loading={submitLoading}
									>
										{t('save')}
									</Button> */}
									<div>
									<ReusableButton
										variant="campaign"
										text={t('save')}
										 htmlType="submit"
										icon={<SaveOutlined />} 
										onClick={() => onFinish()}
									/>
									</div>
									 
								</Col>
							</Card>
						</Col>
					</Row>
				</div>
			</Form>
		</>
	)
}

export default GroupsCreate