

import { Card, Col, Input, Row, Spin, Table, } from "antd";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ExportOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tools from 'tools';
import * as XLSX from 'xlsx';
import AvatarStatus from "components/shared-components/AvatarStatus";
import ReusableButton from "components/Elements/ReusableButton"; 
import { useTranslation } from "react-i18next";
import moment from "moment";

const EventAtteendList = (props) => {

	const [pageData, setPageData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState("");
	const navigate = useNavigate();
	const { t, } = useTranslation();


	useEffect(() => {
			selectCustomerList();
	
	}, []);

	const selectCustomerList = async () => {
		var params = {};

		var result = await network.get("/admin/event/events-visits", params, false);


		if (result.isSuccess) {
		 
			setPageData(result.data);

		}
		setLoading(false);

	} 

	const onSearch = (e) => {
		const value = e.currentTarget.value;
		setSearchValue(value);
 
	}

 

	const handleExcelExport = () => {
		const excelData = filteredData.map((record) => ({
			[t('evenetVisitorName')]: record.clientName,
			[t('evenetVisitorPhone')]: record.clientNumber,
			[t('VisitDate')]: new Date(record.visitDate).toLocaleDateString(),
			[t('EventName')]: record.eventName,
			[t('EventDescription')]: record.eventDesc,
			[t('standCode')]: record.standCode,
			[t('standDesc')]: record.standDesc,
			[t('eventVisitorToFirmName')]: record.attendeeFirmName,
			[t('eventVisitorToContactName')]: record.attendeeContactName,
			[t('eventVisitorToContactPhone')]: record.attendeeContactPhone, 
		}));

		// Create a new worksheet and add the data
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "EventVisitReports");

		// Export to Excel
		XLSX.writeFile(workbook, "EventVisitReport.xlsx");
	}


		 
	const tableColumns = [
	
		{
			title: t('evenetVisitorName'),
			dataIndex: "clientName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'clientName'),
			render: (_, record) => (
				<div className="d-flex align-items-center">
					<AvatarStatus src={"/img/avatars/user-blank-image.jpg"}></AvatarStatus>
					<h5>{record.clientName}</h5>
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.clientName !== prevRecord.clientName,
		},
		{
			title: t('evenetVisitorPhone'),
			dataIndex: "clientNumber",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'clientNumber'),
			render: (_, record) => (
				<div>
					+{record.clientNumber}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.clientNumber !== prevRecord.clientNumber,

		},
		{
			title: t('VisitDate'),
			dataIndex: "visitDate",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'visitDate'),
			render: (_, record) => (
				<div className="font-weight-normal">
					{moment(record.visitDate).format('DD.MM.YYYY')} {moment(record.visitDate).format('HH:mm')}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.visitDate !== prevRecord.visitDate,
		},
		{
			title: t("EventName"),
			dataIndex: "eventName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'eventName'),
			render: (_, record) => (
				<div>
					{record.eventName != null ? record.eventName : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.eventName !== prevRecord.eventName,
		},
		{
			title: t("standCode"),
			dataIndex: "standCode",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'standCode'),
			render: (_, record) => (
				<div>
					{record.standCode != null ? record.standCode : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.standCode !== prevRecord.standCode,
		},
		{
			title: t("standDesc"),
			dataIndex: "standDesc",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'standDesc'),
			render: (_, record) => (
				<div>
					{record.standDesc != null ? record.standDesc : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.standDesc !== prevRecord.standDesc,
		},
		{
			title: t("eventVisitorToFirmName"),
			dataIndex: "attendeeFirmName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'attendeeFirmName'),
			render: (_, record) => (
				<div>
					{record.attendeeFirmName != null ? record.attendeeFirmName : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.attendeeFirmName !== prevRecord.attendeeFirmName,
		},
		{
			title: t("eventVisitorToContactName"),
			dataIndex: "attendeeContactName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'attendeeContactName'),
			render: (_, record) => (
				<div>
					{record.attendeeContactName != null ? record.attendeeContactName : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.attendeeContactName !== prevRecord.attendeeContactName,
		},
		{
			title: t("eventVisitorToContactPhone"),
			dataIndex: "attendeeContactPhone",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'attendeeContactPhone'),
			render: (_, record) => (
				<div>
					{record.attendeeContactPhone != null ? record.attendeeContactPhone : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.attendeeContactPhone !== prevRecord.attendeeContactPhone,
		},
	]
 

	const getFilteredData = () => {

		if (searchValue.length > 0) {
			const filtered = pageData.filter((item) => {
				return (
					item.clientName.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.clientNumber?.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.eventName?.toUpperCase().includes(searchValue.toUpperCase())||
					item.eventDesc?.toUpperCase().includes(searchValue.toUpperCase())||
					item.standCode?.toUpperCase().includes(searchValue.toUpperCase())||
					item.standDesc?.toUpperCase().includes(searchValue.toUpperCase())||
					item.attendeeFirmName?.toUpperCase().includes(searchValue.toUpperCase())||
					item.attendeeContactName?.toUpperCase().includes(searchValue.toUpperCase())||
					item.attendeeContactPhone?.toUpperCase().includes(searchValue.toUpperCase())
				);
			});
			return filtered;
		} else {
			return pageData
		}
	}
	const filteredData = getFilteredData();

 
	return (
		<Spin size="large" spinning={loading}>
			<div>
				<Row className="mb-3" justify={"space-between"}>

					<Col span={4}>
						<Input
							placeholder={t('Search')}
							prefix={<SearchOutlined />}
							onChange={onSearch}
						/>
					</Col>
					<div className="row-justify-between ">
						<ReusableButton
							variant="campaign"
							text={t('Export')}
							onClick={handleExcelExport}
							icon={<ExportOutlined />}
							className={"mr-2"}

						/>
					</div>

				</Row>

				<Card>
					<div className="table-responsive">
						<Table
							columns={tableColumns}
							dataSource={filteredData}
							size="middle"
							rowKey={"id"}
							key={JSON.stringify(pageData)}
						/>
					</div>
				</Card>
			</div>
		</Spin>




	);
}

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventAtteendList);
