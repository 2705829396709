

import { render } from "@testing-library/react";
import { Button, Card, Col, Dropdown, Input, message, Modal, notification, Row, Spin, Table, Tag } from "antd";
import network from "network/network";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectCustomerList as fetchCustomerList } from "utils/redux/actions/customerActions"; // Renamed
import { DashboardOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, FileExcelOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tools from 'tools';
import * as XLSX from 'xlsx';
import AvatarStatus from "components/shared-components/AvatarStatus";
import { t } from "i18next";
import ReusableButton from "components/Elements/ReusableButton";
import helperFunc from "helpers/helperFunc";
import { useTranslation } from "react-i18next";
import globalConstant from "constants/globalConstant";

const CustomerDashboard = (props) => {

	const [customerData, setCustomerData] = useState([]);
	// const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [isModal, setIsmodal] = useState(false);
	const [customerId, setCustomerId] = useState(0);
	const navigate = useNavigate();
	const { t, } = useTranslation();


	useEffect(() => {
		if (!isModal) {
			selectCustomerList();
		}

	}, [isModal]);

	const selectCustomerList = async () => {
		var params = {};

		var result = await network.get("/admin/Client/GetCustomersWithDetail", params, false);


		if (result.isSuccess && result.data.isSuccess) {
			const uniqueData = result.data.data.filter((value, index, self) =>
				index === self.findIndex((t) => t.customerid === value.customerid)
			);

			setCustomerData(uniqueData);
			// setFilteredData(uniqueData);

		}
		setLoading(false);

	}
	const changeCustomerStatusPrev = (row) => {
		const newStatus = row.customerstatus == globalConstant.customerStatus.passive ? globalConstant.customerStatus.active : globalConstant.customerStatus.passive

		const message = newStatus == globalConstant.customerStatus.passive ? t("makePassiveConfirmMessage") : t("makeActiveConfirmMessage")

		helperFunc.showConfirm({
			content: message,
			title: t("warning"), onConfirm: () => changeCustomerStatusConfirm(row, newStatus)
		});
	}
	const changeCustomerStatusConfirm = async (row, newStatus) => {

		setLoading(true);

		var params = {
			customerId: row.customerid,
			status: newStatus
		}

		var result = await network.get("/admin/client/updateClientStatus", params, false);

		setLoading(false);
		if (result.isSuccess) {


			message.success(t("clientStatusUpdateSuccess"))


			var customerIndex = customerData.findIndex(w => w.customerid == row.customerid);
			var data = JSON.parse(JSON.stringify(customerData));
			if (customerIndex != -1) {

				data[customerIndex].customerstatus = newStatus;
			}
			console.log(customerIndex);
			setCustomerData(data);

		} else {
			message.error(t("clientStatusUpdateError"))
		}

	}

	const onSearch = (e) => {
		const value = e.currentTarget.value;
		setSearchValue(value);

		// if (value.length > 0) {
		// 	const filtered = customerData.filter((item) => {
		// 		return (
		// 			item.customername.toUpperCase().includes(value.toUpperCase()) ||
		// 			item.customernumber.toUpperCase().includes(value.toUpperCase()) ||
		// 			item.email?.toUpperCase().includes(value.toUpperCase())
		// 		);
		// 	});
		// 	setFilteredData(filtered); // Update filtered data
		// } else {
		// 	setFilteredData(customerData); // Reset to original data
		// }
	}

	const importExcelClick = () => {

		navigate(`/campaign-customer-excel`)

	}

	const getItems2 = (record) => [
		{
			key: 'detail',
			label: (
				<div onClick={() => navigate(`/customer-edit/${record.customerid}`)}>
					<EditOutlined />
					<span className="ml-2">{t('edit')}</span>
				</div>
			)
		},
		{
			key: 'delete',
			label: (
				<div onClick={() => changeCustomerStatusPrev(record)}>
					<DeleteOutlined />
					<span className="ml-2">{record.customerstatus !== globalConstant.customerStatus.passive ? t('makePassive') : t('makeActive')}</span>
				</div>
			)
		}
	];

	const tableColumns = [
		{
			title: t('customerName'),
			dataIndex: "customername",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'customername'),
			render: (_, record) => (
				<div className="d-flex align-items-center">
					<AvatarStatus src={"/img/avatars/user-blank-image.jpg"}></AvatarStatus>
					<h5>{record.customername}</h5>
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.customername !== prevRecord.customername,
		},
		{
			title: t('customerNumber'),
			dataIndex: "customernumber",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'customernumber'),
			render: (_, record) => (
				<div>
					+{record.customernumber}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.customernumber !== prevRecord.customernumber,

		},
		{
			title: t('regDate'),
			dataIndex: "insertdate",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'insertdate'),
			render: (_, record) => (
				<div className="font-weight-normal">
					{new Date(record.insertdate).toLocaleString('tr-TR').replace(/:\d{2}$/, '')}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.insertdate !== prevRecord.insertdate,
		},
		{
			title: "E-Mail",
			dataIndex: "email",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'email'),
			render: (_, record) => (
				<div>
					{record.email != null ? record.email : '-'}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email,
		},
		{
			title: t("status"),
			dataIndex: "customerstatus",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'customerstatus'),
			render: (_, record) => (
				record.customerstatus !== globalConstant.customerStatus.passive ? (

					<div className='my-4 '>
						<><Tag color={"green"}>{t('active')}</Tag></>
					</div>


				) : (

					<div className='my-4 '>
						<><Tag color={"red"}>{t('inactive')}</Tag></>
					</div>

				)
			),
			shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email,
		},
		{
			title: t('customerDetail'),
			dataIndex: "",
			render: (_, record) => (
				<Dropdown menu={{ items: getItems2(record) }} trigger={['click']}>
					<div className="ellipsis-dropdown" style={{ cursor: 'pointer' }}>
						<EllipsisOutlined />
					</div>
				</Dropdown>
			)
		}
	]

	const getStatusText =(record)=>{

		return record.customerstatus !== globalConstant.customerStatus.passive ? t('active'): t('inactive');
	}

	const handleExcelExport = () => {
		const excelData = filteredData.map((record) => ({
			[t('customerNumber')]: record.customernumber,
			[t('customerName')]: record.customername,
			[t('regDate')]: new Date(record.insertdate).toLocaleDateString(),
			[t('Email')]: record.email,
			[t('status')]: getStatusText(record),
		}));

		// Create a new worksheet and add the data
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

		// Export to Excel
		XLSX.writeFile(workbook, "CustomerData.xlsx");
	}

	const getFilteredData = () => {

		if (searchValue.length > 0) {
			const filtered = customerData.filter((item) => {
				return (
					item.customername.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.customernumber.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.email?.toUpperCase().includes(searchValue.toUpperCase())
				);
			});
			return filtered;
		} else {
			return customerData
		}
	}
	const filteredData = getFilteredData();

	const addCustomer = () => {

		navigate(`/customer-edit/0`)
	}
	return (
		<Spin size="large" spinning={loading}>
			<div>
				<Row className="mb-3" justify={"space-between"}>

					<Col span={4}>
						<Input
							placeholder={t('searchInCustomers')}
							prefix={<SearchOutlined />}
							onChange={onSearch}
						/>
					</Col>
					<div className="row-justify-between ">
						<ReusableButton
							variant="campaign"
							text={t('Export')}
							onClick={handleExcelExport}
							icon={<ExportOutlined />}
							className={"mr-2"}

						/>
						<ReusableButton
							variant="campaign"
							text={t('importExcel')}
							onClick={importExcelClick}
							icon={<FileExcelOutlined />}
							className={"mr-2"}
						/>
						<ReusableButton
							variant="campaign"
							text={t('addCustomer')}
							onClick={addCustomer}
							icon={<PlusCircleOutlined />}
						/>
					</div>

				</Row>

				<Card>
					<div className="table-responsive">
						<Table
							columns={tableColumns}
							dataSource={filteredData}
							size="middle"
							rowKey={"customerid"}
							key={JSON.stringify(customerData)}
						/>
					</div>
				</Card>
			</div>
		</Spin>




	);
}

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashboard);
