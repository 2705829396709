import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, InputNumber, Select, Spin } from 'antd';
import Flex from 'components/shared-components/Flex'
import countryCodes from "assets/data/countryCodes.json"

import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { PhoneOutlined, UserOutlined, CloseOutlined, SaveOutlined, CheckOutlined, CheckCircleOutlined, CloseCircleOutlined, } from '@ant-design/icons';
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { selectBranchList } from 'utils/redux/actions/branchAction';
import { fileToBase64 } from 'helpers/fileHelper';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BRANCH_ID } from 'constants/StorageConstant';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import helperFunc from 'helpers/helperFunc';
import ReusableButton from 'components/Elements/ReusableButton';

const { Option } = Select;

const { TabPane } = Tabs;



const ADD = 'ADD'




const EditCustomer = (props) => {
	let navigate = useNavigate();

	const { mode = ADD, param } = props

	const [form] = Form.useForm();
	const { t, } = useTranslation();
	const [toData, setToData] = useState([])
	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [groupState, setGroupState] = useState({
		groupToid: [],
		groupToName: [],
	});
	const { id } = useParams();
	const [customerState, setCustomerState] = useState({
		customerName: "",
		customerImage: "",
		customerNumber: "",
		countryCode: "",
		customerStatus: -1,
		customerId: id,
		customerAddress: "",
		email: "",
		lat: 0,
		lng: 0,
		approvedate: "",
		Customercountryid: "",
		Customercity: "",
		Customerdistrict: "",
		CustomerBranch: 0,
		approveddate: "",
		denydate: "",
		isapproved: false,
		isdenybyadmin: false,
		isapproveadmin: false,
		// Customergroups:[]

	});
	const [fields, setFields] = useState([
		{
			name: ['countryCode'],
			value: '+90',
		},
	]);

	useEffect(() => {

		// selectFirmSellerList();
		selectGroupsList();
		if (id > 0) {
			selectCustomerDetail();
		} else {
			setGetDetailLoading(false);
			props.selectBranchListAction({});


		}

	}, [form, mode, param]);

	const selectCustomerDetail = async () => {

		setGetDetailLoading(true);
		var params = {
			customerId: id
		}


		var result = await network.get("/admin/Client/selectClientDetail", params, false);

		console.log(result.data);
		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		const { data } = result;
		if (result.isSuccess && data.isSuccess) {
			var customer = data.data;
			console.log(customer);
			var number = customer.customernumber.substring(2);
			setCustomerState({
				countryCode: "+" + customer.countrycode,
				customerId: customer.customerid,
				customerImage: customer.customerimage || "",
				customerName: customer.customername,
				customerNumber: number,
				customerStatus: customer.customerstatus,
				customerAddress: customer.customeraddress,
				lat: customer.lat,
				lng: customer.lng,
				Customercountryid: customer.countrycodeid,
				Customercity: customer.city,
				Customerdistrict: customer.district,
				approveddate: customer.approveddate,
				denydate: customer.denydate,
				isapproved: customer.isapproved,
				isdenybyadmin: customer.isdenybyadmin,
				email: customer.email,
				isapproveadmin: customer.isapproveadmin,

			});
			setGroupState({
				groupToid: customer.groups
			});

		} else {
			setCustomerState({
				...customerState,
				customerId: -1
			})
		}
	}
	const handleToChange = (value, label) => {

		try {
			setGroupState({
				...groupState,
				groupToid: value,
				groupToName: label
			})

		} catch (error) {
			alert(error);
		}
	};


	const selectGroupsList = async () => {

		var toResult = await network.get("/admin/Group/selectGroupList", false);

		const toData = toResult.data.data;
		setToData(toData);

	}

	const onCountryCodeChange = (val) => {
		const code = val.key.replace("+", "")
		try {
			setCustomerState({
				...customerState,
				countryCode: code
			})
		} catch (error) {
			alert(error);
		}
	}

	const rejectCustomerClick = () => {
		helperFunc.showConfirm({
			content: t("denyClientConfirmContent"),
			title: t("denyClientConfirmTitle"), onConfirm: () => rejectCustomerConfirm()
		});
	}
	const rejectCustomerConfirm = async () => {

		var params = {
			customerId: id
		}
		setGetDetailLoading(true);

		var result = await network.get("/admin/Client/denyClientByAdmin", params, false);

		if (result.isSuccess) {
			selectCustomerDetail();
			message.error(t('apiSuccess'));
		} else {
			message.error(t('apiError'));

			setGetDetailLoading(false);
		}
	}

	const onFinish = async () => {

		if (customerState.customerName.length == 0 || customerState.customerNumber.length == 0
			|| customerState.countryCode.length == 0) {
			message.error(t('requiredFieldsError'));
			return;
		}

		setSubmitLoading(true)

		var countryCode = customerState.countryCode.replace("+", "");
		var customerNumber = countryCode + customerState.customerNumber;
		var params = {
			Customerid: parseInt(customerState.customerId),
			Countrycode: countryCode,
			Customernumber: customerNumber,
			Customername: customerState.customerName,
			Email: customerState.email,
			Customerstatus: parseInt(globalConstant.customerStatus.active),
			FirmSellerId: -1,
			groupIds: groupState.groupToid,

		}
		var method = "insertClient";
		if (params.Customerid > 0) {
			method = "updateClient";
		}

		var result = await network.post(`/admin/Client/${method}`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {

				message.success(t("customerSaveSuccess"));

				setTimeout(() => {

					navigate(`/campaign-customer`)
				}, 250);

			} else {
				message.error(t("customerSaveError"));

			}

		} else {
			message.error(t("customerSaveError"));
		}


	};


	if (getDetailLoading) {
		return (
			<div style={{ width: "fit-content", marginTop: "25%" }} className="mx-auto"><Spin tip="Loading" size="large" spinning={getDetailLoading}></Spin></div>
		);
	}

	const getCustomerConfirmationText = () => {


		let approveText = "";
		let formattedApprovedDate = "";
		if (customerState.approveddate) {
			formattedApprovedDate = moment(customerState.approveddate).format('DD.MM.YYYY HH:mm');
			if (customerState.isapproveadmin != true) {
				approveText = t("clientApprovedText");
			} else {
				approveText = t("clientApprovedTextByAdmin");
			}
		}
		 
		if (customerState.isapproved && customerState.approveddate) {



			return (
				<div className='row-align-center'>

					<div className='row-align-center'>
						<CheckCircleOutlined style={{ fontSize: 18, color: '#52c41a' }} className='mr-2 ' />

						{approveText}  {formattedApprovedDate}
					</div>

					<Button type="link" className='max-w-150' block onClick={rejectCustomerClick}>
						{t("denyClient")}
					</Button>
				</div>
			);
		} else if (customerState.isapproved != true && customerState.denydate) {
			const formattedDeniedDate = moment(customerState.denydate).format('DD.MM.YYYY HH:mm');
			const textr = customerState.isdenybyadmin ? t("clientDenyByAdminText") : t("clientDenyText")
			var denyText = textr.replace("{Date_Time}", formattedDeniedDate);


			return (
				<div className='' >

					{approveText && (
						<div className='row-align-start mb-2'>
							<CheckCircleOutlined style={{ fontSize: 18, color: '#52c41a' }} className='mr-2 ' />
							<span>
								{approveText}  {formattedApprovedDate}
							</span>


						</div>)}


					<div className='row-align-start'>
						<CloseCircleOutlined style={{ fontSize: 18, color: '#b82a23' }} className='mr-2 ' />
						{denyText}  {formattedDeniedDate}
					</div>

				</div>
			);
		}
		return null;

	}


	return (
		<>
			<Helmet>
				<title>{customerState.customerId > 0 ? t('updateCustomer') : t('addCustomer')}</title>
			</Helmet>
			<Form
				layout="vertical"
				fields={fields}
				name="advanced_search"
				className="ant-advanced-search-form"
			>

				<div className="">
					<Row gutter={16}>
						<Col xs={24} sm={24} md={17}>
							<Card title={t('customerInfo')}>
								<div className='ant-col ant-form-item-label'>
									<label className='ant-form-item-required' htmlFor="customerName">{t('customerName')}</label>
								</div>

								<Input value={customerState.customerName}
									defaultValue={customerState.customerName}
									onChange={(val) => {
										setCustomerState({
											...customerState,
											customerName: val.target.value
										});
									}}
									placeholder={t('customerName')} />

								<div className='m-4'></div>

								<Row gutter={16}>
									<Col xs={5} sm={8} md={4}>
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="countryCode">{t('countryCode')}</label>
										</div>
										<Select
											showSearch
											labelInValue
											defaultValue={{ key: customerState.countryCode || t('countryCode') }}
											style={{ width: 120 }}
											disabled={id > 0}
											onChange={(val) => {
												onCountryCodeChange(val);
											}}
										>
											{countryCodes.map((item, index) => (
												<Option key={item.dial_code} value={item.dial_code}>
													{item.code + "(" + item.dial_code + ")"}
												</Option>
											))}
										</Select>
									</Col>
									<Col xs={24} sm={24} md={12}>
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">{t('phoneNumber')}</label>
										</div>
										<Input
											value={customerState.customerNumber}
											defaultValue={customerState.customerNumber}
											disabled={id > 0}
											onChange={(val) => {
												setCustomerState({
													...customerState,
													customerNumber: val.target.value
												});
											}}
											placeholder={t('phoneNumber')}
											prefix={<PhoneOutlined className="site-form-item-icon" />}
										/>
									</Col>
								</Row>


								<div className='m-4'></div>

								<div className='ant-col ant-form-item-label'>
									<label className='ant-form-item-required' htmlFor="customerName">{t('customerGroup')}</label>
								</div>
								<Select
									mode="multiple"
									onChange={handleToChange}
									placeholder={t('customerGroup')}
									defaultValue={groupState.groupToid}
									style={{ width: '100%' }}>
									{toData.map((item, index) => (
										<Option value={item.groupid} label={item.groupname} >
											{item.groupname}
										</Option>
									))}
								</Select>

								<div className='m-4'></div>

								<div className='ant-col ant-form-item-label'>
									<label className='ant-form-item-required' htmlFor="customerName">{t('email')}</label>
								</div>
								<Input value={customerState.email}
									defaultValue={customerState.email}
									onChange={(val) => {
										setCustomerState({
											...customerState,
											email: val.target.value
										});
									}}
									placeholder={t('email')} />

								<div className='m-4'></div>
								{getCustomerConfirmationText()}

								<div className="mb-3 row-justify-end">
									<div>
										<ReusableButton
											variant="campaign"
											text={customerState.customerId > 0 ? t('update') : t('save')}
											onClick={onFinish}
											icon={<SaveOutlined />}
											htmlType="submit"
											loading={submitLoading}
										/>
									</div>
								</div>
							</Card>
						</Col>
					</Row>

				</div>
			</Form>
		</>
	)
}

function mapStateToProps(state) {

	return {

		branchList: state.branchReducer.branchList
	}
}

function mapDispatchToProps(dispatch) {
	return {

		selectBranchListAction: bindActionCreators(selectBranchList, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
