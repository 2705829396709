import { CheckCircleOutlined, ExclamationCircleOutlined, ExportOutlined, FieldTimeOutlined, FileImageOutlined, InfoCircleOutlined, MailOutlined, SaveOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Card, InputNumber, message, Select, Switch } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import { FIRMSTAMPCOUNT, ISINFORMATIONSELECT, ISSTAMTPRODUCTSELECT, LANGUAGE } from "constants/StorageConstant";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FirmSettings = ({ changeTab }) => {
    const { t, i18n } = useTranslation();
    const [firmSettings, setfirmSettings] = useState({
        stampTimeLimit: 0,
        getMailStampOrder: 0,
        isStampProductSelect: false,
        isInformationSelect: false,
        stampCount: 0,
        isMailRequired: false
    });
    const [isExistRecord, setIsExistRecord] = useState(false);
    const [goInformation, setGoInformation] = useState(localStorage.getItem(ISINFORMATIONSELECT));

    useEffect(() => {
        getSettings();

        const handleStoreChange = () => {
            setGoInformation(localStorage.getItem(ISINFORMATIONSELECT));

        };

        window.addEventListener("storage", handleStoreChange);

        return () => {
            window.removeEventListener("storage", handleStoreChange);
        };
    }, [])

    // useEffect(()=>{

    // },[firmSettings])

    const getSettings = async () => {
        const resultSettings = await network.get("/admin/CoffeeSettings/GetSettings", {}, false);
        if (resultSettings?.data?.data) {

            const { data } = resultSettings.data;
            setfirmSettings({
                stampTimeLimit: data.stamptimelimit,
                getMailStampOrder: data.getmailstamporder,
                isInformationSelect: data.isinformationselect,
                stampCount: data.stampcount,
                isMailRequired: data.ismailrequired,
                isStampProductSelect: data.isstampproductselect,
                firmSettingsId: data.firmsettingsid
            })
            setIsExistRecord(true);
        }

    }

    const handleSaveOrUpdate = async () => {

        var model = {
            stampTimeLimit: firmSettings.stampTimeLimit,
            getMailStampOrder: firmSettings.getMailStampOrder,
            isInformationSelect: firmSettings.isInformationSelect,
            stampCount: firmSettings.stampCount,
            isMailRequired: firmSettings.isMailRequired,
            isStampProductSelect: firmSettings.isStampProductSelect,
            firmSettingsId: firmSettings.firmSettingsId
        }
        if (isExistRecord) {
            const result = await network.post("/admin/CoffeeSettings/UpdateSettings", model, false);

            if (result.isSuccess) {
                message.success(t("settingsSaved"));
                localStorage.setItem(FIRMSTAMPCOUNT, model.stampCount);
                localStorage.setItem(ISSTAMTPRODUCTSELECT, model.isStampProductSelect);
                localStorage.setItem(ISINFORMATIONSELECT, model.isInformationSelect);
                window.dispatchEvent(new Event("storage"));

                getSettings();
            } else {
                message.success(t("settingsSaveError"));
            }
        } else {
            const result = await network.post("/admin/CoffeeSettings/AddSettings", model, false);

            if (result.isSuccess) {
                message.success(t("settingsSaved"));
            } else {
                message.success(t("settingsSaveError"));
            }
        }

    }

    return (
        <div>
            <Card>

                <div  style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><CheckCircleOutlined /> {t("giftStampCount")}</h3>
                        <p>{t("giftStampCountDescription")}</p>
                    </div>
                    <div className="mt-3" style={{ width: "100px" }}>
                        <Select
                            style={{ width: "100%" }}
                            value={firmSettings.stampCount}
                            onChange={(value) => setfirmSettings({ ...firmSettings, stampCount: value })}
                            options={[
                                { value: 5, label: "5" },
                                { value: 6, label: "6" },
                                { value: 7, label: "7" },
                                { value: 8, label: "8" },
                                { value: 9, label: "9" },
                                { value: 10, label: "10" },
                                { value: 11, label: "11" },
                                { value: 12, label: "12" },
                                { value: 13, label: "13" },
                                { value: 14, label: "14" },
                                { value: 15, label: "15" }
                            ]}
                        />
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />

                <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><ShopOutlined /> {t("productSelection")}</h3>
                        <p>{t("productSelectionDescription")}</p>
                    </div>
                    <div className="mt-3">
                        <Switch checked={firmSettings.isStampProductSelect === true} size="default" onChange={(value) => setfirmSettings({ ...firmSettings, isStampProductSelect: value })} />
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />

                <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><MailOutlined /> {t("emailRequestFromCustomer")}</h3>
                        <p>{t("emailRequestDescription")}</p>
                    </div>
                    <div className="mt-3">
                        <Select
                            value={firmSettings.getMailStampOrder}
                            onChange={(value) => setfirmSettings({ ...firmSettings, getMailStampOrder: value })}
                            options={[
                                { value: 0, label: "Kayıt Sırasında" },
                                ...Array.from({ length: firmSettings.stampCount }, (_, index) => ({ value: index + 1, label: `${index + 1}. Damga` }))
                            ]}
                        />
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />

                <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><ExclamationCircleOutlined /> {t("emailRequired")}</h3>
                        <p>{t("emailRequiredDescription")}</p>
                    </div>
                    <div className="mt-3">
                        <Switch checked={firmSettings.isMailRequired === true} size="default" onChange={(value) => setfirmSettings({ ...firmSettings, isMailRequired: value })} />
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />

                <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><InfoCircleOutlined /> {t("postStampMessage")}</h3>
                        <p>{t("postStampMessageDescription")}</p>
                    </div>
                    <div className="mt-3">
                        <div className="ml-auto" style={{ width: "fit-content" }} >
                            <Switch checked={firmSettings.isInformationSelect} size="default" onChange={(value) => setfirmSettings({ ...firmSettings, isInformationSelect: value })} />
                        </div>
                        {goInformation === "true" ? (
                            <div className="mt-2 mb-1">
                                <Button type="default" onClick={() => changeTab("2")} icon={<ExportOutlined />}>{t("infoMessages")}</Button>
                            </div>
                        ) : null}
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />



                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><FieldTimeOutlined /> {t("stampTimeLimit")}</h3>
                        <p>{t("stampTimeLimitDescription")}</p>
                    </div>
                    <div className="mt-3" style={{ width: "100px" }}>
                        <Select
                            style={{ width: "100%" }}
                            value={firmSettings.stampTimeLimit}
                            onChange={(value) => setfirmSettings({ ...firmSettings, stampTimeLimit: value })}
                            options={[
                                { value: 15, label: "15" },
                                { value: 30, label: "30" },
                                { value: 60, label: "60" },
                                { value: 120, label: "120" },
                                { value: 480, label: "480" },
                                { value: 1440, label: "1 Gün" }

                            ]}
                        />
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />





                <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h3><FileImageOutlined /> {t("customizeChatImages")}</h3>
                        <p>{t("customizeChatImagesDescription")}</p>
                    </div>
                    <div className="mt-3">
                        <div className="mt-2 mb-1">
                            <Button type="default" onClick={() => changeTab("3")} icon={<ExportOutlined />}>{t("chatMedia")}</Button>
                        </div>
                    </div>
                </div>
                <hr style={{ opacity: "50%" }} />

                <div style={{ width: "100%",justifyContent:"end",display:"flex" }}>
                    <div className="mt-3" style={{ width: "250px"}}>
                        <ReusableButton text={t('save')} variant="campaign" onClick={handleSaveOrUpdate} icon={<SaveOutlined />} />
                    </div>
                </div>
            </Card>



        </div>
    )
}

export default FirmSettings