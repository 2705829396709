import { Avatar, Card, Col, Empty, Modal, Row, Select, Spin, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { FaCheckCircle, FaCoffee, FaRegCheckCircle, fasta, FaStamp, FaTimes, FaUser, FaUserCheck } from 'react-icons/fa';
import { connect } from 'react-redux';
import { selectCustomerList } from 'utils/redux/actions/customerActions';
import network from 'network/network';
import { selectBranchList } from 'utils/redux/actions/branchAction';
import ChartWidget from 'components/shared-components/ChartWidget';
import ReactApexChart from 'react-apexcharts';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import AvatarStatus from 'components/shared-components/AvatarStatus';

import moment from 'moment';
import { CheckCircleFilled, CheckCircleOutlined, CoffeeOutlined, CopyOutlined, DeleteFilled, DeleteOutlined, DownOutlined, HourglassOutlined, LoadingOutlined, UpCircleOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import DetailDashboardCustomer from '../dashboard-customer/detail-customer';
import { useTranslation } from 'react-i18next';



const CoffeeDashboard = (props) => {
    const { t, i18n } = useTranslation();

    const branchColors = {
        1: "#FF6A00",
        3: "rgb(204, 153, 0)",
        4: "#10375C",

    }

    const getLast12Months = () => {
        var months = []
        if (i18n.language === "en") {
            months = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
        } else if (i18n.language === "tr") {
            months = [
                "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
                "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
            ];
        }

        const currentMonth = new Date().getMonth(); // Mevcut ayı al (0-11)
        return Array.from({ length: 12 }, (_, i) => months[(currentMonth - i + 12) % 12]).reverse();
    }

    const [dataX, setDatax] = useState(getLast12Months());

    const [stampGraphicData, setStampGraphicData] = useState([]);
    const [newCustomerGraphicData, setNewCustomerGraphicData] = useState([]);

    const [increaseStamp, setIncreaseStamp] = useState(0);
    const [increaseCustomer, setIncreaseCustomer] = useState(0);
    const [increaseGift, setIncreaseGift] = useState(0);
    const [increaseActive, setIncreaseActive] = useState(0);

    const [topTenCustomerData, setTopTenCustomerData] = useState([]);
    const [lastTenData, setLastTenData] = useState([]);

    const [Stamplength, setStampLength] = useState(0);
    const [Giftlength, setGiftLength] = useState(0);
    const [newCustomerLength, setNewCustomerLength] = useState(0);
    const [activeCustomerLength, setActivecustomerLength] = useState(0);

    const [isModal, setIsModal] = useState(false);
    const [customerId, setCustomerId] = useState();

    const [loading, setLoading] = useState(false);

    const [pieValue, setPieValue] = useState( 0);

    const [graphState, setGraphState] = useState({

        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        },


    });


    const [increase, setIncrease] = useState({

    });

    const [pieState, setPieState] = useState({
        series: [44, 55, 13, 43, 22],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            colors: ["#FF6A00", "#4A4A4A", "#D3D3D3", "#00AFFF"],
            labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            tooltip: {
                enabled: true
            }
        }
    })


    const today = new Date();
    var differenceMonth = 11 - today.getMonth();

    const [SelectedBranch, setSelectedBranch] = useState(0);
    const [SelectedDate, setSelectedDate] = useState(2);
    const { customerList } = props;
    const { branchList } = props;

    var data = customerList;

    useEffect(() => {

        selectCustomerList();
 
    }, [])

    useEffect(() => {

        getCustomerData();

    }, [customerList, branchList])

    const getCustomerData = async () => {

        if (customerList) {
            var params = {}




            var resultPieClient = await network.get("/admin/Client/GetPieClientData", { branchId: 0, index: 2 }, false);
            var resultPieStamp = await network.get("/admin/Stamp/GetPieStampData", { branchId: 0, index: 2 }, false);
      
            setPieState(prevState => ({
                ...prevState,
                series: Object.values(resultPieStamp.data.data),
                options: {
                    ...prevState.options,
                    labels: Object.keys(resultPieStamp.data.data),
                    colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],

                }
            }))


        }
    }

    const selectCustomerList = async () => {
 
        setLoading(true);
        var params = {}
        props.selectCustomerListAction(params);
        props.selectBranchListAction(params);

        setPieValue(1);

        var resultStampMaster = await network.get("/admin/Stamp/GetAllStampByClient", params, false);
        var tempTopData = resultStampMaster?.data?.data;

        var resultTempStamp = await network.get("/admin/Stamp/GetAllWaitingStamp", params, false);
        var resultData = resultTempStamp?.data?.data;
 

        var mergedData = [
            ...tempTopData.map(item => ({ clientid: item.clientid, clientname: item.clientname, clientnumber: item.clientnumber, cardcount: item.cardcount, branchname: item.branchname, branchid: item.branchid, stampdate: item.stampdate, status: 0, islaststamp: item.islaststamp })),
            ...resultData.map(item => ({ clientid: item.clientid, clientname: item.clientname, clientnumber: item.clientnumber, cardcount: item.cardcount, branchname: item.branchname, branchid: item.branchid, stampdate: item.insertdate, status: item.status, islaststamp: false }))
        ];


        const nameCounts = {};

        tempTopData.forEach(item => {
            if (nameCounts[item.clientname]) {
                nameCounts[item.clientname].count++;
            } else {
                nameCounts[item.clientname] = { count: 1, clientnumber: item.clientnumber, clientid: item.clientid };
            }
        });

        var resultTop = Object.keys(nameCounts).map(name => ({
            clientname: name,
            count: nameCounts[name].count,
            clientnumber: nameCounts[name].clientnumber,
            clientid: nameCounts[name].clientid
        }));

        const topTen = resultTop.sort((a, b) => b.count - a.count).slice(0, 10);
        const lastTenProcess = mergedData.sort((a, b) => new Date(b.stampdate) - new Date(a.stampdate)).slice(0, 10);
 
        setTopTenCustomerData(topTen);
        setLastTenData(lastTenProcess);

        var resultStamp = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: 0, index: 2, isGift: false, isPrevious: false }, false);
        var resultStampPrevious = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: 0, index: 2, isGift: false, isPrevious: true }, false);
        setStampLength(resultStamp.data.data.length);

        setIncreaseStamp(calculateIncrease(resultStamp.data.data.length, resultStampPrevious.data.data.length));

        var resultGift = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: 0, index: 2, isGift: true, isPrevious: false }, false);
        var resultGiftPrevious = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: 0, index: 2, isGift: true, isPrevious: true }, false);

        setGiftLength(resultGift.data.data.length);
        setIncreaseGift(calculateIncrease(resultGift.data.data.length, resultGiftPrevious.data.data.length));

        var resultCustomer = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: 0, index: 2, isPrevious: false }, false);
        var resultCustomerPrevious = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: 0, index: 2, isPrevious: true }, false);

        setNewCustomerLength(resultCustomer.data.data.length);
        setIncreaseCustomer(calculateIncrease(resultCustomer.data.data.length, resultCustomerPrevious.data.data.length))

        var activeCustomer = await network.get("/admin/Client/GetActiveCustomers", { branchId: 0, index: 2, isPrevious: false }, false);
        var activeCustomerPrevious = await network.get("/admin/Client/GetActiveCustomers", { branchId: 0, index: 2, isPrevious: true }, false);
        var totalCustomer = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: 0, index: 3, isPrevious: false }, false);

        setActivecustomerLength((activeCustomer.data.data.length * 100) / totalCustomer.data.data.length);
        setIncreaseActive(calculateIncrease(activeCustomer.data.data.length, activeCustomerPrevious.data.data.length))

        var resultCustomerGraphic = await network.get("/admin/Client/GetGraphicClientData", { branchId: 0, index: 2 }, false);
        setNewCustomerGraphicData(resultCustomerGraphic.data.data);

        var resultStampGraphic = await network.get("/admin/Stamp/GetGraphicStampData", { branchId: 0, index: 2 }, false);
        setStampGraphicData(resultStampGraphic.data.data);

        setGraphState((prevState) => ({
            ...prevState,
            series: [{ name: t('newCustomer'), data: resultCustomerGraphic.data.data },
            { name: t('stampCount'), data: resultStampGraphic.data.data }
            ],
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: getLast12Months(),
                    labels: {
                        rotate: -40,
                        rotateAlways: true,
                        style: {
                            fontSize: '12px',
                        },
                        offsetY: 0,
                        textAnchor: 'middle'
                    }
                }

            }
        }));

        setTimeout(() => {
            setLoading(false);
        }, (100));


    }

    const changeDateandBranchHandler = async (date, branch) => {



        setSelectedBranch(branch);
        setSelectedDate(date);
        var resultStamp = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: branch, index: date, isGift: false, isPrevious: false }, false);
        var resultStampPrevious = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: branch, index: date, isGift: false, isPrevious: true }, false);
        setStampLength(resultStamp.data.data.length);

        setIncreaseStamp(calculateIncrease(resultStamp.data.data.length, resultStampPrevious.data.data.length));


        var resultGift = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: branch, index: date, isGift: true, isPrevious: false }, false);
        var resultGiftPrevious = await network.get("/admin/Stamp/GetAllByBranchDate", { branchId: branch, index: date, isGift: true, isPrevious: true }, false);
        setGiftLength(resultGift.data.data.length);

        setIncreaseGift(calculateIncrease(resultGift.data.data.length, resultGiftPrevious.data.data.length));



        var resultCustomer = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: branch, index: date, isPrevious: false }, false);
        var resultCustomerPrevious = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: branch, index: date, isPrevious: true }, false);
        setNewCustomerLength(resultCustomer.data.data.length);


        setIncreaseCustomer(calculateIncrease(resultCustomer.data.data.length, resultCustomerPrevious.data.data.length))


        var activeCustomer = await network.get("/admin/Client/GetActiveCustomers", { branchId: branch, index: date, isPrevious: false }, false);
        var activeCustomerPrevious = await network.get("/admin/Client/GetActiveCustomers", { branchId: branch, index: date, isPrevious: true }, false);
        var totalCustomer = await network.get("/admin/Client/GetAllClientBranchDate", { branchId: 0, index: 3, isPrevious: false }, false);

        setActivecustomerLength((activeCustomer.data.data.length * 100) / totalCustomer.data.data.length);

        setIncreaseActive(calculateIncrease(activeCustomer.data.data.length, activeCustomerPrevious.data.data.length))


        setDatax(getLast7Days());

        var resultCustomerGraphic = await network.get("/admin/Client/GetGraphicClientData", { branchId: branch, index: date }, false);
        setNewCustomerGraphicData(resultCustomerGraphic.data.data);


        var resultStampGraphic = await network.get("/admin/Stamp/GetGraphicStampData", { branchId: branch, index: date }, false);
        setStampGraphicData(resultStampGraphic.data.data);

        var resultPieClient = await network.get("/admin/Client/GetPieClientData", { branchId: branch, index: date }, false);
        var resultPieStamp = await network.get("/admin/Stamp/GetPieStampData", { branchId: branch, index: date }, false);
 
        setPieState(prevState => ({
            ...prevState,
            series: pieValue == 0 ? Object.values(resultPieStamp.data.data) : Object.values(resultPieClient.data.data),
            options: {
                ...prevState.options,
                labels: pieValue == 0 ? Object.keys(resultPieStamp.data.data) : Object.keys(resultPieClient.data.data),
                colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],

            }
        }))


        if (date == 1) {
            setGraphState((prevState) => ({
                ...prevState,
                series: [{ name: t('newCustomer'), data: resultCustomerGraphic.data.data },
                { name: t('stampCount'), data: resultStampGraphic.data.data }
                ],
                options: {
                    ...prevState.options,
                    xaxis: {
                        ...prevState.options.xaxis,
                        categories: getLast7Days(),
                    }

                }
            }));
        } else if (date == 2 || date == 3 || date == 4) {
            setGraphState((prevState) => ({
                ...prevState,
                series: [{ name: t('newCustomer'), data: resultCustomerGraphic.data.data },
                { name: t('stampCount'), data: resultStampGraphic.data.data }
                ],
                options: {
                    ...prevState.options,
                    xaxis: {
                        ...prevState.options.xaxis,
                        categories: getLast12Months(),
                    }

                }
            }));
        } else if (date == 0) {
            setGraphState((prevState) => ({
                ...prevState,
                series: [{ name: t('newCustomer'), data: resultCustomerGraphic.data.data },
                { name: t('stampCount'), data: resultStampGraphic.data.data }
                ],
                options: {
                    ...prevState.options,
                    xaxis: {
                        ...prevState.options.xaxis,
                        categories: ["06.00 - 08.00", "08.00 - 10.00", "10.00 - 12.00", "12.00 - 14.00", "14.00 - 16.00", "16.00 - 18.00",
                            "18.00 - 20.00", "20.00 - 22.00", "22.00 - 00.00"
                        ],
                        labels: {
                            rotate: -40,       // Rotate the labels vertically
                            rotateAlways: true, // Ensure that the rotation always occurs
                            style: {
                                fontSize: '12px', // Adjust the font size if necessary
                            },
                            offsetY: 0,         // Adjust vertical alignment of labels
                            textAnchor: 'middle' // Align the labels properly
                        }
                    }

                }
            }));
        }
    }
    const getLast7Days = () => {
        var days = [];

        if (i18n.language === "tr") {
            days = [
                "Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"
            ];
        } else {
            days = [
                "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
            ];
        }


        const currentDay = new Date().getDay(); // Mevcut günü al (0-6)
        return Array.from({ length: 7 }, (_, i) => days[(currentDay - i + 7) % 7]).reverse();
    };

    const changePieChartHandler = async (value) => {

        setPieValue(value);

        var resultPieClient = await network.get("/admin/Client/GetPieClientData", { branchId: SelectedBranch, index: SelectedDate }, false);
        var resultPieStamp = await network.get("/admin/Stamp/GetPieStampData", { branchId: SelectedBranch, index: SelectedDate }, false);

        switch (value) {
            case 0:
                setPieState(prevState => ({
                    ...prevState,
                    series: Object.values(resultPieStamp.data.data),
                    options: {
                        ...prevState.options,
                        labels: Object.keys(resultPieStamp.data.data),
                        colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],

                    }
                }))
                break;
            case 1:
                setPieState(prevState => ({
                    ...prevState,
                    series: Object.values(resultPieClient.data.data),
                    options: {
                        ...prevState.options,
                        labels: Object.keys(resultPieClient.data.data),
                        colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],

                    }
                }))
                break;

        }



    }

    const calculateIncrease = (currentCount, previousCount) => {
        return (((currentCount - previousCount) / previousCount) * 100).toFixed(0);
    }



    const handleModalOpen = (id) => {
        setIsModal(true);
        setCustomerId(id);
    }

    function checkProcessStatus(item) {
        if (item.status == 3) {
            return (
                <Tooltip title={t('stampCancelled')}>

                    <DeleteOutlined style={{ fontSize: "27px", opacity: 0.6 }} />

                </Tooltip>
            );
        } else if (item.status == 1) {
            return (
                <Tooltip title={t('stampRequestWait')}>

                    <HourglassOutlined style={{ fontSize: "27px", opacity: 0.6 }} />

                </Tooltip>
            );

        } else if (item.islaststamp == true) {
            return (
                <Tooltip title={t('coffeeGift')}>

                    <FaCoffee style={{ fontSize: "27px", opacity: 0.6 }} />

                </Tooltip>
            );

        } else if (item.islaststamp == false) {
            return (<Tooltip title={t('stampAccepted')}>

                <FaRegCheckCircle style={{ fontSize: "27px", opacity: 0.6 }} />

            </Tooltip>);

        }

        return null;
    }


    return (
        <div>
            {loading ? (<div style={{ width: "fit-content", marginTop: "25%" }} className="mx-auto"><Spin tip={t("dataLoading")}  size="large" spinning={loading}></Spin></div>) : (
                <>
                    <Modal title={t('customerInfoCard')} open={isModal} width={800} onCancel={() => setIsModal(false)} footer={null} >
                        <DetailDashboardCustomer customerId={customerId} />
                    </Modal>

                    <Row justify="end">
                        <Col className='mr-2 mb-2' span={1.5}>
                            <Select
                                defaultValue={t('currentMonth')}
                                style={{ width: 125, paddingLeft: "8px" }}
                                options={[
                                    { value: 0, label: t('today') },
                                    { value: 1, label: t('last7Days') },
                                    { value: 2, label: t('currentMonth') },
                                    { value: 4, label: t('previousMonth') },
                                    { value: 3, label: t('allTime') },
                                ]}
                                onChange={(value) => changeDateandBranchHandler(value, SelectedBranch)}
                                size='small'

                            />
                        </Col>

                        <Col className='mb-2' span={1.5}>
                            <Select
                                defaultValue={SelectedBranch}
                                style={{ width: 125 }}
                                options={[
                                    ...branchList.map(item => ({
                                        value: item.branchid,
                                        label: item.branchname
                                    })),
                                    { value: 0, label: t('all') }
                                ]}
                                onChange={(value) => changeDateandBranchHandler(SelectedDate, value)}
                                size='small'

                            />
                        </Col>
                    </Row>
                    <Row gutter={16} align={"stretch"} justify={"center"}>
                        <Col className="gutter-row height-100" xs={24} sm={24} md={12} lg={6} >
                            <Card >
                                <div className='text-left'>
                                    <h4>{t('newCustomer')}</h4>
                                    <div className='d-flex justify-content-between'>

                                        <div className='d-flex'>
                                            <h1 className='mb-0 font-weight-bold text-left'>{newCustomerLength}</h1>

                                            {!isNaN(increaseCustomer) && isFinite(increaseCustomer) && increaseCustomer != 0 ? (<div className='d-flex ml-3 align-self-center font-weight-bold' style={{ fontSize: "13px" }}>

                                                {increaseCustomer > 0 ? (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "#1eba26" }}>{increaseCustomer}%</p>
                                                    <UpOutlined className='my-auto' style={{ fontSize: "15px", color: "#1eba26" }} />
                                                </>)
                                                    : (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "red" }}>{increaseCustomer}%</p>
                                                        <DownOutlined className='my-auto' style={{ fontSize: "15px", color: "red" }} />
                                                    </>)}
                                            </div>

                                            ) : (<></>)}

                                        </div>

                                        <div>
                                            <FaUser style={{ fontSize: "45px", opacity: 0.6 }} />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row height-100" xs={24} sm={24} md={12} lg={6}>
                            <Card >
                                <div className='text-left'>
                                    <h4>{t('stampCount')}</h4>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <h1 className='mb-0 font-weight-bold'>{Stamplength}</h1>

                                            {!isNaN(increaseStamp) && isFinite(increaseStamp) && increaseStamp != 0 ? (<div className='d-flex ml-3 align-self-center font-weight-bold' style={{ fontSize: "13px" }}>

                                                {increaseStamp > 0 ? (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "#1eba26" }}>{increaseStamp}%</p>
                                                    <UpOutlined className='my-auto' style={{ fontSize: "15px", color: "#1eba26" }} />
                                                </>)
                                                    : (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "red" }}>{increaseStamp}%</p>
                                                        <DownOutlined className='my-auto' style={{ fontSize: "15px", color: "red" }} />
                                                    </>)}
                                            </div>
                                            ) : (<></>)}

                                        </div>
                                        <div>
                                            <FaRegCheckCircle style={{ fontSize: "45px", opacity: 0.6 }} />
                                        </div>

                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row height-100" xs={24} sm={24} md={12} lg={6}>
                            <Card >
                                <div className='text-left'>
                                    <h4>{t('giftedCoffee')}</h4>
                                    <div className='d-flex justify-content-between'>

                                        <div className='d-flex'>
                                            <h1 className='mb-0 font-weight-bold'>{Giftlength}</h1>
                                            {!isNaN(increaseGift) && isFinite(increaseGift) && increaseGift != 0 ? (<div className='d-flex ml-3 align-self-center font-weight-bold' style={{ fontSize: "13px" }}>

                                                {increaseGift > 0 ? (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "#1eba26" }}>{increaseGift}%</p>
                                                    <UpOutlined className='my-auto' style={{ fontSize: "15px", color: "#1eba26" }} />
                                                </>)
                                                    : (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "red" }}>{increaseGift}%</p>
                                                        <DownOutlined className='my-auto' style={{ fontSize: "15px", color: "red" }} />
                                                    </>)}
                                            </div>
                                            ) : (<></>)}
                                        </div>
                                        <div>
                                            <FaCoffee style={{ fontSize: "45px", opacity: 0.6 }} />
                                        </div>

                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="gutter-row height-100" xs={24} sm={24} md={12} lg={6}>
                            <Card>
                                <div className='text-left'>
                                    <h4>{t('activeCustomer')}</h4>
                                    <div className='d-flex justify-content-between'>

                                        <div className='d-flex'>

                                            <h1 className='mb-0 font-weight-bold'>{isNaN(activeCustomerLength) ? "0%" : activeCustomerLength.toFixed(2) + "%"}</h1>
                                            {!isNaN(increaseActive) && isFinite(increaseActive) && increaseActive != 0 ? (<div className='d-flex ml-3 align-self-center font-weight-bold' style={{ fontSize: "13px" }}>

                                                {increaseActive > 0 ? (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "#1eba26" }}>{increaseActive}%</p>
                                                    <UpOutlined className='my-auto' style={{ fontSize: "15px", color: "#1eba26" }} />
                                                </>)
                                                    : (<><p className='my-auto font-weight-bold' style={{ fontSize: "15px", color: "red" }}>{increaseActive}%</p>
                                                        <DownOutlined className='my-auto' style={{ fontSize: "15px", color: "red" }} />
                                                    </>)}
                                            </div>
                                            ) : (<></>)}
                                        </div>
                                        <div>
                                            <FaUserCheck style={{ fontSize: "45px", opacity: 0.6 }} />
                                        </div>

                                    </div>
                                </div>
                            </Card>
                        </Col>

                    </Row>
                    <Row gutter={16} justify={"center"} align={"stretch"}>

                        <Col span={15} className='height-100' xs={24} sm={24} md={24} lg={14}>
                            <Card title={t("Stamp") + "/" + t('newCustomer')}>
                                <ReactApexChart options={graphState.options} series={graphState.series} type='bar' height={400} />
                            </Card>

                        </Col>

                        <Col span={9} className='mb-4 height-100' xs={24} sm={24} md={24} lg={10}>
                            <Card style={{ height: "100%" }} bodyStyle={{ height: "86%", display: "flex" }} title={t('branchDistribution')} extra={[
                                <Row justify={"end"} className='mb-3'>
                                    <Select
                                        defaultValue={t('Stamp')}
                                        style={{ width: 150 }}
                                        options={[
                                            { value: 0, label: t('Stamp') },
                                            { value: 1, label: t('newCustomer') },
                                        ]}
                                        onChange={changePieChartHandler}
                                    />
                                </Row>
                            ]}>
                                <div className='mx-auto my-auto'>

                                    <ReactApexChart
                                        options={pieState.options}
                                        series={pieState.series}
                                        type="donut"
                                        width={400}
                                    />

                                </div>
                            </Card>

                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={10}>
                            <Card title={t('top10Stamps')}>
                                <div className='mb-1'>
                                    {topTenCustomerData.map((item, index) => (
                                        <Row className='mb-4 align-items-center'>
                                            <Col span={2} style={{ fontWeight: "bold" }}>
                                                <div className='ml-3' style={{ maxWidth: "fit-content" }}>
                                                    {index + 1}.
                                                </div>
                                            </Col>
                                            <Col span={15}>
                                                <div className='ml-3' style={{ maxWidth: "fit-content" }}>
                                                    <div className="avatar-status d-flex align-items-center">
                                                        <Avatar src={`${process.env.PUBLIC_URL}/img/avatars/user-blank-image.jpg`}></Avatar>
                                                        <div className="ml-2">
                                                            <div>
                                                                <div onClick={() => handleModalOpen(item.clientid)} className="avatar-status-name clickable client-name">
                                                                    <a style={{ color: 'inherit', textDecoration: 'none' }}>{item.clientname}</a>
                                                                </div>
                                                                {/* <span>{suffix}</span> */}
                                                            </div>
                                                            <div className="text-muted avatar-status-subtitle">{item.clientnumber}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col span={6}>
                                                <div className='avatar-status-name mx-auto' style={{ maxWidth: "fit-content" }}>
                                                    {/* <p >{item.count} Damga</p> */}
                                                    <Tag
                                                        bordered={false}
                                                        color='blue'
                                                        icon={<CheckCircleOutlined />}
                                                    >{item.count} {t('Stamp')}

                                                    </Tag>
                                                </div>
                                            </Col>


                                        </Row>

                                    ))}
                                </div>

                            </Card>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={14}>
                            <Card title={t('last10Process')}>
                                <div className='mb-1'>
                                    {lastTenData.map((item, index) => (
                                        <Row className='mb-4 align-items-center' >

                                            <Col span={1} style={{ fontWeight: "bold" }}>{index + 1}.</Col>
                                            <Col span={6}>
                                                <div className='ml-3' style={{ maxWidth: "fit-content" }}>
                                                    <div className="avatar-status d-flex align-items-center">
                                                        <Avatar src={`${process.env.PUBLIC_URL}/img/avatars/user-blank-image.jpg`}></Avatar>
                                                        <div className="ml-2">
                                                            <div>
                                                                <div onClick={() => handleModalOpen(item.clientid)} className="avatar-status-name clickable client-name">
                                                                    <a style={{ color: 'inherit', textDecoration: 'none' }}>{item.clientname}</a>
                                                                </div>
                                                                {/* <span>{suffix}</span> */}
                                                            </div>
                                                            <div className="text-muted avatar-status-subtitle">{item.clientnumber}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                            <Col span={8}>
                                                <div className='mx-auto' style={{ maxWidth: "fit-content", fontSize: "13px", fontWeight: "inherit" }}>
                                                    {
                                                        (() => {
                                                            const language = i18n.language === "en" ? "en-EN" : "tr-TR"
                                                            const date = new Date(item.stampdate);
                                                            const day = date.getDate();
                                                            const month = date.toLocaleString(language, { month: 'short' });
                                                            const weekday = date.toLocaleString(language, { weekday: 'short' });
                                                            const time = date.toLocaleTimeString(language, { hour: 'numeric', minute: 'numeric' });

                                                            return (
                                                                <>
                                                                    <div className='text-center'>{time}</div>
                                                                    <div>{`${day} ${month}, ${weekday}`}</div>
                                                                </>
                                                            );
                                                        })()
                                                    }
                                                </div>

                                            </Col>
                                            <Col style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }} span={3}>

                                                <Tag
                                                    bordered={false}
                                                    color='purple'
                                                    icon={<CopyOutlined />}
                                                >{item.cardcount}. {t('Card')}</Tag>

                                            </Col>
                                            <Col style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }} span={3}>

                                                <Tag
                                                    style={{
                                                        borderColor: `${branchColors[item.branchid]}`, // 33 is the opacity in HEX (20% opacity)
                                                        color: `${branchColors[item.branchid]}`,
                                                        fontWeight: "bold" // Text color remains fully opaque
                                                        // Optional: Custom border color if you want it
                                                    }}

                                                >
                                                    {item.branchname}
                                                </Tag>

                                            </Col>



                                            <Col style={{ fontWeight: "bold" }} span={3}>
                                                <div className='mx-auto avatar-status-name' style={{ maxWidth: "fit-content" }}>
                                                    {checkProcessStatus(item)}
                                                </div>

                                            </Col>


                                        </Row>

                                    ))}
                                </div>
                            </Card>
                        </Col>



                    </Row>

                </>
            )}

        </div>
    )
}


function mapStateToProps(state) {

    return {
        customerList: state.customerReducer.customerList,
        branchList: state.branchReducer.branchList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCustomerListAction: bindActionCreators(selectCustomerList, dispatch),
        selectBranchListAction: bindActionCreators(selectBranchList, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoffeeDashboard);
