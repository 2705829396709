import React from 'react';
import { Button, Input, Select, Tooltip, Upload } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const TemplateMediaSelectBox = ({
  title,
  description,
  value,
  onChange,
  languageData = [],
  infoMessage,
  subDesc,
  isRequired = false,
  mediaType,
  beforeFileUpload,
  handleMediaChange
}) => {
  const { t, i18n } = useTranslation();
  const NoticeComponent = () => {

    return (
      <div
        style={{
          color: '#595959',
          fontSize: '12px',
          marginTop: '8px',
          padding: '10px',
          border: '1px solid #f5222d',  // Kırmızı çerçeve
          borderRadius: '4px',
          backgroundColor: '#fff2f0',  // Hafif kırmızımsı arka plan
        }}
      >
        <strong>{t('note')}</strong> {t('fileFormatsAndSizes')}
        <ul>
          <li>
            <strong>{t('image')}</strong>: <span style={{ color: '#f5222d' }}>{t('jpegPng')}</span>
            <span style={{ color: '#f5222d' }}> ({t('maxSize')} 5 MB)</span>
          </li>
          <li>
            <strong>Video:</strong> <span style={{ color: '#f5222d' }}>MP4</span>
            <span style={{ color: '#f5222d' }}>(Maksimum boyut 16 MB)</span>
          </li>
          <li>
            <strong>Belge:</strong> <span style={{ color: '#f5222d' }}>PDF</span>
            <span style={{ color: '#f5222d' }}>(Maksimum boyut 100 MB)</span>
          </li> 
        </ul >
      </div >
    );
  }
<style></style>
const GetHeaderComponent = () => {

  if (mediaType == 1) {
    return <Input
      name="header"
      placeholder={t('titlePlaceholder')}
      style={{ marginBottom: '8px' }}
      onChange={handleMediaChange} />
  }
  else if (mediaType == 2) {
    return <Upload
      action="https://httpbin.org/post"  // Yükleme işlemine gidecek API endpoint'i buraya koymalısınız.
      listType="picture"
      showUploadList={true} // Yükleme sonrası listeyi gösterme
      onChange={handleMediaChange}  // Dosya seçimiyle ilgili işleme fonksiyonu
    >
      <Button icon={<UploadOutlined />}>{t('uploadPhoto')}</Button>
    </Upload>
  }
  else if (mediaType == 3) {
    return <Upload
      action="https://httpbin.org/post"  // Yükleme işlemine gidecek API endpoint'i buraya koymalısınız.
      listType="video"
      showUploadList={true} // Yükleme sonrası listeyi gösterme
      beforeUpload={beforeFileUpload}
      onChange={handleMediaChange}  // Dosya seçimiyle ilgili işleme fonksiyonu
    >
      <Button icon={<UploadOutlined />}>{t('uploadVideo')}</Button>
    </Upload>
  }
  else if (mediaType == 4) {
    return <Upload
      action="https://httpbin.org/post"  // Yükleme işlemine gidecek API endpoint'i buraya koymalısınız.
      listType="file"
      showUploadList={true} // Yükleme sonrası listeyi gösterme
      beforeUpload={beforeFileUpload}
      onChange={handleMediaChange}  // Dosya seçimiyle ilgili işleme fonksiyonu
    >
      <Button icon={<UploadOutlined />}>{t('uploadDocument')}</Button>
    </Upload>
  }
}

return (

  <div style={{
    marginBottom: '20px',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    flexDirection: 'column',
    height: 'auto',  // Yüksekliği içeriğe göre ayarlamak için 'auto'
    flexGrow: 1 // İçeriğin genişlemesine izin vermek
  }}>
    {/* Header with Info Icon and Required/Optional Badge */}
    <div style={{
      display: 'flex',
      justifyContent: 'space-between', // Başlık ve icon arasını ayırmak için
      alignItems: 'center', // Başlık ve badge'i ortalamak için
      marginBottom: '8px',
    }}>
      <div style={{
        display: 'flex',
      }}>
        <h3 style={{
          fontSize: '18px',
          fontWeight: '600',
          color: '#333',
          fontFamily: 'Helvetica, sans-serif',
          marginRight: '10px',
        }}>
          {title}
        </h3>

        {/* Required/Optional Badge */}
        {isRequired ? (
          <div style={{
            padding: '2px 6px',
            backgroundColor: '#E6F7FF',
            borderRadius: '6px',
            fontSize: '14px',
            color: '#1890ff',
            fontWeight: '500',
            marginRight: '10px',
            height: '25px',
            display: 'flex',
            alignItems: 'center'
          }}>
            {t('required')}
          </div>
        ) : (
          <div style={{
            padding: '2px 6px',
            backgroundColor: '#FFF6E6',
            borderRadius: '6px',
            fontSize: '14px',
            color: '#000010',
            fontWeight: '500',
            marginRight: '10px',
            height: '25px',
            display: 'flex',
            alignItems: 'center'
          }}>
            {t('optional')}
          </div>
        )}
      </div>

      {/* Info Tooltip */}
      {infoMessage && infoMessage.length > 0 && (
        <Tooltip title={infoMessage}>
          <InfoCircleOutlined style={{ color: '#1890ff', fontSize: '18px' }} />
        </Tooltip>
      )}
    </div>

    {/* Açıklama */}
    <p style={{
      fontSize: '14px',
      color: '#595959',
      marginBottom: '16px',
      fontFamily: 'Verdana, sans-serif'
    }}>
      {description}
    </p>

    {/* Select alanı */}
    <div style={{ marginBottom: '12px' }}>
      <Select
        value={value}
        onChange={onChange}
        style={{
          width: '100%',
          borderRadius: '8px',
          boxSizing: 'border-box',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          color: '#333',
          backgroundColor: '#fff', // Beyaz arka plan
          transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
        }}
        dropdownStyle={{
          borderRadius: '8px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          maxHeight: '300px', // Uzun listelerde max yükseklik
          overflowY: 'auto', // Uzun listelerde kaydırma
        }}
      >
        {languageData.map((item) => (
          <Option key={item.key} value={item.value}>
            {item.value}
          </Option>
        ))}
      </Select>
    </div>

    {/* Info Bilgisi */}
    {subDesc && subDesc.length > 0 && (
      <div style={{ color: '#595959', fontSize: '12px', fontFamily: 'Verdana, sans-serif' }}>
        {subDesc}
      </div>
    )}

    <div className='mt-4'>
      {GetHeaderComponent()}
    </div>

    <div className='mt-4'>
      {NoticeComponent()}
    </div>
  </div>

);
};

export default TemplateMediaSelectBox;
