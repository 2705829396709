import { CheckOutlined, CloseSquareOutlined, EditOutlined, ProductOutlined } from "@ant-design/icons";
import { Card, Input, message, Table, Tag } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CoffeeProduct = () => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        selectProducts();
    })

    const selectProducts = async () => {
        var result = await network.get("/admin/CoffeeProduct/getAllProductsWithProductName");
        const { data } = result.data;

        setProducts(data);
    }

    const handleStatusChange = async (value) => {

        var params = {
            productId: value.productId,
            productCode: value.productCode,
            productName: value.productName,
            isGiftProduct: value.isGiftProduct,
            productGroupId: value.productGroupId,
            productShortName: value.productShortName,
            status: !value.status
        }

        var result = await network.post("/admin/CoffeeProduct/UpdateProduct", params);

        if (result.isSuccess) {
            message.success(t("productUpdated"));  // Yerelleştirilmiş mesaj
            selectProducts();
        }

    }

    const filteredProduct = products.filter(product =>
        Object.values(product).some(value =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    )

    const dropdownMenu = (record) => {
        return [

            {
                label: (<span >{t('edit')}</span>),
                key: '3',
                icon: <EditOutlined />,
                onClick: () => navigate(`coffeeproduct-create/${record.productId}`)
            },
            {
                label: (<span >{!record.status ? t('active') : t('inactive')}</span>),
                key: '4',
                icon: !record.status ? <CheckOutlined /> : <CloseSquareOutlined />,
                onClick: () => handleStatusChange(record)
            }
        ]
    }

    const tableColumns = [
        {
            title: t("productName"),
            dataIndex: "productName",
            render: (_, record) => (
                <div>
                    {record.productName}
                </div>
            )
        },
        {
            title: t("productCode"),
            dataIndex: "productCode",
            render: (_, record) => (
                <div>
                    
                        {record.productCode}
              
                </div>
            )
        },
        {
            title: t("productGroup"),
            dataIndex: "productGroup",
            render: (_, record) => (
                <div>
                    <Tag color="blue">
                        {record.productGroupName}
                    </Tag>
                </div>
            )
        },
        {
            title: t("productDesc"),
            dataIndex: "productDesc",
            render: (_, record) => (
                <div>
                    {record.productShortName}
                </div>
            )
        },
        {
            title: t("giftProduct"),
            dataIndex: "productShortName",
            render: (_, record) => (
                <div>
                    {record.isGiftProduct ? t("yes") : t("no")}
                </div>
            )
        },
        {
            title: t("status"),
            dataIndex: "status",
            render: (_, record) => (
                <div>
                    <Tag color={record.status ? "green" : "red"}>
                        {record.status ? t("active") : t("inactive")}
                    </Tag>
                </div>
            )
        },
        {
            title: "",
            dataIndex: "",
            render: (_, record) => (
                <div>
                    <EllipsisDropdown menu={{ items: dropdownMenu(record) }} />
                </div>
            )
        }

    ]

    return (
        <>
            <Card title={t("menu.product")}>


                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="mb-3" style={{width:"250px"}}>
                        <Input

                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="mb-3" style={{ width: "200px" }}>
                        <ReusableButton
                            variant="campaign"
                            text={t("newProduct")}
                            icon={<ProductOutlined />}
                            href={"coffee-product/coffeeproduct-create/0"}
                        />
                    </div>
                </div>
                <Table
                    columns={tableColumns}
                    dataSource={filteredProduct}
                />
            </Card>
        </>
    )
}

export default CoffeeProduct