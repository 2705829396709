import { Card, Modal, Row } from "antd";
import React, { useState } from "react";

import { QRCodeCanvas } from 'qrcode.react';
import html2canvas from 'html2canvas';
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import ReusableButton from "./ReusableButton";



const QrCodeModal = React.forwardRef((props, ref) => {

    const { t, } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);
    const [qrLink, setQrLink] = useState("");
    const [qrDesc, setQrDesc] = useState("");

    React.useImperativeHandle(ref, () => ({

        openModal(link, title) {
            setQrLink(link);
            setModalVisible(true);
            setQrDesc(title);
        }
    }));

    const downloadPDF = () => {
        const qrElement = document.getElementById('qr-code');

        html2canvas(qrElement).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg'); 

            const doc = new jsPDF();
            doc.addImage(imgData, 'JPEG', 10, 50, 200, 130);
            doc.save('qrcode.pdf');
        });
    };

    const downloadQRCode = () => {
        const qrElement = document.getElementById('qr-code');
        html2canvas(qrElement).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg');
            link.download = 'qrcode.jpg';
            link.click();
        });
    };
    return (
        <Modal title={qrDesc} open={modalVisible} width={800} onCancel={() => setModalVisible(false)} footer={null} >

            <div id="qr-code" className="text-center px-4 py-4">
                <QRCodeCanvas value={qrLink} size={400} />
            </div>
            <Row className="mb-3" justify={"center"}>
            <div className="mt-4 mr-4">
                <ReusableButton
                    variant="campaign"
                    text={t('downloadQrCodeImage')}
                    onClick={downloadQRCode}
                    icon={<CloudDownloadOutlined />}
                />
            </div>
            <div className="mt-4 ">
                <ReusableButton
                    variant="campaign"
                    text={t('downloadQrCodePdf')}
                    onClick={downloadPDF}
                    icon={<FilePdfOutlined />}
                />
            </div>
            </Row>
        </Modal>
    );
})
export default QrCodeModal;