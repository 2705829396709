import globalConstant from "constants/globalConstant";
import { MESSAGE_TYPES } from "constants/messageConstants";
import network from "network/network";
import actionTypes from "./actionTypes";
import { notification } from 'antd';


export function appListener(received) {

    return (dispatch, getState) => {

        try {
            var actionType = "";
            var payload = {};
            var listenerType = received.type;
            var title = "";
            var content = "";
            switch (listenerType) {
                case globalConstant.listenerTypes.inboundmessage:
                    actionType = actionTypes.ON_CHAT_LISTENER;
                    payload = JSON.parse(received.payload);
                    break;
                case globalConstant.listenerTypes.outboundmessage:
                    actionType = actionTypes.ON_CHAT_LISTENER;
                    payload = JSON.parse(received.payload);
                    break;
                case globalConstant.listenerTypes.connectCustomerService:
                    actionType = actionTypes.ON_CUSTOMER_SERVICE_LISTENER;
                    payload = JSON.parse(received.payload);

                    title = "Müşteri Temsilcisine Bağlantı"
                    content = `${payload.sourcePhone} Numaralı telefondan Müşteri temsilcisine bağlantı isteği gelmiştir.`;
                    break;
                case globalConstant.listenerTypes.neworder:
                    actionType = actionTypes.ON_ORDER_LISTENER;
                    payload = JSON.parse(received.payload);
                    title = "Yeni Sipariş"
                    content = "Sisteme yeni sipariş kaydı gelmiştir.";
                    break;
                case globalConstant.listenerTypes.newcustomer:
                    actionType = actionTypes.ON_NEWCUSTOMER_LISTENER;
                    payload = JSON.parse(received.payload);

                    title = "Yeni Müşteri"
                    content = "Sisteme yeni müşteri kaydı gelmiştir.";

                    break;
                case globalConstant.listenerTypes.newrequest:
                    actionType = actionTypes.ON_REQUEST_INFORMATION_LISTENER;
                    payload = JSON.parse(received.payload);

                    var customerNumber = payload.sourcePhone;
                    var customerName = payload.customername;
                    title = "Bilgi Talebi"
                    content = `${customerNumber} Numaralı ${customerName} Adlı kullanıcı bilgi talebinde bulundu`;

                    break;
                case globalConstant.listenerTypes.newBook:
                    actionType = actionTypes.ON_RESERVATION_LISTENER;
                    payload = JSON.parse(received.payload);

                    title = "Yeni Müşteri"
                    content = "Sisteme yeni müşteri kaydı gelmiştir.";

                    break;
                // case globalConstant.listenerTypes.newstamp:
                //     actionType = actionTypes.STAMP_LISTENER;
                //     payload = JSON.parse(received.payload);
                //     title = "Damga"
                //     content = `Damga Bilgisi Geldi`;
                //     break;
                default:
                    break;
            }
            if (title.length > 0) {
                notification.success({
                    message: title,
                    description: content,
                    placement: "topRight",
                });
            }



            if (actionType == actionTypes.ON_CHAT_LISTENER) {
                var text = payload.message.text;
                if (payload.message.type == MESSAGE_TYPES.QUICK_REPPLY) {
                    text = payload.message.content.header;
                }
                if (payload.message.type == MESSAGE_TYPES.LIST) {
                    text = payload.message.title;
                }
                if (payload.message.type == MESSAGE_TYPES.FILE) {
                    text = payload.message.filename || "Dosya";
                }
                payload.lastWrittenMessage = text;

                dispatch({
                    type: actionTypes.CHAT_MASTER_UPDATE_PREV_TEXT,
                    newText: text,
                    customerNumber: payload.destination
                })
            }

            if (actionType == actionTypes.STAMP_LISTENER) {
                var stampData = payload
                dispatch({
                    type: actionTypes.STAMP_LISTENER,
                    newData:stampData,
                    
                })
            }


            dispatch({
                type: actionType,
                payload: payload
            })
        } catch (error) {
            //alert(error);
        }
    }
};




