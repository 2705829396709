import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import FirmSettings from "./firm-settings";
import InformationSettings from "./information-settings";
import MediaSettings from "./media-settings";
import { ISINFORMATIONSELECT } from "constants/StorageConstant";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs

const Settings = () => {
    const { t, i18n } = useTranslation();
    const [isInfoTabVisible, setIsInfoTabVisible] = useState(
        localStorage.getItem(ISINFORMATIONSELECT)
    );
    const [activeKey, setActiveKey] = useState("1");

    useEffect(() => {

        const handleStoreChange = () => {
            setIsInfoTabVisible(localStorage.getItem(ISINFORMATIONSELECT));
            console.log("EVENT LISTENER WORKED")
        };

        window.addEventListener("storage", handleStoreChange);

        return () => {
            window.removeEventListener("storage", handleStoreChange);
        };
    })

    const handleTabChange = (key) => {
        setActiveKey(key)
        console.log("KEY", key)
    }

    return (

        <Tabs activeKey={activeKey} onChange={handleTabChange} destroyInactiveTabPane={true}>
            <TabPane tab={t("firmSettings")} key={1}>
                <FirmSettings changeTab={handleTabChange} />
            </TabPane>
            {isInfoTabVisible === "true" ? (
                <TabPane tab={t("infoMessages")} key={2}>
                    <InformationSettings changeTab={handleTabChange} />
                </TabPane>
            ) : (
                <></>
            )}
            <TabPane tab={t("chatMedia")} key={3}>
                <MediaSettings />
            </TabPane>
        </Tabs>

    )
}


export default Settings