import React, { useEffect, useRef, useState } from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu, notification, Spin, Tag } from 'antd'; 
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import { useHistory, useNavigate } from "react-router-dom";
import tools from 'tools' 
import { connect } from 'react-redux'; 
import globalConstant from 'constants/globalConstant';
import helperFunc from 'helpers/helperFunc';
import { LoadingOutlined } from '@ant-design/icons';
import { AUTH_TOKEN } from 'constants/StorageConstant';
import network from 'network/network';
import { Helmet } from 'react-helmet';
import FirmSellerInsertUpdate from 'components/WaterOrder/FirmSellerInsertUpdate';

const { Option } = Select



const SellerList = (props) => {
    const firmSellerInsertUpdateModalRef = useRef();
	const[firmSellerList, setFirmSellerList] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]) 
	const [loadingPanel, setLoadingPanel] = useState(false)
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {


		selectFirmSellerList();
	}, []);

 
	const selectFirmSellerList = async() => {

		var params = { 

		}
		var result = await network.get("/admin/FirmSellers/selectFirmSellerList", params, false);

        const {data} = result;

        if (result.isSuccess && data.isSuccess) {
            setFirmSellerList(data.data);        
        }
	
	}


	const addProduct = () => {

		var params ={
			id : 0,
			name : "",
			code : "",
			tahsiledinCode : "",
			status : true
		}
        firmSellerInsertUpdateModalRef.current.openFirmSellerModal(params);
	}

	const viewDetails = row => {

		var params ={
			id : row.firmsellerid,
			name : row.sellername,
			code : row.sellercode,
			tahsiledinCode : row.tahsiledinseller,
			status : row.status
		}
        firmSellerInsertUpdateModalRef.current.openFirmSellerModal(params);
 
	}



	const changeRowStatusConfirm = async (row,status) => {
		
		setLoadingPanel(true);
		var params = {
			firmSellerId: row.firmsellerid, 
			status : status
		}

		var result = await network.get("/admin/FirmSellers/changeSellerStatus", params, false);

		setTimeout(() => {
			setLoadingPanel(false);
		}, 250);

		if (result.isSuccess) {

			var data = result.data;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Bayi silme işlemi başarılı bir şekilde gerçekleştirildi",
					placement: "topRight",
				});
				selectFirmSellerList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage,
				placement: "topRight",
			});
		}
	}
	const changeRowStatus = (row, status) => {

		helperFunc.showConfirm({
			content: `Bayi pasife alınacak. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => changeRowStatusConfirm(row, status)
		});
	} 

	const onSearch = e => {
		const value = e.currentTarget.value

		setSearchValue(value);
	}

	const getItems = elm=>{
		return [
				{
				  label:"Düzenle",
				  key: '0',
				  icon: <EditOutlined />,
				  onClick: ()=> viewDetails(elm)
				},
				{
				  label: elm.status == false ? "Aktife Al": 'Pasife Al',
				  key: '1',
				  icon: <DeleteOutlined />,
				  onClick: ()=> changeRowStatus(elm, (elm.status == false ? true: false))
				}
			  ];
		
		
	}  

 
	const tableColumns = [
		{
			title: 'id',
			dataIndex: 'firmsellerid'
		}, 
		{
			title: 'Bayi Adı',
			dataIndex: 'sellername',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'sellername')
		}, 
		{
			title: 'Bayi Kodu',
			dataIndex: 'sellercode',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'sellercode')
		}, 
		{
			title: 'Tahsiledin Bayi',
			dataIndex: 'tahsiledinseller',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'tahsiledinseller')
		}, 
		{
			title: 'Durumu',
			dataIndex: 'status',
			render: (_, record) => (
				<Tag color={record.status == false ? "red": "green"}>{record.status == false ? "Pasif" : "Aktif"}</Tag>
			)
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right">
					<EllipsisDropdown menu={{items:getItems(elm)}} />
				</div>
			)
		},
	
	
	];


	var data = JSON.parse(JSON.stringify(firmSellerList));
	if (searchValue.length>0) {
		data = data.filter(function (el) {
			return (el.sellername.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 )
		  });
	}

 

	return (
		<Spin spinning={loadingPanel} indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} >
			<Helmet title='Bayi Listesi' />
			<Card>
				<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
					<Flex className="mb-1" mobileFlex={false}>
						<div className="mr-md-3 mb-3">
							<Input placeholder="Bayilerde Ara" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
						</div>
					 
					</Flex>
					<div>
						<Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>Bayi Ekle</Button>
					</div>
				</Flex>
				<div className="table-responsive">
					<Table
						columns={tableColumns}
						loading={loadingPanel}
						dataSource={data}
						rowKey='id'
					/>
				</div>

                <FirmSellerInsertUpdate ref={firmSellerInsertUpdateModalRef} selectFirmSellerList={selectFirmSellerList}>

                </FirmSellerInsertUpdate>
			</Card>
		</Spin>
	)
}


function mapStateToProps(state) {

	return { 


	}
}

function mapDispatchToProps(dispatch) {
	return { 
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SellerList);
