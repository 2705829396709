import { Card, Dropdown, message, Modal, Select, Table, Tag } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import network from "network/network";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import 'quill-emoji/dist/quill-emoji.css';
import Emoji from 'quill-emoji';
import { CheckOutlined, CloseSquareOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ISSTAMTPRODUCTSELECT } from "constants/StorageConstant";

const InformationSettings = ({ changeTab }) => {
    const { t, i18n } = useTranslation();
    const [informationData, setInformationData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newInformation, setNewInformation] = useState('');
    const [isUpdateModalOpen, setIsModalUpdateModalOpen] = useState(false);
    const [openModalDetail, setOpenModalDetail] = useState({
        productId: 0,
        informationId: 0
    })
    const [information, setInformation] = useState('');
    const [isSelectOpenn, setIsSelectOpen] = useState(false);
    const [isProductSelect, setIsProductSelect] = useState(localStorage.getItem(ISSTAMTPRODUCTSELECT));
    const quillRef = useRef(null);

    Quill.register('modules/emoji', Emoji);



    useEffect(() => {
        selectInformationData();
        selectProductData();
        // setIsSelectOpen(localStorage.getItem(ISPRO))


        const handleStoreChange = () => {
            setIsProductSelect(localStorage.getItem(ISSTAMTPRODUCTSELECT));

        };

        window.addEventListener("storage", handleStoreChange);

        return () => {
            window.removeEventListener("storage", handleStoreChange);
        };

    }, [])

    useEffect(() => {
        // if (information && isUpdateModalOpen == false) {
        //     setTimeout(() => {
        //         setIsModalUpdateModalOpen(true)
        //     }, 0);

        // }
    }, [])
    const selectInformationData = async () => {

        var result = await network.get("/admin/CoffeeSettings/GetInformations", { isProductSelect }, false);

        if (result.isSuccess && result.data.isSuccess) {
            const { data } = result.data;
            setInformationData(data);
        }


    }

    const selectProductData = async () => {
        var result = await network.get("/admin/CoffeeProduct/getAllProducts", {}, false);

        if (result.isSuccess && result.data.isSuccess) {
            const { data } = result.data;
            setProductData(data);
        }

    }

    const handleUpdateTextChange = (value) => {

        console.log("INFORMATION", value)
        setTimeout(() => {
            setInformation(value);
        }, 0);
    }

    const handleTextChange = (value) => {
        console.log("New Info", value)
        setNewInformation(value);
    }
    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'emoji'],
            ],
            emoji: {
                inline: true
            }
        },

        'emoji-toolbar': true, // Emoji toolbar'ı etkinleştir
        'emoji-textarea': true, // Emoji textarea (isterseniz true yapabilirsiniz)
        'emoji-shortname': true, // :smile: gibi kısa adlarla emoji ekleme
    };

    const htmlToWhatsAppFormat = (html) => {
        // 1. Handle paragraph tags first
        html = html
            .replace(/<\/p>/g, "\n")  // Replace closing </p> with single newline
            .replace(/<p[^>]*>/g, ""); // Remove all opening <p> tags (with attributes)

        // 2. Handle line breaks
        html = html.replace(/<br\s*\/?>/g, "\n");  // Replace <br> with single newline

        // 3. Text formatting replacements
        html = html
            .replace(/<strong>(.*?)<\/strong>/g, "*$1*")
            .replace(/<em>(.*?)<\/em>/g, "_$1_")
            .replace(/<del>(.*?)<\/del>/g, "~$1~");

        // 4. Handle emojis and cursor
        html = html
            .replace(/<span class="ql-emojiblot"[^>]*>.*?<span[^>]*><span class="ap ap-[^"]+">([^<]+)<\/span><\/span>.*?<\/span>/g, "$1")
            .replace(/<span class="ql-cursor">.*?<\/span>/g, "");

        // 5. Clean up multiple consecutive newlines
        html = html.replace(/\n{3,}/g, "\n\n");  // Replace 3+ newlines with 2

        // 6. Trim and clean final output
        return html
            .replace(/^\n+/, "")   // Remove leading newlines
            .replace(/\n+$/, "")   // Remove trailing newlines
            .trim();
    };

    const WhatsAppTextParser = ({ text }) => {
        const regex = /(\*[^*]+\*|_[^_]+_)/g;

        return text.split("\n").map((line, lineIndex, array) => {
            const segments = line.split(regex);

            const parsedSegments = segments.map((segment, segmentIndex) => {
                if (segment.startsWith("*") && segment.endsWith("*")) {
                    return <strong key={segmentIndex}>{segment.slice(1, -1)}</strong>;
                } else if (segment.startsWith("_") && segment.endsWith("_")) {
                    return <em key={segmentIndex}>{segment.slice(1, -1)}</em>;
                } else {
                    return <React.Fragment key={segmentIndex}>{segment}</React.Fragment>;
                }
            });

            return (
                <React.Fragment key={lineIndex}>
                    {parsedSegments}
                    {lineIndex < array.length - 1 && <br />} {/* Son satırdan sonra <br /> koyma */}
                </React.Fragment>
            );
        });
    };

    const WhatsAppTextParserForQuill = ({ text }) => {
        if (!text) return "";

        var newText = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>") // Kalın (bold)
            .replace(/_([^_]+)_/g, "<em>$1</em>") // İtalik
            .replace(/\n/g, "<br />");

        return newText; // Satır atlama 
    };



    const handleInformationOk = async () => {
        var whatsappString = htmlToWhatsAppFormat(newInformation);
        var result = await network.post("/admin/CoffeeSettings/AddInformation", { text: whatsappString, productId: -1 }, false);

        if (result.isSuccess && result.data.isSuccess) {
            message.success(t("informationSaved"));
            setNewInformation("");
            setIsModalOpen(false);
        } else {
            message.error(t("informationNotSaved"));
        }
    }

    const handleUpdateOpen = async (record) => {

        var info = WhatsAppTextParserForQuill({ text: record.text });
        setInformation(info);
        setOpenModalDetail({ informationId: record.informationid, productId: record.productid })


        setIsModalUpdateModalOpen(true);


    }


    const handleUpdateInformationOk = async (value) => {
        var string = htmlToWhatsAppFormat(information);
        var result = await network.post("/admin/CoffeeSettings/UpdateInformation", { productId: openModalDetail.productId, informationId: openModalDetail.informationId, text: string }, false);

        if (result.isSuccess && result.data.isSuccess) {
            message.success(t("informationSaved"));
            setIsModalUpdateModalOpen(false);
            selectInformationData();

        } else {
            message.error(t("informationNotSaved"));
        }
    }

    const handleSelectChange = async (value, informationId) => {
        var result = await network.post("/admin/CoffeeSettings/UpdateInformation", { productId: value, informationId: informationId }, false);

        if (result.isSuccess && result.data.isSuccess) {
            message.success(t("informationSaved"));
            setIsModalOpen(false);
        } else {
            message.error(t("informationNotSaved"));
        }
    }

    const handleStatusChange = async (value, informationId) => {
        var result = await network.post("/admin/CoffeeSettings/UpdateInformation", { status: !value, informationId: informationId }, false);

        if (result.isSuccess && result.data.isSuccess) {
            message.success(t("informationSaved"));

            selectInformationData();
            selectProductData();

        } else {
            message.error(t("informationNotSaved"));
        }
    }

    const getItems = (record) => [
        {
            label: (<span >{t('edit')}</span>),
            key: '1',
            icon: <EditOutlined />,
            onClick: () => handleUpdateOpen(record)
        },
        {
            label: (<span >{!record.status ? t('active') : t('inactive')}</span>),
            key: '2',
            icon: !record.status ? <CheckOutlined /> : <CloseSquareOutlined />,
            onClick: () => handleStatusChange(record.status, record.informationid)
        }
    ]
    const formats = ['font', 'header', 'bold', 'italic', 'underline', 'emoji']
    const tableColumns = [

        {
            title: t("content"),
            dataIndex: "içerik",
            render: (_, record) => (
                <div className="markdown-container" style={{
                    backgroundColor: "#DCF8C6",
                    borderRadius: "12px",
                    padding: "10px 15px",
                    color: "#111111",
                    fontWeight: 400,
                    maxWidth: "75%",
                    wordWrap: "break-word",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                    display: "inline-block",
                    margin: "5px 0",
                    alignSelf: "flex-end",
                }}>
                    <WhatsAppTextParser text={record.text} />
                </div>
            )
        },

        {
            title: isProductSelect == "false" ? "" : t("productName"),
            dataIndex: "productid",
            key: "productid",
            render: (_, record) => {
                if (isProductSelect === "false") return null;
                return (
                    <div style={{ width: "200px" }} key={record.productid}>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            placeholder
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                { value: -1, label: t("otherProducts") },
                                ...productData.map((i) => ({
                                    value: i.productid,
                                    label: i.productname
                                }))
                            ]}
                            defaultValue={record.productid === -1 ? t("otherProducts") : record.productid}
                            onChange={(value) => handleSelectChange(value, record.informationid)}
                        />
                    </div>
                )

            }

        },
        {
            title: t("status"),
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                <div style={{ width: "200px" }} >
                    <Tag color={record.status === true ? "green" : "red"}>
                        {record.status === true ? t("active") : t("inactive")}
                    </Tag>
                    <Dropdown menu={{ items: getItems(record) }} trigger={["click"]}>
                        <div className="ellipsis-dropdown" style={{ cursor: 'pointer' }}>
                            <EllipsisOutlined />
                        </div>
                    </Dropdown>
                </div>
            )
        }



    ]
    return (
        <div>
            <div className="mb-3" style={{ width: "100%", justifyContent: "end", display: "flex" }}>
                <div style={{ width: "300px" }}>

                    <ReusableButton onClick={() => setIsModalOpen(true)} variant={"template"} text={t('addnewInfo')} />
                </div>
            </div>
            <Card>

                <Table
                    columns={tableColumns}
                    dataSource={informationData}
                />
            </Card>

            <Modal
                open={isModalOpen}
                closeIcon={true}
                closable={false}
                width={"fit-content"}
                style={{ height: "fit-content" }}
                onCancel={() => setIsModalOpen(false)}
                onOk={handleInformationOk}
                afterClose={() => {
                    setNewInformation(undefined);
                }}
            >
                <div className="mt-2">

                    <ReactQuill
                        modules={modules}
                        style={{ width: "900px", height: "350px" }}
                        formats={formats}
                        theme="snow"
                        onChange={handleTextChange}
                        placeholder="Type something with emojis or bold text..."

                    />
                </div>
            </Modal>

            <Modal
                open={isUpdateModalOpen}
                closeIcon={true}
                closable={false}
                width={"fit-content"}
                style={{ height: "fit-content" }}
                onCancel={() => {
                    setIsModalUpdateModalOpen(false);
                    setInformation(undefined);
                }}
                onOk={handleUpdateInformationOk}
                afterClose={() => {
                    setInformation(undefined);
                }}
            >
                <div className="mt-2">

                    <ReactQuill
                        modules={modules}
                        style={{ width: "900px", height: "350px" }}
                        formats={formats}
                        theme="snow"
                        value={information}
                        onChange={handleUpdateTextChange}
                        placeholder="Type something with emojis or bold text..."

                    />
                </div>
            </Modal>
        </div>
    )
}

export default InformationSettings