import actionTypes from "utils/redux/actions/actionTypes";




const initialState = {
    campaignCustomerList: [],
    isLoading: true,
    hasError: false,
    errorMessage: "",
};
export function campaignCustomerReducer(state = initialState, action) {

    switch (action.type) {
        case actionTypes.CAMPAIGN_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                campaignCustomerList: action.payload
            };

        case actionTypes.CAMPAIGN_CUSTOMER_LIST_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };

        case actionTypes.CAMPAIGN_CUSTOMER_LIST_ERROR:

            return {
                ...state,
                hasError: action.hasError,
                errorMessage: action.errorMessage
            };
    
        default:
            return state;
    }
}