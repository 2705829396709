import actionTypes from "../actions/actionTypes";


const initialState = { 
    isActive: false
};

export function listenerEnabledReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LISTENER_ACTIVATE:
            return {
                ...state,
                isActive: true
            } 
        default:
            return state;
    }
}