import React, { useEffect, useState } from 'react'

import { List, Avatar, Card } from 'antd';
import {   MailOutlined } from '@ant-design/icons';
 


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { readAllNotification, selectNotificationList } from 'utils/redux/actions/notiificationActions';
import { Helmet } from 'react-helmet';
  

const NotificationList = (props) => {


    useEffect(() => {

        props.selectNotificationListActions();

        readAllNotification();
    }, []);

    const readAllNotification =()=>{

        if (props.unreadNotificationCount>0) {
            props.readAllNotificationActions();
            
        }
    }
/*
destinationuserid: 1
fromuserid: 1
header: "Yeni Sipariş"
image: ""
isread: false
notificationid: 63
senddateformat: "01.20.2022 11:20"
text: "Yeni sipariş gelmiştir. "
*/


    return (
        <Card title="Bildirimler" >
            	<Helmet title='Bildirim Listesi' />
        <List
            itemLayout="vertical"
            size="default"
            pagination={{
                onChange: page => {
                 
                },
                pageSize: 10,
            }}
            dataSource={props.notificationList}
             
            renderItem={item => (
                <List.Item
                    key={item.destinationuserid + ""} 
                   
                >
                    <List.Item.Meta
                        avatar={
                        // <Avatar src={item.avatar} />
                        <Avatar className={`ant-avatar-info`} icon={ <MailOutlined />} />
                    }
                        title={item.header}
                        description={item.senddateformat}
                    />
                    {item.text}
                </List.Item>
            )}
        />
        </Card>
    );

}

function mapStateToProps(state) {

    return {
        isLoading: state.notificationReducer.isLoading,
        notificationList: state.notificationReducer.notificationList,
        hasError: state.notificationReducer.hasError,
        errorMessage: state.notificationReducer.errorMessage,
        unreadNotificationCount: state.notificationReducer.unreadNotificationCount,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectNotificationListActions: bindActionCreators(selectNotificationList, dispatch),
        readAllNotificationActions: bindActionCreators(readAllNotification, dispatch),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
