import React, { Component, useEffect, useLayoutEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Spin, } from 'antd';
import { Progress, Tooltip, Tag } from 'antd';
import { Badge, Descriptions, Table } from 'antd';
import './phone.css'
import { ClockCircleOutlined, SearchOutlined, TeamOutlined, RollbackOutlined, ArrowRightOutlined, UserDeleteOutlined, LoadingOutlined, ExportOutlined } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';
import network from 'network/network';
import Moment from 'react-moment';
import { DATETIME_FORMAT } from 'constants/DateConstant';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { selectCampaignCustomers } from 'utils/redux/actions/campaignActions';
import { connect } from 'react-redux';
import ReusableButton from 'components/Elements/ReusableButton';
import * as XLSX from 'xlsx';

const CampaignsDetail = (props) => {

    const { t, i18n } = useTranslation();
    const { campaignDetail } = props;
    const location = useLocation();
    const { id } = useParams();
    const [filtredCustomer, setFiltredCustomer] = useState();
    const [searchValue, setSearchValue] = useState("");
    const antIcon = <LoadingOutlined style={{ fontSize: 36, color: '#697780' }} spin />;

    const onSearch = e => {

        const value = e.currentTarget.value
        setSearchValue(value);
    }
    const [columns, setColumns] = useState([
        {

            dataIndex: 'campaignsName',
            key: 'campaignsName',
            width: '30px',
            colSpan: 1,
            align: 'center',
            render: (_, record) => (
                <div>
                    <span>
                        <img src='/img/avatars/user-blank-image.jpg' width={50}></img>
                    </span>

                </div>
            )
        },
        {
            title: t('customerName'),
            dataIndex: 'customerName',
            key: 'customerName',
            width: '350px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item  ">{record.customerName}</div>
                    </div>
                </div>
            )
        },
        {
            title: t('phoneNumber'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '150px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item  ">{record.phone}</div>
                    </div>
                </div>
            )
        },

        {
            title: t('status'),
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <Tooltip title={elm.statusType == "failed" ?
                        `
                    ${t("errorCode")} : ${elm.errorCode} \n
                    ${t("errorMessage")} : ${elm.errorDesc}
                    `
                        : ""} >
                        <div className='my-4 '>
                            {elm.statusType == "read" ? (
                                <><Tag color={"orange"}>{t('read')}</Tag></>
                            ) : elm.statusType == "sent" ? (
                                <><Tag color={"green"}>{t('sent')}</Tag></>
                            ) : elm.statusType == "delivered" ? (
                                <><Tag color={"yellow"}>{t('delivered')}</Tag> </>
                            ) : elm.statusType == "failed" ? (
                                <Tag color={"red"}>{t('failed')}</Tag>
                            ) : (
                                <><Tag color={"red"}>{t('notDelivered')}</Tag> </>
                            )}
                        </div>
                    </Tooltip>

                )
            }

        },
        {
            title: t('replybuttondesc'),
            dataIndex: 'replybuttondesc',
            key: 'replybuttondesc',
            width: '150px',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, record) => (
                <div>
                    <div className="flex-container d-inline">
                        <div className="item  ">{record.replyButtonDesc}</div>
                    </div>
                </div>
            )
        },
        {
            title: t('statusDate'),
            dataIndex: 'id',
            width: 250,
            align: 'center',
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'campaignsName'),
            render: (_, elm) => {
                return (
                    <div className='my-4 '>
                        {moment(elm.createdDate).format('MM-DD-YYYY')}
                    </div>
                )
            }

        },
        {
            title: t('statusTime'),
            dataIndex: 'id',
            width: 250,
            align: 'center',
            render: (_, elm) => {
                return (
                    <div className='my-4 '>
                        {moment(elm.createdDate).format('HH:mm:ss')}
                    </div>
                )
            }

        },

    ])
    useLayoutEffect(() => {
        getCampaignsDetails();
    }, [])
    const getCampaignsDetails = async () => {
        var params = {
            campaignId: id
        }
        props.selectCampaignCustomersAction(params);
    }
    var data = campaignDetail?.campaignCustomer?.length > 0 ? JSON.parse(JSON.stringify(campaignDetail?.campaignCustomer)) : null;

    if (searchValue.length > 0) {
        data = data?.filter(function (el) {
            return (el?.customerName?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                el?.phone?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
        });
    }

    const getDeliveredPercent = () => {
        const percent = campaignDetail?.campaignStatistics?.receiverCount == 0 ? 0 :
            Math.round(((campaignDetail?.campaignStatistics?.deliveredCount || 0) / (campaignDetail?.campaignStatistics?.receiverCount || 1)) * 100).toFixed(0) || 0;

        return percent;
    }

    const getStatusText = (elm) => {

        switch (elm.statusType) {
            case "read":
                return t('read');
            case "sent":
                return t('sent');
            case "delivered":
                return t('delivered');
            case "failed":
                return t('failed');
            
            default:
                return t('notDelivered'); 
        }


 
    }
    const handleExcelExport = () => {
        const excelData = campaignDetail?.campaignCustomer.map((record) => ({
            [t('customerName')]: record.customerName,
            [t('phoneNumber')]: record.phone,
            [t('status')]: getStatusText(record),
            [t('replybuttondesc')]: record.replyButtonDesc,
            [t('statusDate')]: moment(record.createdDate).format('MM-DD-YYYY'),
            [t('statusTime')]: moment(record.createdDate).format('HH:mm:ss'),
        }));

        // Create a new worksheet and add the data
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

        // Export to Excel
        XLSX.writeFile(workbook, "CustomerData.xlsx");
    }


    return (
        props.isLoading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Spin indicator={antIcon} tip={t("dataLoading")} size="large" />
            </div>)
            : (
                <div>
                    <Spin spinning={props.isLoading} indicator={antIcon} >
                        <Card title={t('campaignStatistics')}>
                            <Row justify="space-between" gutter={[16, 16]}>
                                <Col flex="1" key={1}>
                                    <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                        <Row className='mr-1'>
                                            <Col span={19}><h4 className='ml-1 ' >{t('recipient')}</h4>
                                            </Col>
                                            <Col span={4} >
                                                <TeamOutlined className='mt-1 ml-2 d-flex align-items-center justify-content-center' style={{ fontSize: '21px', color: "#52c41a" }} />
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <h1 className=' ml-2 mb-3'>{campaignDetail?.campaignStatistics?.receiverCount || 0} </h1>
                                        <Row className='ml-1 mr-2'>
                                            <Col span={18}>
                                                <Progress strokeColor={"#52c41a"} className="mt-1 mr-1 mb-1 " percent={campaignDetail?.campaignStatistics?.receiverCount * 100 || 0} showInfo={false} />
                                            </Col>
                                            <Col span={6}>
                                                <h4 className='mt-1 ml-2'>%100</h4>
                                            </Col>
                                        </Row>
                                        {/* <Progress strokeColor={"#52c41a"} className=" mb-1 " percent={99} />*/}
                                    </Card>
                                </Col>
                                <Col flex="1" key={2}>
                                    <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                        <Row className='mr-1'>
                                            <Col span={19}><h4 className='ml-1 ' >{t('sent')}</h4>
                                            </Col>
                                            <Col span={4} >

                                                <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 12 11" height="19" width="19" preserveAspectRatio="xMidYMid meet" className="" fill="none"><title>msg-check</title><path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor"></path></svg> </h2>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <h1 className=' ml-2 mb-3'>{campaignDetail?.campaignStatistics?.sendedCount || 0}</h1>
                                        <Row className='ml-1 mr-2'>
                                            <Col span={18}>
                                                <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={(campaignDetail?.campaignStatistics?.sendedCount / campaignDetail?.campaignStatistics?.receiverCount) * 100 || 0} showInfo={false} />
                                            </Col>
                                            <Col span={6}>
                                                <h4 className='mt-1 ml-2'>%{(campaignDetail?.campaignStatistics?.receiverCount || 0) == 0 ? 0 : Math.round((campaignDetail?.campaignStatistics?.sendedCount / (campaignDetail?.campaignStatistics?.receiverCount || 0)) * 100).toFixed(0) || 0}</h4>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col flex="1" key={3}>
                                    <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                        <Row className='mr-1'>
                                            <Col span={19}><h4 className='ml-1 ' >{t('delivered')}</h4>
                                            </Col>
                                            <Col span={4} >

                                                <h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>
                                            </Col>
                                        </Row >
                                        <br></br>
                                        <h1 className=' ml-2 mb-3'>{campaignDetail?.campaignStatistics?.deliveredCount || 0}</h1>
                                        <Row className='ml-1 mr-2' >
                                            <Col span={18}>
                                                <Progress strokeColor={"#697780"} className="mt-1 mr-1 mb-1 " percent={((campaignDetail?.campaignStatistics?.deliveredCount || 0) / campaignDetail?.campaignStatistics?.receiverCount || 0) * 100 || 0} showInfo={false} />
                                            </Col>
                                            <Col span={6}>
                                                <h4 className='mt-1 ml-2'>%{getDeliveredPercent()}</h4>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col flex="1" key={4}>
                                    <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                        <Row className='mr-1'>
                                            <Col span={19}><h4 className='ml-1 ' >{t('read')} </h4>
                                            </Col>
                                            <Col span={4} >
                                                <h2 className='ml-3' style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>

                                            </Col>
                                        </Row >
                                        <br></br>
                                        <h1 className=' ml-2 mb-3'>{campaignDetail?.campaignStatistics?.readedCount || 0
                                        }</h1>
                                        <Row className='ml-1 mr-2'>
                                            <Col span={18}>
                                                <Progress strokeColor={"#53bdea"} className="mt-1 mr-1 mb-1 " percent={
                                                    (campaignDetail?.campaignStatistics?.readedCount || 0
                                                        / campaignDetail?.campaignStatistics?.receiverCount || 0) * 100 || 0
                                                } showInfo={false} />
                                            </Col>
                                            <Col span={6}>
                                                <h4 className='mt-1 ml-2'>%{campaignDetail?.campaignStatistics?.receiverCount == 0 ? 0 : Math.round(((campaignDetail?.campaignStatistics?.readedCount || 0)
                                                    / campaignDetail?.campaignStatistics?.receiverCount || 0) * 100).toFixed(0) || 0
                                                }</h4>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col flex="1" key={5}>
                                    <Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
                                        <Row className='mr-1'>
                                            <Col span={19}><h4 className='ml-1' >{t('notDelivered')} </h4>
                                            </Col>
                                            <Col span={4} >
                                                <h2 className='ml-3' style={{ color: "red", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 16 15" height="22" width="22" preserveAspectRatio="xMidYMid meet" className="" version="1.1" x="0px" y="0px" enable-background="new 0 0 16 15"><title>msg-time</title><path fill="currentColor" d="M9.75,7.713H8.244V5.359c0-0.276-0.224-0.5-0.5-0.5H7.65c-0.276,0-0.5,0.224-0.5,0.5v2.947 c0,0.276,0.224,0.5,0.5,0.5h0.094c0.001,0,0.002-0.001,0.003-0.001S7.749,8.807,7.75,8.807h2c0.276,0,0.5-0.224,0.5-0.5V8.213 C10.25,7.937,10.026,7.713,9.75,7.713z M9.75,2.45h-3.5c-1.82,0-3.3,1.48-3.3,3.3v3.5c0,1.82,1.48,3.3,3.3,3.3h3.5 c1.82,0,3.3-1.48,3.3-3.3v-3.5C13.05,3.93,11.57,2.45,9.75,2.45z M11.75,9.25c0,1.105-0.895,2-2,2h-3.5c-1.104,0-2-0.895-2-2v-3.5 c0-1.104,0.896-2,2-2h3.5c1.105,0,2,0.896,2,2V9.25z"></path></svg></h2>

                                            </Col>
                                        </Row>
                                        <br></br>
                                        <h1 className=' ml-2 mb-3'>{campaignDetail?.campaignStatistics?.notDeliveredCount || 0}</h1>
                                        <Row className='ml-1 mr-2'>
                                            <Col span={19}>
                                                <Progress strokeColor={"red"} className="mt-1 mr-1 mb-1 " percent={((campaignDetail?.campaignStatistics?.notDeliveredCount || 0) / (campaignDetail?.campaignStatistics?.receiverCount || 0)) * 100 || 0} showInfo={false} />
                                            </Col>
                                            <Col span={5}>
                                                <h4 className='mt-1 ml-2'>%{campaignDetail?.campaignStatistics?.receiverCount == 0 ? 0 : Math.round(((campaignDetail?.campaignStatistics?.notDeliveredCount || 0) / (campaignDetail?.campaignStatistics?.receiverCount || 1)) * 100).toFixed(0) || 0}</h4>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>

                            </Row>
                        </Card>
                        <Card title={t('campaignDetails')} >
                            <div>
                                <Row >
                                    <Col className='mt-2' span={12}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label={t('campaignName')} span={3}>{campaignDetail?.campaignDetailInfo?.campaignName}</Descriptions.Item>
                                            <Descriptions.Item label={t('senderNumber')} span={3}>{campaignDetail?.campaignDetailInfo?.sourceNumber}</Descriptions.Item>

                                            <Descriptions.Item label={t('createdDate')} span={3}>
                                                {moment(campaignDetail?.campaignDetailInfo?.createdDate).format('MM-DD-YYYY')} {moment(campaignDetail?.campaignDetailInfo?.createdDate).format('HH:mm:ss')}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={t('sentGroups')} span={3}>

                                                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                                    {campaignDetail.campaignDetailInfo.sendedGroups?.map((item, index) => (
                                                        <Tag key={index + "groups"} className="m-1" color={"blue"}>
                                                            {item}
                                                        </Tag>
                                                    ))}
                                                </div>
                                            </Descriptions.Item>

                                        </Descriptions>
                                    </Col>
                                    <Col span={2}>
                                    </Col>

                                    <Col style={{ background: "#e5ddd5", borderRadius: 5 }} className='mt-1  ml-3' span={8}>
                                        <Row>
                                            <Col span={5}>
                                            </Col>
                                            <Col span={19} className='my-3'>

                                                <div className=" mt-3 mr-2 p-1 " style={{ background: "#d3eebe", borderRadius: 10 }}>

                                                    <div className='p-1 ' > {campaignDetail?.campaignDetailInfo?.template?.data}
                                                    </div>

                                                </div>
                                                <div className='my-1 '></div>

                                            </Col>

                                        </Row>

                                    </Col>
                                    <Col span={1}>
                                    </Col>
                                </Row>



                            </div>
                        </Card>
                        <Card title={t("recipientInfo")}>
                            <div className="mb-1 row-justify-between" mobileFlex={false} justifyContent='space-between'>
                                <div className="mr-md-3 mb-3">
                                    <Input placeholder={t('searchNameNumber')} prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                                </div>
                                <div>
                                <ReusableButton
									variant="campaign"
									text={t('Export')}
									onClick={handleExcelExport}
									icon={<ExportOutlined />}
									className={"mr-2"}

								/>
                                </div>
                               
                            </div>
                            <Col span={24}><Table
                                columns={columns}
                                dataSource={data}
                                rowKey='id'
                            /></Col>
                        </Card>

                    </Spin>
                </div>
            )
    );

}
function mapStateToProps(state) {

    return {
        isLoading: state.campaignCustomerReducer.isLoading,
        campaignDetail: state.campaignCustomerReducer.campaignCustomerList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCampaignCustomersAction: bindActionCreators(selectCampaignCustomers, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsDetail);