

import { Button, Card, Col, Dropdown, Input, message, Row, Spin, Table, Tag } from "antd";
import network from "network/network";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, FileExcelOutlined, PlusCircleOutlined, QrcodeOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tools from 'tools';
import * as XLSX from 'xlsx';
import AvatarStatus from "components/shared-components/AvatarStatus";
import ReusableButton from "components/Elements/ReusableButton";
import helperFunc from "helpers/helperFunc";
import { useTranslation } from "react-i18next";
import globalConstant from "constants/globalConstant";
import QrCodeModal from "components/Elements/QrCodeModal";

const EventStandList = (props) => {



	const qrCodeModalRef = useRef();

	const [pageData, setPageData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchValue, setSearchValue] = useState("");
	const navigate = useNavigate();
	const { t, } = useTranslation();


	useEffect(() => {
		selectEventStandList();

	}, []);

	const selectEventStandList = async () => {
		var params = {};

		var result = await network.get("/admin/event/events-stands", params, false);


		if (result.isSuccess) {


			setPageData(result.data);

		}
		setLoading(false);

	}
	const changeEventStandStatusPrev = (row) => {
		const newStatus = row.status == false ? true : false

		const message = newStatus !== false ? t("makeEventStandPassiveConfirm") : t("makeEventStandActiveConfirm")

		helperFunc.showConfirm({
			content: message,
			title: t("warning"), onConfirm: () => changeEventStandStatusConfirm(row, newStatus)
		});
	}
	const changeEventStandStatusConfirm = async (row, newStatus) => {

		setLoading(true);

		var params = {
			eventStandId: row.id,
			status: newStatus
		}

		var result = await network.get("/admin/event/update-event-stand-status", params, false);

		setLoading(false);
		if (result.isSuccess) {


			message.success(t("changeEventStandStatusSuccess"))


			var index = pageData.findIndex(w => w.id == row.id);
			var data = JSON.parse(JSON.stringify(pageData));
			if (index != -1) {

				data[index].status = newStatus;
			}

			setPageData(data);

		} else {
			message.error(t("changeEventStandStatusError"))
		}

	}

	const onSearch = (e) => {
		const value = e.currentTarget.value;
		setSearchValue(value);
	}
	const getItems2 = (record) => [
		{
			key: 'detail',
			label: (
				<div onClick={() => navigate(`/stand-edit/${record.id}`)}>
					<EditOutlined />
					<span className="ml-2">{t('edit')}</span>
				</div>
			)
		},
		{
			key: 'delete',
			label: (
				<div onClick={() => changeEventStandStatusPrev(record)}>
					<DeleteOutlined />
					<span className="ml-2">{record.status !== false ? t('makePassive') : t('makeActive')}</span>
				</div>
			)
		}
	];

	const tableColumns = [
		{
			title: t('standCode'),
			dataIndex: "standCode",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'standCode'),
			shouldCellUpdate: (record, prevRecord) => record.standCode !== prevRecord.customstandCodeername,
		},
		{
			title: t('standDesc'),
			dataIndex: "standDesc",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'standDesc'),
			shouldCellUpdate: (record, prevRecord) => record.standDesc !== prevRecord.standDesc,

		},
		{
			title: t('EventName'),
			dataIndex: "eventName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'eventDesc'),
			shouldCellUpdate: (record, prevRecord) => record.eventDesc !== prevRecord.eventDesc,
		},
		{
			title: t("attendeeFirmName"),
			dataIndex: "attendeeFirmName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'attendeeFirmName'),
			shouldCellUpdate: (record, prevRecord) => record.attendeeFirmName !== prevRecord.attendeeFirmName,
		},
		{
			title: t("attendeeContactName"),
			dataIndex: "attendeeContactName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'attendeeContactName'),
			shouldCellUpdate: (record, prevRecord) => record.attendeeContactName !== prevRecord.attendeeContactName,
		},
		{
			title: t("status"),
			dataIndex: "status",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'status'),
			render: (_, record) => (
				record.status !== false ? (

					<div className='my-4 '>
						<><Tag color={"green"}>{t('active')}</Tag></>
					</div>


				) : (

					<div className='my-4 '>
						<><Tag color={"red"}>{t('inactive')}</Tag></>
					</div>

				)
			),
			shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email,
		},
		{
			title: t('qrCodeCol'),
			dataIndex: "",
			render: (_, record) => (
				<div className="row-align-center">
					<Button variant="text" style={{
						boxShadow: "none",
						border: 0
					}} onClick={() => onOpenQrCode(record)} icon={<QrcodeOutlined style={{ fontSize: "25px" }} />}>

					</Button>
				</div>

			)
		},
		{
			title: t('eventDetail'),
			dataIndex: "",
			render: (_, record) => (
				<div className="row-align-center">
					<Dropdown menu={{ items: getItems2(record) }} trigger={['click']}>
						<div className="ellipsis-dropdown" style={{ cursor: 'pointer' }}>
							<EllipsisOutlined />
						</div>
					</Dropdown> 
				</div>

			)
		}
	]

	const getStatusText = (record) => {

		return record.status !== false ? t('active') : t('inactive');
	}

	const onOpenQrCode = (row) => {
		const link = `https://wa.me/${row.firmNumber}?text=${row.eventMessageKey}+${row.standCode}`
		const desc = `${row.eventDesc}`
		qrCodeModalRef.current.openModal(link, desc);
	}

	const handleExcelExport = () => {
		const excelData = filteredData.map((record) => ({
			[t('standCode')]: record.standCode,
			[t('standDesc')]: record.standDesc,
			[t('EventDescription')]: record.eventDesc,
			[t('attendeeFirmName')]: record.attendeeFirmName,
			[t('attendeeContactName')]: record.attendeeContactName,
			[t('status')]: getStatusText(record),
		}));

		// Create a new worksheet and add the data
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "EventStands");

		// Export to Excel
		XLSX.writeFile(workbook, "EventStandData.xlsx");
	}

	const getFilteredData = () => {

		if (searchValue.length > 0) {
			const filtered = pageData.filter((item) => {
				return (
					item.eventDesc.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.standCode.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.attendeeFirmName.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.attendeeContactName.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.standDesc?.toUpperCase().includes(searchValue.toUpperCase())
				);
			});
			return filtered;
		} else {
			return pageData
		}
	}
	const filteredData = getFilteredData();

	const addEventStand = () => {

		navigate(`/stand-edit/0`)
	}
	return (
		<Spin size="large" spinning={loading}>
			<div>
				<Row className="mb-3" justify={"space-between"}>

					<Col span={4}>
						<Input
							placeholder={t('searchEventStand')}
							prefix={<SearchOutlined />}
							onChange={onSearch}
						/>
					</Col>
					<div className="row-justify-between ">
						<ReusableButton
							variant="campaign"
							text={t('Export')}
							onClick={handleExcelExport}
							icon={<ExportOutlined />}
							className={"mr-2"}

						/>
						<ReusableButton
							variant="campaign"
							text={t('addEventStand')}
							onClick={addEventStand}
							icon={<PlusCircleOutlined />}
						/>
					</div>

				</Row>

				<Card>
					<div className="table-responsive">
						<Table
							columns={tableColumns}
							dataSource={filteredData}
							size="middle"
							rowKey={"id"}
							key={JSON.stringify(pageData)}
						/>
					</div>
				</Card>
			</div>
			<QrCodeModal ref={qrCodeModalRef} />
		</Spin>




	);
}

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventStandList);
