import React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const TemplateInput = ({
  title,
  description,
  placeholder,
  value,
  onChange,
  infoMessage,
  subDesc,
  isRequired,
  isVariables,
  bodyCounterRef,
  addBodyVariable,
  disabled,
  hideRequired
}) => {
  const{t,i18n}=useTranslation();
  return (
    <div style={{
      marginBottom: '20px',
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      border: '1px solid #d9d9d9',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      height: '90%'
    }}>
      {/* Header with Info Icon */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px'
      }}>
        <div style={{
          display: 'flex',
        }}>
          <h3 style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#333',
            fontFamily: 'Helvetica, sans-serif',
            marginRight: '10px',
          }}>
            {title}
          </h3>

          {/* Required/Optional Badge */}
          {hideRequired ? null: isRequired ? (
            <div style={{
              padding: '2px 6px',
              backgroundColor: '#E6F7FF',
              borderRadius: '6px',
              fontSize: '14px',
              color: '#1890ff',
              fontWeight: '500',
              marginRight: '10px',
              height: '25px',
              display: 'flex',
              alignItems: 'center'
            }}>
              {t('required')}
            </div>
          ) : (
            <div style={{
              padding: '2px 6px',
              backgroundColor: '#FFF6E6',
              borderRadius: '6px',
              fontSize: '14px',
              color: '#000010',
              fontWeight: '500',
              marginRight: '10px',
              height: '25px',
              display: 'flex',
              alignItems: 'center'
            }}>
              {t('optional')}
            </div>
          )}
        </div>
        {
          infoMessage && infoMessage.length > 0 && (
            <Tooltip title={infoMessage}>
              <InfoCircleOutlined style={{ color: '#1890ff', fontSize: '18px' }} />
            </Tooltip>
          )
        }

      </div>

      {/* Açıklama */}
      <p style={{
        fontSize: '14px',
        color: '#595959',
        marginBottom: '16px',
        fontFamily: 'Verdana, sans-serif'
      }}>
        {description}
      </p>

      {/* Input alanı */}
      <div style={{ marginBottom: '12px' }}>
        <Input
          name="elementName"
          placeholder={placeholder}
          disabled={disabled}
          style={{
            width: '100%',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #d9d9d9',
            boxSizing: 'border-box',
            fontFamily: 'Arial, sans-serif',
            fontSize: '14px',
            color: '#333',
            transition: 'border-color 0.3s ease',
          }}
          value={value}
          onChange={onChange}
        />
      </div>

      {/* Info Bilgisi */}
      <div style={{ color: '#595959', fontSize: '12px', fontFamily: 'Verdana, sans-serif' }}>
        {subDesc}
      </div>

      {
        isVariables &&
        (
          <div className="controls mt-2">
            <Button
              className="controls-right"
              type="button"
              onClick={() => {
                bodyCounterRef.current = bodyCounterRef.current + 1;
              }}
            >
              Değişken Ekle
            </Button>
          </div>
        )
      }
    </div>

  );
};

export default TemplateInput;
