import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, InputNumber, Select, DatePicker, locales, Spin, notification, Alert } from 'antd';
import Flex from 'components/shared-components/Flex'


import { PlusCircleOutlined } from '@ant-design/icons';
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectProductList } from 'utils/redux/actions/productAction';
import Moment from 'react-moment';
import locale from 'antd/es/date-picker/locale/tr_TR'
import { selectCustomerList } from 'utils/redux/actions/customerActions';
import NumberFormat from 'react-number-format';
import OrderItemList from 'components/order/OrderItemList';
import { selectOrderDetail, updateOrderMaster } from 'utils/redux/actions/orderAction';
import { DATETIME_FORMAT } from 'constants/DateConstant';
import InsertOrderItemModal from 'components/order/InsertOrderItemModal';



const { Dragger } = Upload;
const { Option } = Select;


const { TabPane } = Tabs;

const paymentTypes = [
    {
        id: globalConstant.paymentType.cashOnDelivery,
        label: "Kapıda Ödeme"
    },
    {
        id: globalConstant.paymentType.onlinePayment,
        label: "Online Ödeme"
    }
]

const EditOrder = (props) => {

    const { customerId, orderDate, orderId, paymentType, } = props.orderMasterInfo;
    const { orderItemList } = props;

    const { isOrderEditable } = props;

    let navigate = useNavigate();


    const orderItemModalRef = useRef();


    const [submitLoading, setSubmitLoading] = useState(false);

    const { id } = useParams();
    /*
    
    customerId: 43
orderDate: "17.02.2022 12:20"
orderId: 112
paymentType: 1
totalAmount: 90
    */

    const [orderMasterInformation, setOrderMasterInformation] = useState({
        customerId: "",
        orderDate: "",
        orderId: id,
        paymentType: -1,
        totalAmount: id
    });


    useEffect(() => {

        props.selectCustomerListAction();
        selectProducts();

        if (id > 0) {
            selectProductDetail();
        }


    }, [id]);

    const selectProducts = async () => {

        props.selectProductListAction();

    }

    const selectProductDetail = async () => {

        props.selectOrderDetailAction(id);
    }
    /*
    
      public int OrderId { get; set; }
        public int CustomerId { get; set; }
        public string OrderDate { get; set; } 
        public int PaymentType { get; set; }
        public int OrderStatus { get; set; }
        public List<OrderItemRequest> ItemList { get; set; }

              public int ItemId { get; set; }
        public int OrderId { get; set; }
        public int ProductId { get; set; }
        public string Quantity { get; set; }
    
    */

    //customerId, orderDate, orderId, paymentType, totalAmount
    const onFinish = async () => {


        if (!(orderDate) || !(customerId > 0) || !(paymentType > 0) || orderItemList.length == 0) {

            message.error('Lütfen zorunlu alanları doldurunuz');
            return;
        }

        setSubmitLoading(true)

        var items = [];
        for (let i = 0; i < orderItemList.length; i++) {
            const element = orderItemList[i];

            items.push({
                OrderId: parseInt(id),
                ProductId: parseInt(element.productId),
                Quantity: element.quantity.toString(),
                ItemId: -1
            });
        }
        var params = {
            OrderId: parseInt(id),
            CustomerId: parseInt(customerId),
            OrderDate: orderDate,
            PaymentType: parseInt(paymentType),
            OrderStatus: 1,
            ItemList: items
        }
        var method = "insertOrder";
        if (params.OrderId > 0) {
            method = "updateOrder";
        }
 
        var result = await network.post(`/admin/orders/${method}`, params);

        setSubmitLoading(false)
        if (result.isSuccess) {
            var postResult = result.data;
            if (postResult.isSuccess) {


                notification.success({
                    message: 'Başarılı!',
                    description: "Sipariş bilgileri başarılı bir şekilde kaydedilmiştir.",
                });
                setTimeout(() => {

                    navigate(`/app/apps/ecommerce/orders`)
                }, 500);

            } else {
                notification.error({
                    message: 'Hata!',
                    description: postResult.errorMessage,
                });
            }

        } else {
            notification.error({
                message: 'Hata!',
                description: result.errorMessage,
            });
        }


    };

    const addOrderItem = () => {

        orderItemModalRef.current.openItemModal(-1);
    }


    if (props.isLoading) {

        return (
            <div style={{ textAlign: "center", marginTop: 50 }}>
                <Spin tip="Sipariş Bilgileri Yükleniyor...">

                </Spin>
            </div>
        );
    }


    const getTotalPricce = () => {
        var price = 0;
        for (let i = 0; i < orderItemList.length; i++) {
            const element = orderItemList[i];
            price += element.price * element.quantity;

        }


        return (
            <NumberFormat
                displayType={'text'}
                value={(Math.round(price * 100) / 100).toFixed(2)}
                prefix={'₺'}
                thousandSeparator={true}
            />
        );
    }

    const getPostButton = () => {

        if (isOrderEditable) {

            return (
                <div className="mb-3">
                    <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading} >
                        {orderId > 0 ? 'Güncelle' : `Ekle`}
                    </Button>
                </div>
            );
        }
        else {

            return (
                <div className="mb-3">
                    <Alert
                        message=""
                        description="Sipariş Teslim edildiği için değişiklik yapılamaz."
                        type="warning"
                    />
                </div>
            );
        }
    }
    const tabItems = [
        {
          key: "1",
          label: "Genel Bilgiler",
          children: (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={17}>
                <Card title="Ürün Bilgileri">
                  <div className='ant-col ant-form-item-label'>
                    <label className='ant-form-item-required' htmlFor="productName">Müşteri Adı</label>
                  </div>
                  <Select disabled={id > 0} className="w-100" placeholder="Müşteri Bayisi"
                          showSearch
                          onChange={(val) => {
                              props.updateOrderMasterAction("customerId", val);
                          }}
                          defaultValue={customerId > 0 ? customerId : "Müşteri Seçiniz"}
                  >
                    {props.customerList.map((item, index) => (
                      <Option key={item.CUSTOMERNAME} value={item.CUSTOMERID}>
                        {item.CUSTOMERNAME}
                      </Option>
                    ))}
                  </Select>
                  <div className='m-4'></div>
                  <div className='ant-col ant-form-item-label'>
                    <label className='ant-form-item-required' htmlFor="customerName">Sipariş Tarihi </label>
                  </div>
                  <div>
                    <DatePicker allowClear={false}
                                locale={locale} defaultValue={Moment(orderDate, DATETIME_FORMAT)}
                                showTime
                                format={DATETIME_FORMAT}
                                showSecond={false} placeholder="Sipariş Tarihi"
                                onChange={(value, dateString) => {
                                    props.updateOrderMasterAction("orderDate", dateString);
                                }}
                                style={{ width: "100%" }}
                    />
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={7}>
                <Card title="Ödeme Bilgileri">
                  <div className='ant-col ant-form-item-label'>
                    <label className='ant-form-item-required' htmlFor="productName">Ödeme Tipi</label>
                  </div>
                  <Select className="w-100" placeholder="Müşteri Bayisi"
                          onChange={(val) => {
                              props.updateOrderMasterAction("paymentType", val);
                          }}
                          defaultValue={paymentType > 0 ? paymentType : "Ödeme Tipi Seçiniz"}
                  >
                    {paymentTypes.map((item, index) => (
                      <Option key={item.label} value={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Card>
                <Card title="Alt Bilgiler">
                  <div className='ant-col ant-form-item-label'>
                    <label className='ant-form-item mr-2' htmlFor="productName">Sipariş Toplamı :</label>
                    {getTotalPricce()}
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Card title="Sipariş Kalemleri" extra={
                  <Button type="link" onClick={() => addOrderItem()} htmlType="button" icon={<PlusCircleOutlined />}>
                    Kalem Ekle
                  </Button>
                }>
                  <OrderItemList data={orderItemList} />
                </Card>
              </Col>
            </Row>
          )
        }
      ];
    return (
        <>
            <Helmet title={orderId > 0 ? 'Siparişi Güncelle' : `Sipariş Ekle`} />
            <fieldset disabled={isOrderEditable}>
                <Form
                    layout="vertical"
                    name="advanced_search"
                    className="ant-advanced-search-form"

                >
                    <PageHeaderAlt className="border-bottom" overlap>
                        <div className="container">
                            <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
                                <h2 className="mb-3">{orderId > 0 ? 'Siparişi Güncelle' : `Sipariş Ekle`} </h2>
                                {getPostButton()}
                            </Flex>
                        </div>
                    </PageHeaderAlt>
                    <div className="container">
                        <Tabs defaultActiveKey="1" style={{ marginTop: 30 }} items={tabItems}>
                        </Tabs>
                    </div>
                </Form>
                <InsertOrderItemModal ref={orderItemModalRef}>

                </InsertOrderItemModal>

            </fieldset>
        </>
    )
}


function mapStateToProps(state) {

    return {
        isLoading: state.orderDetailReducer.isLoading,
        hasError: state.orderDetailReducer.hasError,
        errorMessage: state.orderDetailReducer.errorMessage,
        customerList: state.customerReducer.customerList,
        orderMasterInfo: state.orderDetailReducer.orderMasterInfo,
        isOrderEditable: state.orderDetailReducer.orderisEnable,
        orderItemList: state.orderDetailReducer.orderItemList || []
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectProductListAction: bindActionCreators(selectProductList, dispatch),
        selectCustomerListAction: bindActionCreators(selectCustomerList, dispatch),
        selectOrderDetailAction: bindActionCreators(selectOrderDetail, dispatch),
        updateOrderMasterAction: bindActionCreators(updateOrderMaster, dispatch),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder);