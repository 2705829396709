import React, { useState, useEffect, useRef } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, InputNumber, Select } from 'antd';
import Flex from 'components/shared-components/Flex'
import countryCodes from "assets/data/countryCodes.json"

import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { PhoneOutlined, UserOutlined, CloseOutlined, } from '@ant-design/icons';
import network from 'network/network';
import globalConstant from 'constants/globalConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { selectBranchList } from 'utils/redux/actions/branchAction';
import { fileToBase64 } from 'helpers/fileHelper';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BRANCH_ID } from 'constants/StorageConstant';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const { TabPane } = Tabs;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

const ADD = 'ADD'


const rules = {
	customerName: [
		{
			required: true,
			message: 'Lütfen Müşteri Adını giriniz',
		}
	],
	customerNumber: [
		{
			required: true,
			message: 'Lütfen Telefon Numarasını giriniz',
		}
	],
	countryCode: [
		{
			required: true,
			message: 'Lütfen Ülke Kodunu seçiniz',
		}
	],
}

const EditCustomer = (props) => {
	let navigate = useNavigate();

	const { mode = ADD, param } = props

	const [form] = Form.useForm();
	const { branchList } = props;
	const { t, i18n } = useTranslation();
	const mainImageFileUploadRef = useRef();
	const [uploadedImg, setImage] = useState('')
	const [toData, setToData] = useState([])
	const [uploadLoading, setUploadLoading] = useState(false)
	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [firmSellerList, setFirmSellerList] = useState([]);
	const [selectedFirmSeller, setSelectedFirmSeller] = useState(-1);
	const [selectedBranchId, setSelectedBranchId] = useState(0);
	const [groupState, setGroupState] = useState({
		groupToid: [],
		groupToName: [],
	});
	const { id } = useParams();
	const branchId = localStorage.getItem(BRANCH_ID);
	const [customerState, setCustomerState] = useState({
		customerName: "",
		customerImage: "",
		customerNumber: "",
		countryCode: "",
		customerStatus: -1,
		customerId: id,
		customerAddress: "",
		email: "",
		lat: 0,
		lng: 0,
		isapproved: false,
		approvedate: "",
		dennydate: "",
		Customercountryid: "",
		Customercity: "",
		Customerdistrict: "",
		CustomerBranch: 0
		// Customergroups:[]

	});
	const [fields, setFields] = useState([
		{
			name: ['countryCode'],
			value: '+90',
		},
	]);

	useEffect(() => {

		// selectFirmSellerList();
		selectGroupsList();
		if (id > 0) {
			selectCustomerDetail();
		} else {
			setGetDetailLoading(false);
			props.selectBranchListAction({});
 
		}

	}, [form, mode, param]);

	const selectCustomerDetail = async () => {

		setGetDetailLoading(true);
		var params = {
			customerId: id
		}


		var result = await network.get("/admin/Client/selectClientDetail", params, false);



		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		const { data } = result;

		if (result.isSuccess && data.isSuccess) {
			var customer = data.data;
			var number = customer.customernumber.substring(2);
			setCustomerState({
				countryCode: "+" + customer.countrycode,
				customerId: customer.customerid,
				customerImage: customer.customerimage || "",
				customerName: customer.customername,
				customerNumber: number,
				customerStatus: customer.customerstatus,
				customerAddress: customer.customeraddress,
				lat: customer.lat,
				lng: customer.lng,
				Customercountryid: customer.countrycodeid,
				Customercity: customer.city,
				Customerdistrict: customer.district,
				// Customergroups: customer.groups,
				// approvedate: customer.approvedate,
				// dennydate: customer.dennydate,
				// isapproved: customer.isapproved,

			});
			setGroupState({
				groupToid: customer.groups
			});

			setSelectedFirmSeller(customer.firmsellerid);
			// {
			// 	"data": {
			// 	  "customerid": 61,
			// 	  "firmid": 1,
			// 	  "countrycode": "90",
			// 	  "customernumber": "905443777070",
			// 	  "customername": "Kaan Ayhan",
			// 	  "customerstatus": 1,
			// 	  "customeraddress": "Meydan",
			// 	  "lat": 0,
			// 	  "lng": 0,
			// 	  "countrycodeid": 1,
			// 	  "city": "Gaziantep",
			// 	  "district": "Şehitkamil"
			// 	},
			// 	"isSuccess": true,
			// 	"message": null
			//   }
		} else {
			setCustomerState({
				...customerState,
				customerId: -1
			})
		}
	}
	const handleToChange = (value, label) => {

		try {
			setGroupState({
				...groupState,
				groupToid: value,
				groupToName: label
			})

		} catch (error) {
			alert(error);
		}
	};
	const handleCountryChange = (value) => {

		try {
			setCustomerState({
				...customerState,
				Customercountryid: value
			})

		} catch (error) {
			alert(error);
		}
	};
	const selectFirmSellerList = async () => {

		var params = {
		}

		var result = await network.get("/admin/FirmSellers/selectFirmSellerList", params, false);

		const { data } = result;
		if (result.isSuccess && data.isSuccess) {

			setFirmSellerList(data.data);
		}
	}
	const selectGroupsList = async () => {

		var toResult = await network.get("/admin/Group/selectGroupList", false);

		const toData = toResult.data.data;
		setToData(toData);

	}

	const onCountryCodeChange = (val) => {
		const code = val.key.replace("+", "")
		try {
			setCustomerState({
				...customerState,
				countryCode: code
			})
		} catch (error) {
			alert(error);
		}
	}


	const onFinish = async () => {

		if (customerState.customerName.length == 0 || customerState.customerNumber.length == 0
			|| customerState.countryCode.length == 0) {
			message.error('Lütfen zorunlu alanları doldurunuz');
			return;
		}

		setSubmitLoading(true)

		var countryCode = customerState.countryCode.replace("+", "");
		var customerNumber = countryCode + customerState.customerNumber;
		var params = {
			Customerid: parseInt(customerState.customerId),
			Countrycode: countryCode,
			Customernumber: customerNumber,
			Customername: customerState.customerName,
			Email: customerState.email,
			Customerstatus: parseInt(globalConstant.customerStatus.active),
			FirmSellerId: -1,
			groupIds: groupState.groupToid,
			branchId: selectedBranchId
		}

		var method = "insertClient";
		if (params.Customerid > 0) {
			method = "updateClient";
		}

		var result = await network.post(`/admin/Client/${method}`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			var postResult = result.data;
			if (postResult.isSuccess) {

				message.success('Müşteri bilgileri başarılı bir şekilde kaydedilmiştir.');

				setTimeout(() => {

					navigate(`/dashboard-customer`)
				}, 250);

			} else {
				alert(postResult.errorMessage);
			}

		} else {
			alert(result.errorMessage);
		}


	};

	const onFileUpload = async (event) => {

		var mainImageFromFile = event.target.files[0]

		if (mainImageFromFile) {
			var base64 = await fileToBase64(mainImageFromFile);

			setCustomerState({
				...customerState,
				customerImage: base64
			});
		}
	};


	const getRemoveImageButton = () => {

		if (customerState.customerImage.length > 0) {

			return (
				<Button type="danger" onClick={() => {
					setCustomerState({
						...customerState,
						customerImage: ""
					});
				}} htmlType="button" shape='circle' loading={submitLoading}
					icon={<CloseOutlined></CloseOutlined>}
					style={{ float: "right" }}
				>
					{/* Kaldır */}
				</Button>
			);
		}
	}

	if (getDetailLoading) {
		return (
			<div>

			</div>
		);
	}

	const getimageUrl = () => {

		if (customerState.customerImage.length > 0) {
			if (customerState.customerImage.indexOf("data:image") != -1) {

				return customerState.customerImage;
			} else {

				var imageurl = globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, customerState.customerImage);

				return imageurl;
			}

		} else {
			return "";
		}
	}

	const getCustomerLocation = () => {

		if (customerState.customerId > 0) {


			return (
				<Col xs={24} sm={24} md={24}>
					<div className='ant-col ant-form-item-label'>
						<label className='ant-form-item' htmlFor="countryCode">Müşteri Konumu</label>
					</div>
					<div>
						<a className="row justify-content-center" target={"_blank"} href={`https://www.google.com/maps/search/?api=1&query=${customerState.lat},${customerState.lng}`}>
							{customerState.lat + "  -  " + customerState.lng + "    "}
							<FontAwesomeIcon style={{ marginRight: 5, color: "#585858", fontSize: 16 }} icon={faMapMarkerAlt} />
						</a>
					</div>

				</Col>
			);
		}
	}
	const getCustomerApproveInformation = () => {

		if (customerState.customerId > 0) {
			var approveText = customerState.isapproved == true ? "Müşteri Tarafından Onay  Alındı" : "Müşteri Tarafından Onay Reddedildi.";
			var approveColor = customerState.isapproved == true ? "green" : "red";

			return (
				<Row gutter={16}>
					<div className='m-3'>

					</div>
					<Col xs={24} sm={24} md={24}>
						<div className='ant-col ant-form-item-label'>
							<label className='ant-form-item' htmlFor="countryCode">Müşteri Onay Bilgisi</label>
						</div>
						<div>
							<div style={{ color: approveColor }}>
								{approveText}
							</div>
							<div>
								<label className='ant-col ant-form-item-label mr-2'>
									{customerState.isapproved == true ? "Onaylanma Tarihi" : "Red Tarihi"}:
								</label>
								<label className='ant-col ant-form-item-label'>
									{customerState.isapproved == true ? customerState.approvedate : customerState.dennydate}
								</label>
							</div>
						</div>
					</Col>
				</Row>
			);
		}
	}

	return (
		<>
			<Helmet>
				<title>{customerState.customerId > 0 ? t('updateCustomer') : t('addCustomer')}</title>
			</Helmet>
			<Form
				layout="vertical"
				fields={fields}
				name="advanced_search"
				className="ant-advanced-search-form"
			>
				<PageHeaderAlt className="border-bottom" overlap>
					<div className="container">
						<Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
							<h2 className="mb-3">
								{customerState.customerId > 0 ? t('updateCustomer') : t('addCustomer')}
							</h2>
							<div className="mb-3">
								{/* <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading}>
									{customerState.customerId > 0 ? t('update') : t('add')}
								</Button> */}
								<ReusableButton
									variant="campaign"
									text={customerState.customerId > 0 ? t('update') : t('add')}
									onClick={onFinish}
									// icon={<PlusCircleOutlined />}
									htmlType="submit" 
									loading={submitLoading}
								/>
							</div>
						</Flex>
					</div>
				</PageHeaderAlt>
				<div className="container">
					<Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
						<TabPane tab={t('generalInfo')} key="1">
							<Row gutter={16}>
								<Col xs={24} sm={24} md={17}>
									<Card title={t('customerInfo')}>
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">{t('customerName')}</label>
										</div>

										<Input value={customerState.customerName}
											defaultValue={customerState.customerName}
											onChange={(val) => {
												setCustomerState({
													...customerState,
													customerName: val.target.value
												});
											}}
											placeholder={t('customerName')} />

										<div className='m-4'></div>

										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">{t('customerGroup')}</label>
										</div>
										<Select
											mode="multiple"
											onChange={handleToChange}
											placeholder={t('customerGroup')}
											defaultValue={groupState.groupToid}
											style={{ width: '100%' }}>
											{toData.map((item, index) => (
												<Option value={item.groupid} label={item.groupname} >
													{item.groupname}
												</Option>
											))}
										</Select>

										<div className='m-4'></div>

										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="customerName">{t('email')}</label>
										</div>
										<Input value={customerState.email}
											defaultValue={customerState.email}
											onChange={(val) => {
												setCustomerState({
													...customerState,
													email: val.target.value
												});
											}}
											placeholder={t('email')} />

										<div className='m-4'></div>

										<div className='ant-col ant-form-item-label mr-4'>
											<label className='ant-form-item-required' htmlFor="customerName">{t('branch')}</label>
										</div>
										<Select
											defaultValue={0}
											style={{ width: 125 }}
											options={[
												...branchList.map(item => ({
													value: item.branchid,
													label: item.branchname
												})),
											]}
											onChange={(value) => setSelectedBranchId(value)}
											size='small'
										/>

										<div className='m-4'></div>

										<Row gutter={16}>
											<Col xs={5} sm={8} md={4}>
												<div className='ant-col ant-form-item-label'>
													<label className='ant-form-item-required' htmlFor="countryCode">{t('countryCode')}</label>
												</div>
												<Select
													showSearch
													labelInValue
													defaultValue={{ key: customerState.countryCode || t('countryCode') }}
													style={{ width: 120 }}
													onChange={(val) => {
														onCountryCodeChange(val);
													}}
												>
													{countryCodes.map((item, index) => (
														<Option key={item.dial_code} value={item.dial_code}>
															{item.code + "(" + item.dial_code + ")"}
														</Option>
													))}
												</Select>
											</Col>
											<Col xs={24} sm={24} md={12}>
												<div className='ant-col ant-form-item-label'>
													<label className='ant-form-item-required' htmlFor="customerName">{t('phoneNumber')}</label>
												</div>
												<Input
													value={customerState.customerNumber}
													defaultValue={customerState.customerNumber}
													onChange={(val) => {
														setCustomerState({
															...customerState,
															customerNumber: val.target.value
														});
													}}
													placeholder={t('phoneNumber')}
													prefix={<PhoneOutlined className="site-form-item-icon" />}
												/>
											</Col>
										</Row>
									</Card>
								</Col>
							</Row>
						</TabPane>

					</Tabs>
				</div>
			</Form>
		</>
	)
}

function mapStateToProps(state) {

	return {

		branchList: state.branchReducer.branchList
	}
}

function mapDispatchToProps(dispatch) {
	return {

		selectBranchListAction: bindActionCreators(selectBranchList, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
