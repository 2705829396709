

export const targetAppContstants ={

    order:"order",
    reservation:"reservation",
    appointment:"appointment",
    coffeeproject:"coffeeproject",
    campaign:"campaign",
    event:"event",
}

 