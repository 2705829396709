import { SaveOutlined } from "@ant-design/icons";
import { Card, Checkbox, Col, Form, Input, message, Row, Select } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
const ADD = 'ADD'


const CoffeeProductGroupCreate = props => {

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { mode = ADD, param } = props

    const [form] = Form.useForm();

    const [productGroupState, setProductGroupState] = useState({
        productGroupId: 0,
        productGroupName: "",
        productGroupDesc: "",
        status: true,
        isInitialGroup: false


    });
    const [getDetailLoading, setGetDetailLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        if (id > 0) {

            selectProductGroupDetail();
        } else {
            setGetDetailLoading(false);
            // selectProductGroupDetail();

        }
    }, [form, mode, param, props])


    const selectProductGroupDetail = async () => {
        setGetDetailLoading(true);

        var result = await network.get("/admin/CoffeeProduct/getAllProductGroups", false);

        setTimeout(() => {
            setGetDetailLoading(false);
        }, 100);

        const { data } = result.data;


        var index = data.findIndex(w => w.productgroupid == id);
        if (result.isSuccess) {
            setProductGroupState({
                productGroupId: id,
                productGroupName: data[index].productgroupname,
                productGroupDesc: data[index].productgroupdesc,
                status: data[index].status,
                isInitialGroup: data[index].isinitialgroup


            });


        }
    }


    const onFinish = async () => {

        if (productGroupState.productGroupName.length == 0) {
            message.error(t('requiredFields'));
            return;
        }


        var params = {
            productGroupId: id,
            isInitialGroup: productGroupState.isInitialGroup,
            productGroupDesc: productGroupState.productGroupDesc,
            productGroupName: productGroupState.productGroupName,
            status: true,

        }

        var method = "AddProductGroup";
        if (params.productGroupId > 0) {
            method = "UpdateProductGroup";
        }

        var result = await network.post(`/admin/CoffeeProduct/${method}`, params);


        if (result.isSuccess) {
            var postResult = result.data;
            if (postResult.isSuccess) {

                message.success(t('groupSavedSuccess'));

                setTimeout(() => {
                    navigate(`/coffee-productgroup`)
                }, 250);

            } else {
                alert(postResult.errorMessage);
            }

        } else {
            alert(result.errorMessage);
        }


    };

    return (
        <>
            <Helmet title={id > 0 ? t("productGroupUpdate") : t("productGroupAdd")} />
            <Form layout="vertical" name="advanced_search" className="ant-advanced-search-form">
                <div>
                    <Row gutter={24} style={{ marginTop: 20 }}>
                        <Col xs={24} sm={24} md={24}>
                            <Card title={t("productInfo")}>
                                <Col span={10} className="mb-3">
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required" htmlFor="customerName">{t("productGroupName")}</label>
                                    </div>
                                    <Input
                                        value={productGroupState.productGroupName}
                                        defaultValue={productGroupState.productGroupName}
                                        onChange={(val) => {
                                            setProductGroupState({
                                                ...productGroupState,
                                                productGroupName: val.target.value,
                                            });
                                        }}
                                        placeholder={t("productGroupNamePlaceholder")}
                                    />
                                </Col>
                                <Col span={10} className="mb-3">
                                    <div className="ant-col ant-form-item-label">
                                        <label htmlFor="customerName">{t("productGroupDesc")}</label>
                                    </div>
                                    <Input
                                        value={productGroupState.productGroupDesc}
                                        defaultValue={productGroupState.productGroupDesc}
                                        onChange={(val) => {
                                            setProductGroupState({
                                                ...productGroupState,
                                                productGroupDesc: val.target.value,
                                            });
                                        }}
                                        placeholder={t("productGroupDescPlaceholder")}
                                    />
                                </Col>
                                <Col span={10} className="mb-3">
                                    <div className="row">
                                        <div className="ant-col ant-form-item-label">
                                            <label htmlFor="customerName" style={{ marginRight: '10px' }}>{t("initialGroup")}</label>
                                            <Checkbox
                                                checked={productGroupState.isInitialGroup}
                                                onChange={(val) => {
                                                    setProductGroupState({
                                                        ...productGroupState,
                                                        isInitialGroup: val.target.checked,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                    <div>
                                        <ReusableButton
                                            variant="campaign"
                                            text={t('save')}
                                            htmlType="submit"
                                            icon={<SaveOutlined />}
                                            onClick={() => onFinish()}
                                        />
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>

        </>
    )
}

export default CoffeeProductGroupCreate