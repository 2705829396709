import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Alert, Card, Row, Col, message } from "antd";

import PropTypes from 'prop-types';
import { hideAuthMessage, loginUser } from 'utils/redux/actions/loginAction';
import { INITIAL_ROUTE_NAME } from 'constants/StorageConstant';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import network from 'network/network';
import Loading from 'components/shared-components/Loading';

const ResetPassword = props => {

	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token'); // ?token=asd şeklindeki değeri alır

	const [resetPasswordError, setResetPasswordError] = useState("");
	const [tokenError, setTokenError] = useState("");
	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [isCompleteReset, setIsCompleteReset] = useState(false);

	const initialCredential = {
		password: '',
		passwordAgain: '',
	}

	useEffect(() => {
			i18n.changeLanguage(navigator.language);
		controlToken();
	}, []);
	const controlToken = async () => {
		setInitialLoading(true);

		const params = {
			token: token
		}

		const result = await network.get("/admin/account/is-valid-token", params);
		setInitialLoading(false);
		if (result.isSuccess) {
			const status = result.data;
			if (status == "success") {

			} else {
				if (status == "notfound") {
					setTokenError(t("resetTokenNotFound"));
				} else if (status == "expire") {
					setTokenError(t("resetTokenExpire"));
				} else if (status == "completed") {
					setTokenError(t("resetTokenCompleted"));
				}
			}
		} else {
			setTokenError(t("networkError"));
		}

	}
	const onLogin = async (values) => {

		if (values.password != values.passwordAgain) {
			message.error(t("passwordMatchError"));
			return;
		}
		setLoading(true);
		const params = {
			Token: token,
			NewPassword: values.password
		}
		const result = await network.post("/admin/account/reset-pass", params);
		setLoading(false);
		if (result.isSuccess) {
			setIsCompleteReset(true);

			setTimeout(() => {
				navigate("/login")
			}, 500);
		} else {
			setResetPasswordError(t("resetPasswordCompletedError"));
		}

	};


	const backgroundStyle = {
		backgroundImage: 'url(/img/others/img-17.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	}
	return (
		<div className='auth-container'>
			<div className="h-100" style={backgroundStyle}>
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Col xs={20} sm={20} md={20} lg={7}>
							<Card>
								<div className="my-4">
									<div className="text-center">
										<img className="img-fluid" src={`/img/${'logo.png'}`} alt="" />
										<p> </p>
									</div>
									<Row justify="center">
										<Col xs={24} sm={24} md={20} lg={20}>
											{initialLoading?(
												<Loading align='center' />
											) :isCompleteReset ? (
												<motion.div
													initial={{ opacity: 0, marginBottom: 0 }}
													animate={{
														opacity: 1,
														marginBottom: 20
													}}>
													<Alert type="success" showIcon message={t("resetPasswordCompletedSuccess")}></Alert>
												</motion.div>
											) : tokenError?.length > 0 ? (
												<motion.div
													initial={{ opacity: 0, marginBottom: 0 }}
													animate={{
														opacity: 1,
														marginBottom: 20
													}}>
													<Alert type="error" showIcon message={(tokenError)}></Alert>
												</motion.div>
											) : (
												<>
													{resetPasswordError?.length > 0 && (
														<motion.div
															initial={{ opacity: 0, marginBottom: 0 }}
															animate={{
																opacity: 1,
																marginBottom: 20
															}}>
															<Alert type="error" showIcon message={(resetPasswordError)}></Alert>
														</motion.div>
													)}
													<Form
														layout="vertical"
														name="login-form"
														initialValues={initialCredential}
														onFinish={onLogin}
													>
														<Form.Item
															name="password"
															label={
																<div className='d-flex justify-content-between w-100 align-items-center'>
																	<span>{t('newPassword')}</span>

																</div>
															}
															rules={[
																{
																	required: true,
																	message: t('WrongPassword'),
																}
															]}
														>
															<Input.Password prefix className="text-primary" />
														</Form.Item>

														<Form.Item
															name="passwordAgain"
															label={
																<div className='d-flex justify-content-between w-100 align-items-center'>
																	<span>{t('newPasswordAgain')}</span>

																</div>
															}
															rules={[
																{
																	required: true,
																	message: t('WrongPassword'),
																}
															]}
														>
															<Input.Password prefix className="text-primary" />
														</Form.Item>

														<Form.Item>
															<Button type="primary" htmlType="submit" block loading={loading} >
																{t("changePassword")}
															</Button>
														</Form.Item>

													</Form>
												</>
											)}

										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</div>

	)
}



export default ResetPassword;

