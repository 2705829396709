import globalConstant from "constants/globalConstant";
import { MESSAGE_TYPES } from "constants/messageConstants";
import network from "network/network";
import actionTypes from "./actionTypes";
import { notification } from 'antd';


export function listenerActivate() {

    return (dispatch, getState) => {


 
        dispatch({
            type: actionTypes.LISTENER_ACTIVATE,
        })
    }
};




