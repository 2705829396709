import { API_BASE_URL } from "./ApiConstant";


const globalConstant = {


    imageTypes: {
        userImage: {
            type: 1,
            imagePath: "/CustomFolders/images/firmUserImages/",
            blankImagePath: "/img/avatars/user-blank-image.jpg"
        },
        customerImage: {
            type: 2,
            imagePath: "/CustomFolders/images/customerImages/",
            blankImagePath: "/img/avatars/user-blank-image.jpg"
        },
        productImage: {
            type: 3,
            imagePath: "/CustomFolders/images/productImages/",
            blankImagePath: "/img/avatars/product-blank.png"
        },
        notificationImage: {
            type: 4,
            imagePath: "/CustomFolders/images/notificationImages/",

        },
        roomImage: {
            type: 5,
            imagePath: "/CustomFolders/images/roomImages/",
            blankImagePath: "/img/avatars/room-blank.png"
        },
        getImageUrl: (imageType, imageName) => {
            var imageUrl = "";
            var blankimageUrl = "";
           
            switch (imageType) {
                case globalConstant.imageTypes.customerImage.type:
                    imageUrl = API_BASE_URL + globalConstant.imageTypes.customerImage.imagePath;
                    blankimageUrl = globalConstant.imageTypes.customerImage.blankImagePath;
                    break;
                case globalConstant.imageTypes.userImage.type:
                    imageUrl = API_BASE_URL + globalConstant.imageTypes.userImage.imagePath;
                    blankimageUrl = globalConstant.imageTypes.userImage.blankImagePath;
                    break;
                case globalConstant.imageTypes.productImage.type:
                    imageUrl = API_BASE_URL + globalConstant.imageTypes.productImage.imagePath;
                    blankimageUrl = globalConstant.imageTypes.productImage.blankImagePath;
                  
                    break;
                    case globalConstant.imageTypes.roomImage.type:
                        imageUrl = API_BASE_URL + globalConstant.imageTypes.roomImage.imagePath;
                        blankimageUrl = globalConstant.imageTypes.roomImage.blankImagePath;
                      
                        break;

                default:
                    break;
            } 
            if (imageName && imageName.length > 0) {
                return imageUrl + imageName;
            } else {
                return blankimageUrl;
            }



        }
    },

    listenerTypes: {
        inboundmessage: "inboundmessage",
        outboundmessage: "outboundmessage",
        newcustomer: "newcustomer",
        neworder: "neworder",
        message_event: "message-event",
        connectCustomerService : "connectCustomerService",
        newrequest :"newrequest",
        newBook : "newBook",
        newstamp: "newstamp"
        
    },
    soundFolderPaths: {
        newOrderSound: "/CustomFolders/sounds/"
    },
    customerStatus : {
        active : 1,
        waitConfirmation : 2,
        passive : 3
    },
    orderStatus : {
        inProgress : 1,
        onDelivery : 2,
        deliveryComplete : 3,
        cancelOrder : 4
    },
    paymentStatus :{
        waitForPayment : 0,
        paymentComplete : 1,
        paymentError : 2
    },
    paymentType :{
        cashOnDelivery : 1, 
        onlinePayment : 2
    },
    messageEventTypes :{
        enqueued : "enqueued",
        sent : "sent",
        delivered : "delivered",
        read : "read",

    },
    bookingStatus :{
        active : 1, 
        passive : 2
    },
    communicationchannels:{
        whatsapp:1,
        phoneCall:2
    },
    TemplateCategoryGroup:{
        marketing:1,
        utility:2,
        authentication:3,
    },
    TemplateCategoryType:{
        customMessage:1,
        productMessage:2,
        carousel:3,
        lto:4,
        copyCode:5,
        autofill:6,
        zeroTap:7,
    }
}
export default globalConstant;