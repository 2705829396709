import network from "network/network";
import actionTypes from "./actionTypes";

export function selectCampaignCustomers(params) {

     return (dispatch, getState) => {
 
         dispatch({
             type: actionTypes.CAMPAIGN_CUSTOMER_LIST_LOADING,
             isLoading: true
         })
 
         network.get("/admin/Campaign/GetCampaignDetail", params, false).then((result) => {
             dispatch({
                 type: actionTypes.CAMPAIGN_CUSTOMER_LIST_LOADING,
                 isLoading: false
             })
 
             const { data } = result;
 
             if (result.isSuccess && data.isSuccess) {
                 dispatch({
                     type: actionTypes.CAMPAIGN_CUSTOMER_LIST_SUCCESS,
                     payload: data.data
                 })
 
             } else {
                 dispatch({
                     type: actionTypes.CAMPAIGN_CUSTOMER_LIST_ERROR,
                     errorMessage: result.errorMessage,
                     hasError: true
                 })
             }
 
         });
     }
 };
 