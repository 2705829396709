
import React, { useState, useEffect, useRef } from 'react'
import { Form, message, Input, Row, Col, Card, DatePicker, TimePicker, Select } from 'antd';

import network from 'network/network';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import { SaveOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { TagsInput } from 'react-tag-input-component';
const { Option } = Select;


const ADD = 'ADD'

const EventStandCreate = props => {
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();



	const { mode = ADD, param } = props

	const [form] = Form.useForm();

	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [eventList, setEventList] = useState([]);

	const { id } = useParams();

	const [eventState, setEventState] = useState({
		eventId: "",
		standCOde: "",
		standDesc: "",
		attendeeFirmNmae: "",
		attendeeContactName: "",
		attendeeContactPhone: "",
		tags: [],
	});

	useEffect(() => {
		selectEventList();
		if (id > 0) {
			selectEventStandDetail();
		} else {
			setGetDetailLoading(false);
		}

	}, [form, mode, param, props]);

	const selectEventList = async () => {

		var result = await network.get("/admin/event/events");
		if (result.isSuccess) {
			setEventList(result.data);
		}


	}
	const selectEventStandDetail = async () => {

		setGetDetailLoading(true);
		const params = {
			eventStandId: parseInt(id) || 0
		}
		var result = await network.get("/admin/event/event-stand-detail", params);

		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		if (result.isSuccess) {
			setEventState({
				...result.data,
				tags:result.data.tags?.split(",")
			});
		} else {
			message.error(t("networkError"));
		}
	}


	const onFinish = async (values) => {


		setSubmitLoading(true)

		const params = {
			...values,
			Id: parseInt(id) || 0,
			tags: values.tags?.join(",")
		}
		var result = await network.post(`/admin/event/insert-update-event-stand`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			message.success(t('groupSavedSuccess'));

			setTimeout(() => {
				navigate(`/event-stands`)
			}, 250);

		} else {
			message.error(t("networkError"));
		}


	};

	if (getDetailLoading) {
		return (
			<div>

			</div>
		);
	}


	return (
		<>
			<Helmet title={id > 0 ? t('updateEventStand') : t('addEventStand')} />
			<Form layout="vertical" name="advanced_search" className="ant-advanced-search-form"
				initialValues={eventState}
				onFinish={onFinish}

			>
				<div>
					<Row gutter={24} style={{ marginTop: 20 }}>
						<Col xs={24} sm={24} md={24}>
							<Card title={t('eventStandInformations')}>


								<Row>
									<Col span={8} className="mr-3">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="standCode">{t('standCode')}</label>
										</div>
										<Form.Item name={"standCode"}
											rules={[
												{
													required: true,
													message: t('standCodeReq'),
												},
												{
													pattern: /^[^\s]+$/, // Boşluk karakterini engelleyen bir regex
													message: t("allowSingleWordError"),
												},
											]}
										>
											<Input
												placeholder={t('standCode')}
											/>
										</Form.Item>

									</Col>
									<Col span={8} className="">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="standDesc">{t('standDesc')}</label>
										</div>
										<Form.Item name={"standDesc"}
											rules={[
												{
													required: true,
													message: t('standDescReq'),
												}
											]}>
											<Input
												placeholder={t('standDesc')}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={8} className="mr-3">
										<div className='ant-col ant-form-item-label'>
											<label className='ant-form-item-required' htmlFor="eventId">{t('EventName')}</label>
										</div>
										<Form.Item name={"eventId"}
											rules={[
												{
													required: true,
													message: t('eventNameReq'),
												}
											]}
										>
											<Select
												mode="single"
												placeholder={t('EventName')}
												defaultValue={eventState.eventId}
												style={{ width: '100%' }}>
												{eventList.map((item, index) => (
													<Option value={item.eventId} label={item.eventName} >
														{item.eventName}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8} className="">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="attendeeFirmName">{t('attendeeFirmName')}</label>
										</div>
										<Form.Item name={"attendeeFirmName"}
											rules={[
												{
													required: true,
													message: t('attendeeFirmNameReq'),
												}
											]}
										>
											<Input
												placeholder={t('attendeeFirmName')}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col span={8} className="mr-3">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="attendeeContactName">{t('attendeeContactName')}</label>
										</div>
										<Form.Item name={"attendeeContactName"}
											rules={[
												{
													required: true,
													message: t('attendeeContactNameReq'),
												}
											]}
										>
											<Input
												placeholder={t('attendeeContactName')}
											/>
										</Form.Item>
									</Col>
									<Col span={8} className="">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="attendeeContactPhone">{t('attendeeContactPhone')}</label>
										</div>
										<Form.Item name={"attendeeContactPhone"}
											rules={[
												{
													required: true,
													message: t('attendeeContactPhoneReq'),
												}
											]}
										>
											<Input
												placeholder={t('attendeeContactPhone')}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Col span={8} className="mb-3">
									<div className="ant-col ant-form-item-label">
										<label className="ant-form" htmlFor="tags">{t('Tags')}</label>
									</div>
									<Form.Item name={"tags"}>
										<TagsInput
											value={form.getFieldValue("tags")?.split(",") ||[]}
											name="tags"
											placeHolder={t('Tags')}
										/>
									</Form.Item>
								</Col>


								<Col span={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
									<div>
										<ReusableButton
											variant="campaign"
											text={t('save')}
											htmlType="submit"
											icon={<SaveOutlined />}
										/>
									</div>

								</Col>
							</Card>
						</Col>
					</Row>
				</div>
			</Form>
		</>
	)
}

export default EventStandCreate 