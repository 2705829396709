import React, { Component, useEffect, useState } from 'react'
import Flex from 'components/shared-components/Flex';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Menu, Dropdown, Spin } from 'antd';
import { Progress, Tooltip, Tag, notification } from 'antd';
import { Badge, Descriptions, Table } from 'antd';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';
import network from 'network/network';
import helperFunc from 'helpers/helperFunc';
import { useNavigate, useParams } from 'react-router-dom';

import { PlusCircleOutlined, ClockCircleOutlined, SearchOutlined, TeamOutlined, RollbackOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import { Helmet } from 'react-helmet';


const Templates = (props) => {
     const { t, i18n } = useTranslation();
     const navigate = useNavigate();
     const [searchValue, setSearchValue] = useState("");
     const [groupList, setGroupList] = useState([]);
     const [loadingPanel, setLoadingPanel] = useState(false);
     const getTemplateTypeStyle = (type) => {
          switch (type) {
               case 'TEXT':
                    return { backgroundColor: '#f7faff', color: '#0078D4', padding: '5px 10px', borderRadius: '5px' }; // Hafif mavi ton
               case 'IMAGE':
                    return { backgroundColor: '#fff7e6', color: '#fa8c16', padding: '5px 10px', borderRadius: '5px' }; // Turuncu ton
               case 'VIDEO':
                    return { backgroundColor: '#fcefe6', color: '#d4380d', padding: '5px 10px', borderRadius: '5px' }; // Kırmızımsı ton
               default:
                    return { backgroundColor: '#f0f0f0', color: '#595959', padding: '5px 10px', borderRadius: '5px' }; // Nötr gri
          }
     };
     const getCategoryStyle = (category) => {
          switch (category) {
               case 'MARKETING':
                    return { backgroundColor: '#e6f7ff', color: '#1890ff', padding: '5px 10px', borderRadius: '5px' }; // Mavi ton
               case 'UTILITY':
                    return { backgroundColor: '#f6ffed', color: '#52c41a', padding: '5px 10px', borderRadius: '5px' }; // Yeşil ton
               case 'AUTHENTICATION':
                    return { backgroundColor: '#fff1f0', color: '#f5222d', padding: '5px 10px', borderRadius: '5px' }; // Kırmızı ton
               default:
                    return { backgroundColor: '#f0f0f0', color: '#595959', padding: '5px 10px', borderRadius: '5px' }; // Nötr gri
          }
     };
     const [columns, setColumns] = useState([


          {
               title: t('templateName'), // 'Template Adı' için dil desteği
               dataIndex: 'elementName',
               key: 'elementName',
               width: '200px',
               render: (_, record) => (
                    <div>
                         <div
                              className="flex-container d-inline"
                              style={{
                                   display: 'inline-block',
                                   color: '#333',
                                   padding: '5px 10px',
                                   fontWeight: '500',
                                   fontSize: '14px',
                              }}
                         >
                              {record.elementName}
                         </div>
                    </div>
               )
          },
          {
               title: t('category'), // 'Kategori' için dil desteği
               dataIndex: 'category',
               key: 'category',
               width: '100px',
               render: (_, record) => {
                    const typeStyle = getCategoryStyle(record.category);
                    return (
                         <div className='my-4 '>
                              <div style={{ ...styles.text, ...typeStyle }}>{record.category}</div>
                         </div>
                    );
               }
          },
          {
               title: t('templateType'), // 'Template Tipi' için dil desteği
               dataIndex: 'templateType',
               key: 'templateType',
               width: '100px',
               render: (_, record) => {
                    const typeStyle = getTemplateTypeStyle(record.templateType);
                    return (
                         <div className='my-4 '>
                              <div style={{ ...styles.text, ...typeStyle }}>{record.templateType}</div>
                         </div>
                    );
               }
          },
          {
               title: t('message'), // 'Mesaj' için dil desteği
               dataIndex: 'message',
               key: 'message',
               width: '450px',
               render: (_, record) => {
                    return (
                         <div style={styles.cell}>
                              <div style={{
                                   backgroundColor: "#DCF8C6",
                                   borderRadius: "12px",
                                   padding: "10px 15px",
                                   color: "#111111",
                                   fontWeight: 400,
                                   maxWidth: "100%",
                                   wordWrap: "break-word",
                                   boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                                   display: "inline-block",
                                   margin: "5px 0",
                                   alignSelf: "flex-end",
                              }}>
                                   {record.data}
                              </div>
                         </div>
                    );
               }
          },
          {
               title: t('status'), // 'Durumu' için dil desteği
               dataIndex: 'id',
               width: 150,
               align: 'center',
               render: (_, record) => {
                    if (record.status === "APPROVED") {
                         return (
                              <div className='my-4 '>
                                   <Tag color={"green"}>{t('approved')}</Tag> {/* 'Aktif' için dil desteği */}
                              </div>
                         )
                    }
                    else if (record.status === "REJECTED") {
                         return (
                              <div className='my-4 '>
                                   <Tag color={"yellow"}>{t('rejected')}</Tag> {/* 'Reddedildi' için dil desteği */}
                              </div>
                         )
                    }
                    else if (record.status === "FAILED") {
                         return (
                              <div className='my-4 '>
                                   <Tag color={"red"}>{t('failed')}</Tag> {/* 'Hatalı' için dil desteği */}
                              </div>
                         )
                    }
                    else if (record.status === "PENDING") {
                         return (
                              <div className='my-4 '>
                                   <Tag color={"orange"}>{t('pending')}</Tag> {/* 'Bekleyen' için dil desteği */}
                              </div>
                         )
                    }
                    else {
                         return (
                              <div className='my-4 '>
                                   <Tag color={"gray"}>{t('inactive')}</Tag> {/* 'Pasif' için dil desteği */}
                              </div>
                         )
                    }
               }
          },
          {
               title: '',
               dataIndex: 'actions',
               width: 50,
               align: 'center',
               render: (_, elm) => (
                    // <div className="text-right">
                    //      <Dropdown menu={{ items: getItems(elm) }}>
                    //           <div className="ellipsis-dropdown">
                    //                <EllipsisOutlined />
                    //           </div>
                    //      </Dropdown>
                    //      {/* <EllipsisDropdown menu={getItems(elm)} /> */}
                    // </div>
                    <Dropdown menu={{ items: getItems2(elm) }} trigger={['click']}>
                         <div className="ellipsis-dropdown" style={{ cursor: 'pointer' }}>
                              <EllipsisOutlined />
                         </div>
                    </Dropdown>
               )
          }

     ])

     useEffect(() => {
          selectGroupList();
     }, []);

     const onSearch = e => {
          const value = e.currentTarget.value
          setSearchValue(value);
     }
     const deleteGroupConfirm = async (row) => {

          setLoadingPanel(true);
          var params = {
               elementName: row.elementName
          }
          var result = await network.post("/TemplateMessage/deleteByName", params, false);

          setLoadingPanel(false);
          if (result.isSuccess) {
               if (result.data) {
                    notification.success({
                         message: t('successful'), // 'Başarılı' için dil desteği eklenmiş
                         description: t('templateDeleteSuccess'), // 'Template silme işlemi başarılı bir şekilde gerçekleştirildi' için dil desteği eklenmiş
                         placement: "topRight",
                    });
                    selectGroupList();
               } else {
                    notification.error({
                         message: t('error'), // 'Hata!' için dil desteği eklenmiş
                         description: result.data.errorMessage,
                         // placement:"topRight",
                    });
               }
          } else {
               notification.error({
                    message: t('error'), // 'Hata!' için dil desteği eklenmiş
                    description: result.errorMessage,
                    placement: "topRight",
               });
          }

     }
     const deleteGroupPrevious = (row) => {
          helperFunc.showConfirm({
               content: `${row.elementName} ${t('templateDeleteConfirmation')}`, // 'Template kalıcı olarak silinecek. Devam edilsin mi?' için dil desteği eklenmiş
               title: t('reminder'), // 'Hatırlatma' için dil desteği eklenmiş
               onConfirm: () => deleteGroupConfirm(row)
          });
     }
     const editTemplate = (elm) => {
          navigate("/template-edit", {
               state: { template: elm },
          });
     }
     const getItems2 = (elm) => [
          {
               key: 'edit',
               label: (
                    <div onClick={() => editTemplate(elm)}>
                         <EditOutlined />
                         <span className="ml-2">{t('edit')}</span>
                    </div>
               )
          },
          {
               key: 'delete',
               label: (
                    <div onClick={() => deleteGroupPrevious(elm)}>
                         <DeleteOutlined />
                         <span className="ml-2">{t('delete')}</span>
                    </div>
               )
          }
     ];




     const selectGroupList = async () => {

          setLoadingPanel(true);
          var result = await network.get("/TemplateMessage/GetAll");
          setLoadingPanel(false);
          const { data } = result;
          if (result.isSuccess && data.isSuccess) {
               setGroupList(data.data);
          }
     }

     const getTemplateListWithFilter = () => {

          if (searchValue.length > 0) {
               const filteredData = groupList.filter(function (el) {

                    return (
                         (el?.category?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                              el?.elementName?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                              el?.data?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1 ||
                              el?.templateType?.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
                    );
               });
               return filteredData;
          } else {
               return groupList
          }
     }

     const templateListWithFilter = getTemplateListWithFilter();
     return (
          <div>

               <Helmet>
                    <title>{t('templateList')}</title>
               </Helmet>
               <Spin spinning={loadingPanel} indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} >
                    <Card title={t('templateList')}>
                         <Flex className="mt-4" alignItems="center" justifyContent="between" mobileFlex={false}>
                              <Flex className="mb-1" mobileFlex={false}>
                                   <div className="mr-md-3 mb-3">
                                        <Input placeholder={t('Search')} prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                                   </div>
                              </Flex>
                              <Flex className="mb-1" mobileFlex={false}>

                              </Flex>
                              <Flex className="mb-1" alignItems="center" justifyContent="between" mobileFlex={false}>

                                   <div className="mr-md-3 mb-3">


                                        <ReusableButton
                                             variant="campaign"
                                             text={t('newTemplate')}
                                             href="template-create"
                                             icon={<PlusCircleOutlined />}
                                        />
                                   </div>
                              </Flex>
                         </Flex>
                         <Col span={24}><Table
                              columns={columns}
                              // loading={props.isLoading}
                              dataSource={templateListWithFilter}
                              rowKey='id'
                         /></Col>
                    </Card>
               </Spin>
          </div>
     );
}
const styles = {
     table: {
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginTop: '20px',
     },
     cell: {
          padding: '10px',
          textAlign: 'left',
          fontSize: '14px',
          color: '#333',
     },
     cell2: {
          padding: '10px',
          textAlign: 'left',
          fontSize: '14px',
          color: '#333',
     },
     text: {
          fontWeight: '500',
          display: 'inline-block',
          padding: '4px 8px',
          borderRadius: '4px',

     },
     header: {
          backgroundColor: '#f5f5f5',
          fontWeight: '600',
          color: '#1890ff',
     },
     radioButton: {
          display: 'flex',
          justifyContent: 'center',
     },
     // Row hover style
     rowHover: {
          backgroundColor: '#f0faff',
     },
     selectedRow: {
          backgroundColor: '#e6f7ff',
     },
     // Header styles
     headerTitle: {
          backgroundColor: '#1890ff',
          color: '#fff',
          padding: '12px',
          fontWeight: 'bold',
          fontSize: '16px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
     },
};
export default Templates; 
