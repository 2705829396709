import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Tooltip, Button, Col, Card, Input, Radio, Row, Select, message, Upload, Spin } from 'antd';
import { InfoCircleOutlined, PlusCircleOutlined, PlusOutlined, CloseCircleOutlined, PhoneOutlined, LinkOutlined, CloseOutlined, LoadingOutlined, ArrowLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './Steps.css';
import network from 'network/network';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplateInput from 'components/Elements/TemplateInput';
import TemplateSelectBox from 'components/Elements/TemplateSelectBox';
import TemplateMediaSelectBox from 'components/Elements/TemplateMediaSelectBox';
import TemplateTextArea from 'components/Elements/TemplateTextArea';

import { v4 as uuidv4 } from 'uuid';
import Flex from 'components/shared-components/Flex';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import EmojiPicker from 'emoji-picker-react';


const EditTemplate = (props) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { template } = location.state || {};
    const languageData = [
        {
            "key": "tr",
            "value": t("turkish"),
        },
        {
            "value": "English(US)",
            "key": "en_US"
        },
        {
            "value": "English(UK)",
            "key": "en_GB"
        }, {
            "value": "Azerbaijani",
            "key": "az"
        }, {
            "value": "Czech",
            "key": "cs"
        }, {
            "value": "French",
            "key": "fr"
        }, {
            "value": "German",
            "key": "de"
        }, {
            "value": "Irish",
            "key": "ga"
        }, {
            "value": "Italian",
            "key": "it"
        }, {
            "value": "Spanish",
            "key": "es"
        }, {
            "value": "Japanese",
            "key": "ja"
        }, {
            "value": "Korean",
            "key": "ko"
        }
    ]
    const headerData = [
        {
            key: 0,
            value: t('none'), // i18n ile "Yok" çevirisi
        },
        {
            key: 1,
            value: t('text'), // i18n ile "Metin" çevirisi
        },
        {
            key: 2,
            value: t('photo'), // i18n ile "Fotoğraf" çevirisi
        },
        {
            key: 3,
            value: t("Video") // 
        }, {
            key: 4,
            value: t("document") // 
        }
    ]
  
 
    const [formData, setFormData] = useState({
        templateId:template?.id,
        elementName: template.elementName,
        languageCode: template.languageCode,
        category: template.category,
        templateType: template.templateType,
        templateFormat: template.templateFormat,
        vertical: template.vertical,
        content: template?.editTemplate?.data,
        header: template.templateType,
        footer: template?.editTemplate.footer,
        buttons: template.buttons,
        example: removeBrackets(template?.editTemplate.sampleText),
        enableSample: template?.enableSample,
        allowTemplateCategoryChange: template?.allowTemplateCategoryChange,
        mediaId:template?.editTemplate?.mediaId,
        sampleText:template?.editTemplate?.sampleText,
        data:template?.editTemplate?.data,
    });
    const bodyCounterRef = useRef(0);


    const [quickReplies, setQuickReplies] = useState([]);
    const [phoneReplies, setPhoneReplies] = useState([]);
    const [urlReplies, setUrlReplies] = useState([]);
    let navigate = useNavigate();

    const [headerType, setHeaderType] = useState(0);
    const [language, setLanguage] = useState(languageData.filter(s => s.key == template.languageCode));
    const [inputs, setInputs] = useState(Array.from({ length: bodyCounterRef.current }, (_, index) => index));
    const [selectedImage, setSelectedImage] = useState();
    const [selectedHeaderText, setSelectedHeaderText] = useState();
    const [loadingPanel, setLoadingPanel] = useState(false)
    const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />
    useEffect(() => {
        getHeaderType();
        getButtonType();
        getDataVariables();
    }, []);
    console.log("d",template)
    const getButtonType=()=>{
        template?.editTemplate?.buttons?.map((item,index)=>{
            if(item.type=="QUICK_REPLY"){
                const newReplyObject = {
                    id: quickReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
                    value: item.text, // Yeni değer
                };
                setQuickReplies((prevReplies) => [...prevReplies, newReplyObject]);
            }else if(item.type=="PHONE_NUMBER"){
                const newReplyObject = {
                    id: phoneReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
                    value: item.phone_number, // Yeni değer
                    header:item.text
                };
                setPhoneReplies((prevReplies) => [...prevReplies, newReplyObject]);
            }
            else if(item.type=="URL"){
                const newReplyObject = {
                    id: urlReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
                    value:item.text,
                    url: item.url, // Yeni değer
                };
                setUrlReplies((prevReplies) => [...prevReplies, newReplyObject]);
            }
        });
    }
    const extractBracketContent = (text) => {
        const regex = /\[(.*?)\]/g;
      
        const matches = [...text.matchAll(regex)];
      
        return matches.map((match) => match[1]);
      };
    const countPlaceholders = (text) => {
        const regex = /{{(.*?)}}/g;
        const matches = text.match(regex);
        return matches ? matches.length : 0;
      };
      function removeBrackets(content) {
        return content.replace(/\[|\]/g, '');
      }
      
    const getDataVariables =()=>{
       var countVariables= countPlaceholders(template.editTemplate.data);
     var contents=  extractBracketContent(template.editTemplate.sampleText);
       for (let index = 0; index < countVariables; index++) {
        setInputs((prevInputs) => [
            ...prevInputs,
            {
                id: uuidv4(),
                reg: "{{" + `${prevInputs.length + 1}` + "}}",
                var: t('variable', { count: prevInputs.length + 1 }),
                value: contents[index]
            }
        ]);
       }
    }
    const getHeaderType = () => {
        if (template.templateType == "DOCUMENT") {
            setHeaderType(4);
        }
        else if (template.templateType == "VIDEO") {
            setHeaderType(3);

        }
        else if (template.templateType == "IMAGE") {
            setHeaderType(2);

        }
        else if (template.templateType == "TEXT") {
            setHeaderType(1);

        }
        else {
            setHeaderType(0);

        }
    }
    // Input değişikliklerini yönetme
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "elementName" && value != '') {
            const regex = /^[a-z0-9_]+$/;
            if (!regex.test(value)) {
                message.error(t('templateNameInfo3'));
                return;
            }
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleFooterChange = (e) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            footer: value,
        }));
    };
    const GetTemplateVariablesDesc = () => {
        let updatedContent = formData.content;

        // inputs array'sindeki her item için işlemi yapıyoruz
        inputs.map((item) => {
            // reg (örneğin: {{1}}) ile ilgili tüm eşleşmeleri value ile değiştiriyoruz
            updatedContent = updatedContent.replace(item.reg, item.value);
        });

        setFormData({ ...formData, example: updatedContent });
    }
    const GetTemplateVariablesDesc2 = (value) => {
        let updatedContent = value;

        // inputs array'sindeki her item için işlemi yapıyoruz
        inputs.map((item) => {
            // reg (örneğin: {{1}}) ile ilgili tüm eşleşmeleri value ile değiştiriyoruz
            updatedContent = updatedContent.replace(item.reg, item.value);
        });

        // formData.content'ı değiştirmiyoruz, sadece example'ı güncelliyoruz
        setFormData({ ...formData, example: updatedContent });
    }
    function getCustomerNumber(customerNumber) {
        if (!customerNumber.startsWith("9")) {
            if (customerNumber.startsWith("0")) {
                customerNumber = "9" + customerNumber;
            } else if (customerNumber.startsWith("5")) {
                customerNumber = "90" + customerNumber;
            }
        }
        return customerNumber;
    }
    const handleSubmit = async (e) => {
        setLoadingPanel(true);
        inputs.map((item) => {
            if (item.value == null || item.value == undefined || item.value == '') {
                message.error(t('templateVariablesRequired')); // i18n ile mesajı çeviriyoruz
                setLoadingPanel(false);
                return;
            }
        });

        if (!formData.elementName) {
            message.error(t('templateNameRequired')); // i18n ile mesajı çeviriyoruz
            setLoadingPanel(false);
            return;
        }
        if (!formData.languageCode) {
            message.error(t('languageSelectionRequired')); // i18n ile mesajı çeviriyoruz
            setLoadingPanel(false);
            return;
        }
        if (!formData.content) {
            message.error(t('templateContentRequired')); // i18n ile mesajı çeviriyoruz
            setLoadingPanel(false);
            return;
        }

        const keyValueList = [];
        quickReplies.map((quick) => {
            const model = {
                type: "QUICK_REPLY",
                text: quick.value
            }
            keyValueList.push(model)
        })
        urlReplies.map((quick) => {
            const model = {
                type: "URL",
                text: quick.value,
                url: quick.url
            }
            keyValueList.push(model)
        })
        phoneReplies.map((quick) => {
            const model = {
                type: "PHONE_NUMBER",
                text: quick.header,
                phone_number: getCustomerNumber(quick.value)
            }
            keyValueList.push(model)
        })
        var params = {
            elementName: formData.elementName,
            languageCode: formData.languageCode,
            category: "MARKETING",
            templateType: headerType == 1 ? "TEXT" : headerType == 2 ? "IMAGE" : headerType == 3 ? "VIDEO" : headerType == 4 ? "DOCUMENT" : "TEXT",
            vertical: "Marketing Message for Customers",
            content: formData.content || null,
            header: selectedHeaderText || null,
            footer: t("stopTemplateMessage"),
            example: formData.example || null,
            enableSample: formData.enableSample || null,
            allowTemplateCategoryChange: formData.allowTemplateCategoryChange || null,
            buttons: JSON.stringify(keyValueList),
            image: selectedImage || null,
            mediaId:formData.mediaId ,
            templateId:formData.templateId
        };
        var result = await network.postFormData("/TemplateMessage/EditTemplateMessage", params);
        const { data } = result;
        setLoadingPanel(false);
        if (result.isSuccess && data.isSuccess) {
            message.success(t('templateCreatedSuccessfully'));
            setTimeout(() => {
                navigate(`/templates`)
            }, 1000);
        } else {
            message.error(data.message);
        }
    };
    const handleInputChange = (index, value) => {
        const newQuickReplies = [...quickReplies];
        newQuickReplies[index].value = value;
        setQuickReplies(newQuickReplies);
    };
    const handleUrlChange = (index, value) => {
        const newQuickReplies = [...urlReplies];
        newQuickReplies[index].value = value;
        setUrlReplies(newQuickReplies);
    };
    const handleUrl2Change = (index, value) => {
        const newQuickReplies = [...urlReplies];
        newQuickReplies[index].url = value;
        setUrlReplies(newQuickReplies);
    };
    const handlePhoneChange = (index, value) => {
        const newQuickReplies = [...phoneReplies];
        newQuickReplies[index].value = value;
        setPhoneReplies(newQuickReplies);
    };
    const handlePhoneHeaderChange = (index, value) => {
        const newQuickReplies = [...phoneReplies];
        newQuickReplies[index].header = value;
        setPhoneReplies(newQuickReplies);
    };

    // Input'u temizleme
    const clearInput = (index) => {
        const newQuickReplies = quickReplies.filter((reply, idx) => idx !== index);
        setQuickReplies(newQuickReplies); // Yeni array'i state'e set ediyoruz
    };
    const clearPhoneInput = (index) => {
        const newQuickReplies = phoneReplies.filter((reply, idx) => idx !== index);
        setPhoneReplies(newQuickReplies); // Yeni array'i state'e set ediyoruz
    };
    const clearUrlInput = (index) => {
        const newQuickReplies = urlReplies.filter((reply, idx) => idx !== index);
        setUrlReplies(newQuickReplies); // Yeni array'i state'e set ediyoruz
    };


    const phoneOnClick = () => {
        const newReplyObject = {
            id: phoneReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
            value: '', // Yeni değer
        };
        setPhoneReplies((prevReplies) => [...prevReplies, newReplyObject]);
    }
    const urlOnClick = () => {
        const newReplyObject = {
            id: urlReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
            value: '', // Yeni değer
        };
        setUrlReplies((prevReplies) => [...prevReplies, newReplyObject]);
    }
    const addQuickReply = () => {

        const newReplyObject = {
            id: quickReplies.length + 1, // ID'yi dinamik olarak oluşturuyoruz
            value: '', // Yeni değer
        };
        setQuickReplies((prevReplies) => [...prevReplies, newReplyObject]);
    };

    const GetQuickReply = () => {
        return (
            <>
                {quickReplies.map((reply, index) => (
                    <div
                        key={reply.id}
                        className="row"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Input
                            name={`quiclyButton${reply.id}`}
                            placeholder={t('quickReplyPlaceholder')}  // Placeholder için çeviri
                            value={reply.value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            style={{ marginBottom: '8px', marginTop: '4px', flexGrow: 1 }}
                        />
                        <CloseCircleOutlined
                            onClick={() => clearInput(index)} // Çarpıya tıklandığında ilgili input'u temizle
                            style={{
                                fontSize: '20px',
                                color: '#1890ff',
                                cursor: 'pointer',
                                marginLeft: '8px',
                            }}
                        />
                    </div>
                ))}
            </>
        );
    }
    const GetPhoneCta = () => {
        return (
            <>
                {phoneReplies.map((reply, index) => (
                    <div
                        key={reply.id}
                        className="row"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Input
                            name={`phone${reply.id}`}
                            placeholder={t('phoneButtonTitle')}  // Placeholder için çeviri
                            value={reply.header}
                            onChange={(e) => handlePhoneHeaderChange(index, e.target.value)}
                            style={{ marginBottom: '8px', marginTop: '4px', flexGrow: 1 }}
                        />
                        <Input
                            name={`phone2${reply.id}`}
                            placeholder={t('phoneButtonValue')}  // Placeholder için çeviri
                            value={reply.value}
                            onChange={(e) => handlePhoneChange(index, e.target.value)}
                            style={{ marginBottom: '8px', marginTop: '4px', flexGrow: 1 }}
                        />
                        <CloseCircleOutlined
                            onClick={() => clearPhoneInput(index)} // Çarpıya tıklandığında ilgili input'u temizle
                            style={{
                                fontSize: '20px',
                                color: '#1890ff',
                                cursor: 'pointer',
                                marginLeft: '8px',
                            }}
                        />
                    </div>
                ))}
            </>
        );
    }
    const GetUrlCta = () => {
        return (
            <>
                {urlReplies.map((reply, index) => (
                    <div
                        key={reply.id}
                        className="row"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Input
                            name={`cta${reply.id}`}
                            placeholder={t('urlButtonTitle')}  // Placeholder için çeviri
                            value={reply.value}
                            onChange={(e) => handleUrlChange(index, e.target.value)}
                            style={{ marginBottom: '8px', marginTop: '4px', flexGrow: 1 }}
                        />
                        <Input
                            name={`cta2${reply.id}`}
                            placeholder={t('urlButtonValue')}  // Placeholder için çeviri
                            value={reply.url}
                            onChange={(e) => handleUrl2Change(index, e.target.value)}
                            style={{ marginBottom: '8px', marginTop: '4px', flexGrow: 1 }}
                        />
                        <CloseCircleOutlined
                            onClick={() => clearUrlInput(index)} // Çarpıya tıklandığında ilgili input'u temizle
                            style={{
                                fontSize: '20px',
                                color: '#1890ff',
                                cursor: 'pointer',
                                marginLeft: '8px',
                            }}
                        />
                    </div>
                ))}
            </>
        );
    }
    const handleLanguageChange = (value, key) => {
        setLanguage(key.value);
        setFormData({
            ...formData,
            languageCode: key.key
        })
    }
    const handleHeaderChange = (value, key) => {
        setHeaderType(key.key);
        setFormData({
            ...formData,
            header: key.value
        })
    }
    const beforeFileUpload = (file) => {
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
        const isVideo = file.type === 'video/mp4';
        const isDocument = file.type === 'application/pdf';

        const isValidImageSize = file.size / 1024 / 1024 < 5; // 5MB
        const isValidVideoSize = file.size / 1024 / 1024 < 16; // 16MB
        const isValidDocumentSize = file.size / 1024 / 1024 < 100; // 100MB

        if (isImage && !isValidImageSize) {
            message.error(t('imageSizeError')); // i18n ile mesajı çeviriyoruz
            return Upload.LIST_IGNORE;
        }
        if (isVideo && !isValidVideoSize) {
            message.error(t('videoSizeError')); // i18n ile mesajı çeviriyoruz
            return Upload.LIST_IGNORE;
        }
        if (isDocument && !isValidDocumentSize) {
            message.error(t('documentSizeError')); // i18n ile mesajı çeviriyoruz
            return Upload.LIST_IGNORE;
        }

        if (!isImage && !isVideo && !isDocument) {
            message.error(t('invalidFileFormat')); // i18n ile mesajı çeviriyoruz
            return Upload.LIST_IGNORE;
        }

        return true; // Dosya geçerli, yükleme işlemine devam edebilir.
    }
    const handleContentChange = (e) => {

        if (e.target.value.length == 0) {
            bodyCounterRef.current = 0;
        }
        GetTemplateVariablesDesc2(e.target.value);
        setFormData({
            ...formData,
            content: e.target.value
        });
    }
    const handleMediaChange = (e) => {
        if (headerType == 1) {
            setSelectedHeaderText(e.target.value)
        }
        else if (headerType == 2 || headerType == 3 || headerType == 4) {
            setSelectedImage(e.file.originFileObj);
        }

    }


    const addBodyVariable = () => {
        var length = inputs.length + 1;
        var insertText = formData.content + " " + "{{" + length + "}}" + " ";
        setFormData({
            ...formData,
            content: insertText
        })
        setInputs((prevInputs) => [
            ...prevInputs,
            {
                id: uuidv4(),
                reg: "{{" + `${prevInputs.length + 1}` + "}}",
                var: t('variable', { count: prevInputs.length + 1 }),
                value: ''
            }
        ]);
    };

    const handleRemove = (id) => {
        // inputs.filter(s=>s.)
        const updatedInputs = inputs.filter((input) => input.id !== id); // ID'yi kullanarak inputu sil

        setInputs(updatedInputs);
    };
    const GetDinamicInputs = (input) => {
        return (
            <Row gutter={16} key={input.id}>
                <Col span={6}>
                    <Input key={`var-${input.id}`} placeholder={input.var} style={{ marginTop: 5, marginBottom: 5 }} disabled={true} />
                </Col>
                <Col span={16}>
                    <Input
                        key={`input-${input.id}`}
                        placeholder={t('inputPlaceholder')}
                        style={{ marginTop: 5, marginBottom: 5 }}
                        value={input.value}
                        onChange={(e) => {
                            const updatedInputs = inputs.map((item) =>
                                item.id === input.id ? { ...item, value: e.target.value } : item
                            );
                            setInputs(updatedInputs);
                            GetTemplateVariablesDesc();
                        }}
                    />
                </Col>
                <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CloseOutlined
                        onClick={() => handleRemove(input.id)} // Tıklandığında input id'sine göre silme işlemi yapılır
                        style={{ fontSize: '18px', color: '#1890ff', cursor: 'pointer' }}
                    />
                </Col>
            </Row>
        );
    };
    const GetVariables = () => {

        return (
            <>
                {inputs.map((index) => GetDinamicInputs(index))}
            </>
        );
    }
    return (
        <Spin spinning={loadingPanel} indicator={Icon} >
            <div style={{
                maxWidth: '98%',
                margin: '0 auto',
                padding: '30px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                border: '1px solid #d9d9d9'
            }}>
                <div className='row-justify-between'>
                    <h2 style={{ marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>{t('editTemplate')}</h2>
                    <Flex className="mb-1" alignItems="center" justify='flex-end' mobileFlex={false}>
                        <div className="mb-3">
                            {/* <Button href={"templates"} type="primary" block>{t('backToTemplate')}</Button> */}
                            <ReusableButton
                                variant="campaign"
                                text={t('backToTemplate')}
                                // onClick={sendTestTemplateMessage}
                                href={"templates"}
                                icon={<UnorderedListOutlined />}
                            />
                        </div>
                    </Flex>
                </div>

                {/* <Flex className="mb-1" alignItems="center" justify='flex-end' mobileFlex={false}>
                    <div className="mr-md-3 mb-3">
                        <Button href={"templates"} type="primary" block>{t('back')}</Button>
                    </div>
                </Flex> */}
                {/* Template Name and Language - Two components side by side */}
                <Row gutter={16}>

                    <Col span={12}>
                        <TemplateInput
                            title={t('templateName')}
                            description={t('templateNameInfo1')}
                            value={formData.elementName}
                            onChange={handleChange}
                            infoMessage={t('templateNameInfo2')}
                            subDesc={t('templateNameInfo3')}
                            isRequired={true}
                            disabled={true}
                        />
                    </Col>
                    <Col span={12}>
                        <TemplateSelectBox
                            title={t('language')}
                            description={t('languageDescription')}
                            value={language}
                            onChange={handleLanguageChange}
                            languageData={languageData}
                            infoMessage={t('languageInfo')}
                            isRequired={true}
                            disabled={true}
                        />
                    </Col>
                    {/* <Col span={6}>
                    <TemplateSelectBox
                        title="Şablon Tipi"
                        description="Lütfen şablon tipini seçin."
                        // value={formData.templateType}
                        onChange={handleTemplateTypeChange}
                        languageData={templateTypeData}
                        infoMessage="Şablonunuzu göndereceğiniz tipi seçebilirsiniz."
                        isRequired={true}
                    />
                </Col> */}
                </Row>

                {/* Media Select Box */}
                <Row gutter={16} style={{ marginTop: '20px', display: 'flex', alignItems: 'stretch' }}>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100%', // Kolonların yüksekliklerinin eşit olmasını sağlar
                        }}
                    >
                        <TemplateMediaSelectBox
                            title={t('headerTitle')}
                            description={t('headerDescription')}
                            value={formData.header}
                            onChange={handleHeaderChange}
                            languageData={headerData}
                            infoMessage={t('headerInfoMessage')}
                            isRequired={false}
                            beforeFileUpload={beforeFileUpload}
                            mediaType={headerType}
                            handleMediaChange={handleMediaChange}
                        />
                    </Col>

                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100%', // Kolonların yüksekliklerinin eşit olmasını sağlar
                        }}
                    >

                        {/* <EmojiPicker /> */}
                        <TemplateTextArea
                            title={t('templateContentTitle')}
                            description={t('templateContentDescription')}
                            value={formData.content}
                            onChange={handleContentChange}
                            isRequired={true}
                            isVariables={true}
                            bodyCounterRef={bodyCounterRef}
                            addBodyVariable={addBodyVariable}
                            minHeight={285}
                        />
                        {
                            GetVariables()
                        }
                    </Col>
                </Row>


                {/* Footer Input */}
                <Row gutter={16} style={{ marginTop: '20px' }}>
                    {/* <Col span={12}>
                        <TemplateInput
                            title={t('footerTitle')}
                            value={formData.footer}
                            description={t('footerDescription')}
                            onChange={handleFooterChange}
                            isRequired={false}
                        />

                    </Col> */}
                    <Col span={12}>
                        <div style={{
                            backgroundColor: '#fff',
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            minHeight: 207
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '8px',
                            }}>
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <h3 style={{
                                        fontSize: '18px',
                                        fontWeight: '600',
                                        color: '#333',
                                        fontFamily: 'Helvetica, sans-serif',
                                        marginRight: '10px',
                                    }}>
                                        {t('buttonsTitle')}
                                    </h3>
                                    <div style={{
                                        padding: '2px 6px',
                                        backgroundColor: '#FFF6E6',
                                        borderRadius: '6px',
                                        fontSize: '14px',
                                        color: '#000010',
                                        fontWeight: '500',
                                        marginRight: '10px',
                                        height: '25px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {t('optional')}
                                    </div>
                                </div>
                                <Tooltip title={t('tooltipInfo')}>
                                    <InfoCircleOutlined style={{ color: '#1890ff', fontSize: '18px' }} />
                                </Tooltip>
                            </div>

                            <p style={{
                                fontSize: '14px',
                                color: '#595959',
                                marginBottom: '16px',
                                fontFamily: 'Verdana, sans-serif'
                            }}>
                                {t('buttonSelectionInfo')}
                            </p>

                            <div style={{ marginBottom: '12px' }}>
                                <Button
                                    type="button"
                                    disabled={quickReplies.length > 9}
                                    onClick={addQuickReply}
                                    icon={<PlusOutlined />}
                                    style={{ marginRight: 10 }}
                                >
                                    {t('quickReplyButton')}
                                </Button>

                                <Button
                                    type="button"
                                    disabled={urlReplies.length > 1}
                                    onClick={urlOnClick}
                                    icon={<LinkOutlined />}
                                >
                                    {t('urlButton')}
                                </Button>

                                <Button
                                    type="button"
                                    disabled={phoneReplies.length > 0}
                                    onClick={phoneOnClick}
                                    icon={<PhoneOutlined />}
                                >
                                    {t('phoneButton')}
                                </Button>
                                {
                                    GetQuickReply()
                                }
                                {
                                    GetPhoneCta()
                                }
                                {
                                    GetUrlCta()
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Submit Button */}
                <Row style={{ marginTop: '20px' }} justify={'end'}>
                    <Col span={4}>
                        {/* <Button size="large" onClick={() => {
                            handleSubmit();
                        }} type="primary" icon={<PlusCircleOutlined />} block>
                            {t('create')}
                        </Button> */}

                        <ReusableButton
                            variant="campaign"
                            text={t('createTemplateText')}
                            onClick={handleSubmit}
                            // href={"templates"}
                            icon={<PlusCircleOutlined />}
                        />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
}


export default EditTemplate