import React from 'react';
import { Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const TemplateSelectBox = ({
  title,
  description,
  value,
  onChange,
  languageData = [],
  infoMessage,
  subDesc,
  isRequired = false, // Yeni parametre: Required ya da Optional
  disabled=false
}) => {
  const {t,i18n}=useTranslation();
  return (
    <div style={{
      marginBottom: '20px',
      fontFamily: 'Arial, sans-serif',
      padding: '20px',
      border: '1px solid #d9d9d9',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
          height:'90%'
    }}>
      {/* Header with Info Icon and Required/Optional Badge */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between', // Başlık ve icon arasını ayırmak için
        alignItems: 'center', // Başlık ve badge'i ortalamak için
        marginBottom: '8px',
      }}>
        <div style={{
          display: 'flex',
          // alignItems: 'center', // Başlık ve badge'i dikeyde ortalamak için
        }}>
          <h3 style={{
            fontSize: '18px',
            fontWeight: '600',
            color: '#333',
            fontFamily: 'Helvetica, sans-serif',
            marginRight: '10px',
          }}>
            {title}
          </h3>

          {/* Required/Optional Badge */}
          {isRequired ? (
            <div style={{
              padding: '2px 6px',
              backgroundColor: '#E6F7FF',
              borderRadius: '6px',
              fontSize: '14px',
              color: '#1890ff',
              fontWeight: '500',
              marginRight: '10px', // Badge ve info icon arasında boşluk
              height: '25px',
              display: 'flex',
              alignItems: 'center'
            }}>
              {t('required')}
            </div>
          ) : (
            <div style={{
              padding: '2px 6px',
              backgroundColor: '#FFF6E6',
              borderRadius: '6px',
              fontSize: '14px',
              color: '#000010',
              fontWeight: '500',
              marginRight: '10px', // Badge ve info icon arasında boşluk
              height: '25px',
              display: 'flex',
              alignItems: 'center'
            }}>
              {t('optional')}
            </div>
          )

          }
        </div>
        {
          infoMessage && infoMessage.length >0 &&(
            <Tooltip title={infoMessage}>
            <InfoCircleOutlined style={{ color: '#1890ff', fontSize: '18px' }} />
          </Tooltip>
          )
        }
      </div>

      {/* Açıklama */}
      <p style={{
        fontSize: '14px',
        color: '#595959',
        marginBottom: '16px',
        fontFamily: 'Verdana, sans-serif'
      }}>
        {description}
      </p>

      {/* Select alanı */}
      <div style={{ marginBottom: '12px' }}>
        <Select
        disabled={disabled}
             value={value}
             onChange={onChange}
             style={{
               width: '100%',
               borderRadius: '8px',
              //  border: '1px solid #d9d9d9',
               boxSizing: 'border-box',
               fontFamily: 'Arial, sans-serif',
               fontSize: '14px',
               color: '#333',
               backgroundColor: '#fff', // Beyaz arka plan
               transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
             }}
             dropdownStyle={{
               borderRadius: '8px',
               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', // Dropdown için daha belirgin gölge
               maxHeight: '300px', // Uzun listelerde max yükseklik
               overflowY: 'auto', // Uzun listelerde kaydırma
             }}
        >
          {languageData.map((item) => (
            <Option key={item.key} value={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
      </div>

      {/* Info Bilgisi */}
      {
        subDesc && subDesc.length > 0 &&(
          <div style={{ color: '#595959', fontSize: '12px', fontFamily: 'Verdana, sans-serif' }}>
          {subDesc}
        </div>
        )
      }
    
    </div>
  );
};

export default TemplateSelectBox;
