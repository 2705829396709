import React, { Component, useEffect, useState } from 'react'
import network from 'network/network';
import { Form, notification, Tooltip, Collapse, Button, message, Steps, theme, Select, Row, Col, Tag, Table, Checkbox, Card, Input, Space, Descriptions, Badge, Spin, Modal, Typography, List, Radio } from 'antd';
import { SendOutlined, CloseOutlined, CheckOutlined, EditOutlined, UnorderedListOutlined, AlignCenterOutlined, EyeOutlined, CopyOutlined, FontSizeOutlined, CheckCircleFilled, UsergroupAddOutlined, CheckCircleOutlined, ExclamationCircleOutlined, SearchOutlined, RollbackOutlined, ArrowRightOutlined, UserOutlined, PlusCircleOutlined, FileTextOutlined, SaveOutlined, LoadingOutlined, FormOutlined, BugOutlined, SyncOutlined } from '@ant-design/icons';
import './Steps.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import Flex from 'components/shared-components/Flex';
const { Text, Link } = Typography;


const { Panel } = Collapse;
const { Option } = Select;


const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={'blue'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};

const customerGroupTypes = {
    customerGroups: 1,
    eventCustomers: 2,
    standCustomers: 3
}

const CreateCampaigns = (props) => {

    const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />
    const { t, i18n } = useTranslation();
    const [buttonVariable, setButtonVariable] = useState('')
    const [fromData, setFromData] = useState([])
    const [toData, setToData] = useState(new Array())
    const [eventCustomerList, setEventCustomerList] = useState([])
    const [standCustomerList, setStandCustomerList] = useState([])
    const [parameterData, setParameterData] = useState([])
    const [templatesData, setTemplatesData] = useState([]);
    const [variable, setVariable] = useState(new Array());
    const [input, setInput] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [inputEnable, setInputEnable] = useState(true);
    const [testPhone, setTestPhone] = useState();
    const [rawPhone, setRawPhone] = useState();

    // const [sendMessage, setSendMessage] = useState("");
    const [campaignState, setCampaignState] = useState({
        campaignName: "",
        campaignFrom: "",
        campaignTo: [],
        campaignToName: [],
        chooseTemplateId: "",
        chooseTemplateName: "",
        chooseTemplateMessage: "",
        chooseTemplateType: "",
        chooseTemplateDynamicCount: "",
        chooseTemplateDynamicButtonCount: "",
        chooseTemplateButtonsData: [],
        chooseTemplateContent: "",
        mediaId: "",
        campaignEventCustomers: [],
        campaignEventCustomersName: [],
        campaignStandCustomers: [],
        campaignStandCustomersName: [],
    });
    const [searchValue, setSearchValue] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState(templatesData);
    const panelErrorStyle = {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "red ",
        borderRadius: "5px",
        marginTop: "1px",
    }
    const panelInfoStyle = {

    }
    const [fromValidation, setFromValidation] = useState(false);
    const [toValidation, setToValidation] = useState(false);
    const [chooseTemplateValidation, setChooseTemplateValidation] = useState(false);
    const [contentValidation, setContentValidation] = useState(false);
    const [campaignNameValidation, setCampaignNameValidation] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [loadingPanel, setLoadingPanel] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [totalCredit, setTotalCredit] = useState(0);
    const [selectedCustomerGroupType, setSelectedCustomerGroupType] = useState(customerGroupTypes.customerGroups);

    const navigate = useNavigate();
    useEffect(() => {
        getAllTemplate();
        responseFromData();
        selectTotalCredit();
    }, [])
    useEffect(() => {

        setCampaignState({
            ...campaignState,
            campaignEventCustomers: [],
            campaignEventCustomersName: [],
            campaignStandCustomers: [],
            campaignStandCustomersName: [],
            campaignTo: [],
            campaignToName: [],
            campaignEventCustomers: [],
            campaignEventCustomersName: [],
            campaignStandCustomers: [],
            campaignStandCustomersName: [],
        });
    }, [selectedCustomerGroupType]);

    const selectTotalCredit = async () => {

        const result = await network.get("/admin/campaign/total-credit");
        if (result.isSuccess) {
            setTotalCredit(result.data.creditCount);
        }

    }

    const isEventApp = () => {
        const selectedApp = JSON.parse(localStorage.getItem("selectedApp") || "{}")
        if (selectedApp && selectedApp.appName == "event") {
            return true;
        }
        return false;
    }
    const isEvent = isEventApp();

    const changeButtonEdit = () => {

        if (campaignState?.chooseTemplateDynamicButtonCount == 0) {
            let regex = /{{(\d+)}}/g;
            let text = campaignState.chooseTemplateButtonsData[0]
            let matches = String(text).match(regex);
            let button = [];
            let srtButton = "";
            let bracketop = "{{"
            let bracketcl = "}}"

            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {

                    const number = parseInt(matchValue.match(/\d+/)[0], 10);

                    const matchIndex = text.indexOf(matchValue);

                    const outsideText = text.slice(currentIndex, matchIndex);

                    button.push((<span >{outsideText}</span>));
                    srtButton += outsideText;


                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));

                    button.push((<span className=" text-danger  font-size-md">{bracketop}test{bracketcl}</span>));
                    srtButton += bracketop + buttonVariable + bracketcl
                    currentIndex = matchIndex + matchValue.length;

                });

                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);
                    if (remainingText) {
                        button.push((<span>{remainingText}</span>));
                        srtButton += remainingText
                    }
                }
            }
            else {
                if (text) {
                    // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
                    button.push((<span>{text}</span>));
                    srtButton += text
                }
            }
            return srtButton;
        }
    }

    const contentButton = () => {
        if (campaignState?.chooseTemplateDynamicButtonCount != 0) {//parametre varsa
            let regex = /{{(\d+)}}/g;
            let text = campaignState.chooseTemplateButtonsData[0]
            let matches = String(text).match(regex);
            let component = [];

            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {
                    const number = parseInt(matchValue.match(/\d+/)[0], 10);
                    const matchIndex = text.indexOf(matchValue);

                    const outsideText = text.slice(currentIndex, matchIndex);
                    component.push((<span >{outsideText}</span>));



                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));


                    component.push((<span className=" text-danger  font-size-md"><Input onChange={inputChange} style={{
                        width: "200px",
                        height: "30px",
                        marginTop: "10px",
                        marginBottom: "15px",
                        marginLeft: "5px",
                        marginRight: "5px",

                    }}></Input></span>));

                    currentIndex = matchIndex + matchValue.length;

                });

                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);

                    component.push((<span>{remainingText}</span>));
                }
            }
            else {
                // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.

                component.push((<span>{text}</span>));
            }
            return (
                <div >
                    <Col className='d-flex align-items-center justify-content-center'> <b>Buton İçeriği</b>
                    </Col>
                    <div className=" ml-4 p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                        {component}
                    </div>
                </div>
            )
        }

    }
    const removeBrackets = (text) => {
        return text.replace(/[\[\]]/g, "");
    };
    const phoneButton = () => {
        if (campaignState?.chooseTemplateDynamicButtonCount != 0) {//değişken varsa 
            let regex = /{{(\d+)}}/g;

            let text = campaignState.chooseTemplateButtonsData[0]

            text = text.substring(2, text.length - 1);

            let matches = String(text).match(regex);
            let content = [];
            let srtButton = "";
            const words = String(text).split(',')
            // content.push(words[0]);
            // content.push(words[1]);

            if (matches) {
                let currentIndex = 0;
                matches.forEach(matchValue => {
                    const number = parseInt(matchValue.match(/\d+/)[0], 10);
                    const matchIndex = text.indexOf(matchValue);
                    const outsideText = text.slice(currentIndex, matchIndex);
                    content.push((<span >{outsideText}</span>));
                    srtButton += outsideText;
                    // component.push((<span className=" text-danger  font-size-md" >{buttonVariable}</span>));
                    content.push((<span className=" text-danger  font-size-md">test</span>));
                    srtButton += buttonVariable;
                    currentIndex = matchIndex + matchValue.length;
                });


                // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
                if (currentIndex < text.length) {
                    const remainingText = text.slice(currentIndex);
                    content.push((<span>{remainingText}</span>));
                    srtButton += remainingText;

                }
            }
            else {
                // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
                content.push((<span>{text}</span>));
                srtButton += text;


            }
            const word = String(srtButton).split(',');

            return (
                <Tooltip placement="bottom" color={""} title={word[1]}>
                    <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                        <a >{word[0]}</a>
                    </div>
                </Tooltip>
            )
        }
        else {//değişken yoksa
            let text = campaignState.chooseTemplateButtonsData

            const response = text.map(item => {


                const text = removeBrackets(item);
                const words = String(text).split(',')
                if (words.length > 1)//Linkli olan
                {

                    return <Tooltip placement="bottom" title={words[1]}>
                        <div className=' mr-2 p-1 d-flex align-items-center justify-content-center' style={{ background: "#d3eebe", borderRadius: 5 }} >
                            <a>{words[0]}</a>
                        </div>
                    </Tooltip>
                }
                else//c2a buton
                {
                    return (
                        <div className="hover-button mt-2 mr-3 ml-2 " style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ fontWeight: "bold" }}>{text}</span>
                        </div>
                    );
                }

            })
            return response

        }
    }
    const getCampaignParameter = (parameterId) => {

        var index = parameterData.findIndex(w => w.parameterid == parameterId);


        return parameterData[index]?.parameterid
    }
    const getCampaignParameterName = (parameterId) => {

        var index = parameterData.findIndex(w => w.parameterid == parameterId);


        return parameterData[index]?.parametername
    }
    const getCampaignTo = (groupId) => {

        var index = toData.findIndex(w => w.groupid == groupId);
        return toData[index].groupname
    }
    const getCampaignEventName =(eventId)=>{

        var index = eventCustomerList.findIndex(w => w.eventId == eventId);
        return eventCustomerList[index]?.eventName
    }
    const getCampaignGrupTotalCount = () => {
        var count = 0;
        if (selectedCustomerGroupType == customerGroupTypes.eventCustomers) {
            campaignState.campaignEventCustomers.map((item, index) => {
                var index = eventCustomerList.findIndex(w => w.eventId == item);
                count += eventCustomerList[index].customerCount;
            });
        } else {
            campaignState.campaignTo.map((item, index) => {
                var index = toData.findIndex(w => w.groupid == item);
                count += toData[index].customercount;
            });
        }

        return count;
    }

    const handleContentChange = (i, value) => {
        let tmpVariable = [...variable];
        tmpVariable[i] = value;

        setVariable(tmpVariable);
        // try {
        //     setCampaignState({
        //         ...campaignState,
        //         chooseTemplateContent: value,
        //     })
        //     variable[i]:value;
        // } catch (error) {
        //     alert(error);
        // }


    };
    // const handleButtonContentChange = (i, label) => {
    //     setButtonVariable(label);
    // };
    const formatPhoneNumber = (phone) => {
        let cleaned = phone.replace(/\D/g, '');

        if (cleaned.length <= 10) {
            cleaned = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        }

        return cleaned;
    };
    const testPhoneChange = (e) => {
        const value = e.target.value;
        const formattedPhone = formatPhoneNumber(value);
        setTestPhone(formattedPhone);

        // Sadece sayıları gönderilecek format
        const rawValue = value.replace(/\D/g, '');
        setRawPhone(rawValue);  // Burada rawPhone yalnızca sayıları tutuyor
        setTestPhone(value);

        // Sadece sayıları gönderilecek format
        // const rawValue = value.replace(/\D/g, '');
        setRawPhone(value);  // Burada rawPhone yalnızca sayıları tutuyor
    };
    const sendTestTemplateMessage = async () => {
        setLoadingPanel(true);
        var params = {
            campaignName: campaignState.campaignName,
            firmBusinessNumberId: getCampaignRow().firmbusinessnumberid,
            firmBusinessNumber: getCampaignRow().businessnumber,
            groupIds: campaignState.campaignTo,
            templateId: campaignState.chooseTemplateId,
            templateType: campaignState.chooseTemplateType,
            message: sendMessage(),
            parameterIds: variable.length == 0 ? null : variable,
            campaignStatus: 1,
            buttonValue: buttonVariable,
            testPhone: rawPhone,
            mediaId: campaignState.mediaId
        }
        var result = await network.post("/TemplateMessage/SendTestTemplateMessage", params);
        setLoadingPanel(false);
        if (result.isSuccess) {
            var postResult = result;
            if (postResult.isSuccess) {
                message.success(t('messageSentSuccessfully'));
            } else {
                alert(postResult.errorMessage);
            }

        } else {
            alert(result.errorMessage);
        }

    }
    const ConfirmationModal = ({ visible, onClose, senderNumber, groups, messageContent }) => {
        return (
            <Modal
                centered
                visible={visible}
                onOk={onClose}
                onCancel={onClose}
                footer={null}
            >
                {/* Gönderen Numara */}
                <div style={{ marginBottom: "15px" }}>
                    <h2 className='ant-typography' strong style={{ color: "#555555" }}>
                        {t('campaignSendConfirmationTitle')}
                    </h2>
                </div>

                {/* Gönderilecek Gruplar */}
                <div style={{ marginBottom: "15px" }}>

                    <Text style={{
                        fontSize: 17
                    }}>
                        {t("campaignSendConfirmationFirst")} <span style={{ color: "#2a59d1", fontWeight: "bold" }} >{getCampaignGrupTotalCount()}</span> {t("campaignSendConfirmationSecond")}
                    </Text>

                    <div style={{ marginBottom: "15px", marginTop: 15 }} >

                        <span style={{ color: 'gray', marginBottom: 10 }}> {t('targetGroups')} </span>
                    </div>
                    {groups.map((item) => {
                        return (
                            <Tag className="m-1" color={"blue"} key={item.value}>
                                <div style={{ fontSize: "1rem", color: "#1890ff" }}>
                                    {item.label}
                                </div>
                            </Tag>
                        );
                    })}
                </div>

                {/* Mesaj İçeriği */}
                <div className='mt-5'>
                    <ReusableButton
                        variant="campaign"
                        text={t('send')}
                        onClick={() => {
                            controlCredit();
                        }}
                        icon={<SendOutlined />}
                    />
                </div>

            </Modal>
        );
    };

    const getCampaignParams = () => {
       
        let groupIds = campaignState.campaignTo;
        let groupType = customerGroupTypes.customerGroups;
        if (selectedCustomerGroupType == customerGroupTypes.eventCustomers) {
            if (campaignState.campaignStandCustomers?.length>0) {
                groupType = customerGroupTypes.standCustomers;
                groupIds = campaignState.campaignStandCustomers;
            }else {
                groupType= customerGroupTypes.eventCustomers;
                groupIds = campaignState.campaignEventCustomers
            }
           
        }
        var params = {
            campaignName: campaignState.campaignName,
            firmBusinessNumberId: getCampaignRow().firmbusinessnumberid,
            firmBusinessNumber: getCampaignRow().businessnumber,
            groupIds: groupIds,
            templateId: campaignState.chooseTemplateId,
            templateType: campaignState.chooseTemplateType,
            message: sendMessage(),
            parameterIds: variable.length == 0 ? null : variable,
            campaignStatus: 1,
            buttonValue: buttonVariable,
            mediaId: campaignState.mediaId, 
            campaignGroupType : groupType
        }
        return params;
    }
    const controlCredit = async () => {
        setModalVisible(false);
        setLoadingPanel(true);
        const params = getCampaignParams();
        var result = await network.post("/admin/Campaign/control-packages", params);

        if (result.isSuccess) {
            sendCampaign();
        } else {
            message.error(t("creditOverflowApiError"))
        }
        setLoadingPanel(false);


    }
    const sendCampaign = async () => {

        // setLoadingPanel(true);

        const params = getCampaignParams();
        // var result = 
        network.post("/TemplateMessage/SendTemplateMessageAndCreateCampaign", params);

        message.success(t('messageSentSuccessfully'));
        setTimeout(() => {
            navigate('/campaigns')
        }, 250);
    }
    const onFinish = async () => {
        setLoadingPanel(true);
        var error = false;
        setCampaignNameValidation(false);
        setFromValidation(false);
        setToValidation(false);
        setChooseTemplateValidation(false);
        setContentValidation(false);


        if (campaignState.campaignName == "") {
            setCampaignNameValidation(true);
            setInput(true);
            error = true;


        }
        if (getCampaignRow().firmbusinessnumberid == undefined) {
            setFromValidation(true);
            error = true;


        }
        if (campaignState.chooseTemplateType == customerGroupTypes.customerGroups && campaignState.campaignTo.length == 0) {
            setToValidation(true);
        }else if (campaignState.chooseTemplateType == customerGroupTypes.eventCustomers && campaignState.campaignEventCustomers.length == 0) {
            setToValidation(true);
        } 
        if (campaignState.chooseTemplateId == "") {
            setChooseTemplateValidation(true);
            error = true;
        }

        if (error == true) {
            api['error']({
                message: t('campaignNotSendError'),
                description:
                    t('campaignNotSendError2'),
            });

            setLoadingPanel(false);

        } else {
            setLoadingPanel(false);
            setModalVisible(true);
        }
    }
    const DynamicSelectList = (value) => {
        let list = [];
        const index = campaignState.chooseTemplateDynamicCount ? campaignState.chooseTemplateDynamicCount : 0;
        for (let i = 0; i < index; i++)
            list.push(
                <Select
                    style={{
                        width: "130px",
                        height: "30px",
                        marginTop: "10px",
                        marginBottom: "15px"
                    }}
                    onChange={(value, label) => {
                        handleContentChange(i, value, label);
                    }}>
                    {parameterData.map((item, index) => (
                        <Option value={item.parameterid} >
                            {item.parametername}
                        </Option>
                    ))}
                </Select>

            );
        return list[value];
    };
    const getCampaignRow = () => {
        let index = fromData.findIndex(w => w.firmbusinessnumberid == campaignState.campaignFrom);

        if (index != -1) {
            return fromData[index];
        }
        return {};
    }


    const handleFromChange = (value, key) => {
        try {
            setCampaignState({
                ...campaignState,
                campaignFrom: value
            })

        } catch (error) {
            alert(error);
        }

    };
    const handleToChange = (value, label) => {

        try {
            setCampaignState({
                ...campaignState,
                campaignTo: value,
                campaignToName: label
            })

        } catch (error) {
            alert(error);
        }

    };
    const handleeventCustomerChange = (value, label) => {

        try {
            setCampaignState({
                ...campaignState,
                campaignEventCustomers: value,
                campaignEventCustomersName: label
            })

        } catch (error) {
            alert(error);
        }

    };
    const handleStandCustomerChange = (value, label) => {

        try {
            setCampaignState({
                ...campaignState,
                campaignStandCustomers: value,
                campaignStandCustomersName: label
            })

        } catch (error) {
            alert(error);
        }

    };
    const getAllTemplate = async () => {
        var result = await network.get("/TemplateMessage/GetAllActiveTemplate", false);
        if (!result.isSuccess) {
            message.error(result.errorMessage);
        }
        else {
            if (!result.data.isSuccess) {
                message.error(result.data.errorMessage);
            } else {
                setTemplatesData(result.data.data);
                setFilteredTemplates(result.data.data);
            }
        }
    }

    const responseFromData = async () => {

        var fromResult = await network.get("/admin/Firm/selectFirmNumberList", false);
        const fromData = fromResult.data.data;
        setFromData(fromData);
        setCampaignState({
            ...campaignState,
            campaignFrom: fromData[0].firmbusinessnumberid
        })
        var toResult = await network.get("/admin/Group/selectGroupList-campaign", false);


        const groupData = toResult.data.data;
        if (groupData) {
            const groupList = groupData.groupList;
            const customerCount = groupData.customerCount
            groupList.push({
                groupname: t("allReceiver"),
                groupdesc: t("allReceiver"),
                groupid: -1,
                status: true,
                customercount: customerCount
            });

            setToData(groupList);
        }
        if (isEvent) {
            const eventResult = await network.get("/admin/event/campaign-events");
            if (eventResult.isSuccess && eventResult.data) {
                const eventData = eventResult.data;
                setEventCustomerList(eventData.eventCustomerList);
                setStandCustomerList(eventData.standCustomerList);
            }
        }



        var paramaterResult = await network.get("/admin/Parameter/selectParameterList", false);

        const parameterData = paramaterResult.data.data;
        setParameterData(parameterData);

    }
    const inputChange = (e) => {
        if (e.target.value) {
            setButtonVariable(e.target.value)
        }
    };
    const onCampNameInput = (e) => {

        setInputEnable(true);

        setInputValue(e.target.value);

    };
    const CampNameClick = () => {
        setInput(true);

    };
    const InputClick = () => {
        setInput(false);

    };
    const InputTrueClick = () => {
        if (inputValue != "") {
            setInput(false);
        }
        setCampaignState({
            ...campaignState,
            campaignName: inputValue
        })
        // setCampaignName(inputValue)

        setInputEnable(false);
    };
    const getTemplateTypeStyle = (type) => {
        switch (type) {
            case 'TEXT':
                return { backgroundColor: '#f7faff', color: '#0078D4', padding: '5px 10px', borderRadius: '5px' }; // Hafif mavi ton
            case 'IMAGE':
                return { backgroundColor: '#fff7e6', color: '#fa8c16', padding: '5px 10px', borderRadius: '5px' }; // Turuncu ton
            case 'VIDEO':
                return { backgroundColor: '#fcefe6', color: '#d4380d', padding: '5px 10px', borderRadius: '5px' }; // Kırmızımsı ton
            default:
                return { backgroundColor: '#f0f0f0', color: '#595959', padding: '5px 10px', borderRadius: '5px' }; // Nötr gri
        }
    };
    const getLanguageStyle = (languageCode) => {
        switch (languageCode) {
            case 'en_GB':
                return { backgroundColor: '#e6f7ff', color: '#1890ff', padding: '5px 10px', borderRadius: '5px' };
            case 'tr':
                return { backgroundColor: '#fff7e6', color: '#fa8c16', padding: '5px 10px', borderRadius: '5px' };
            case 'tr_TR':
                return { backgroundColor: '#fff7e6', color: '#fa8c16', padding: '5px 10px', borderRadius: '5px' };
            case 'en_US':
                return { backgroundColor: '#f9f0ff', color: '#722ed1', padding: '5px 10px', borderRadius: '5px' };
            default:
                return { backgroundColor: '#f0f0f0', color: '#000', padding: '5px 10px', borderRadius: '5px' };
        }
    };
    const getCategoryStyle = (category) => {
        switch (category) {
            case 'MARKETING':
                return { backgroundColor: '#e6fffb', color: '#52c41a', padding: '5px 10px', borderRadius: '5px' };
            case 'UTILITY':
                return { backgroundColor: '#fff0f6', color: '#eb2f96', padding: '5px 10px', borderRadius: '5px' };
            case 'AUTHENTICATION':
                return { backgroundColor: '#f5f5f5', color: '#8c8c8c', padding: '5px 10px', borderRadius: '5px' };
            default:
                return { backgroundColor: '#f0f0f0', color: '#000', padding: '5px 10px', borderRadius: '5px' };
        }
    };
    const [columns, setColumns] = useState([
        {
            key: 1,
            title: t('templateName'),
            dataIndex: 'elementName',
            width: 100,
            colSpan: 1,
            render: (_, record) => (
                <div style={styles.cell}>
                    <div style={styles.text}>{record.elementName}</div>
                </div>
            )
        },
        {
            key: 2,
            title: t('templateType'),
            dataIndex: 'templateType',
            width: 100,
            render: (_, record) => {
                // Template tipine göre renklendirme
                const typeStyle = getTemplateTypeStyle(record.templateType);
                return (
                    <div style={styles.cell}>
                        <div style={{ ...styles.text, ...typeStyle }}>{record.templateType}</div>
                    </div>
                );
            }
        },
        {
            key: 2,
            title: <div style={{ textAlign: 'left', marginLeft: 8 }}>{t('messageContent')}</div>,
            dataIndex: 'data',
            width: 350,
            render: (_, record) => (
                <div style={styles.cell}>
                    <div style={{
                        backgroundColor: "#DCF8C6", // Gönderilen mesajlar için WhatsApp yeşili
                        borderRadius: "12px", // Yuvarlak köşeler
                        padding: "10px 15px", // Mesaj içeriği için boşluk
                        color: "#111111", // Yazı rengi
                        fontWeight: 400, // Yazı kalınlığı
                        maxWidth: "80%", // Mesajın genişliği
                        wordWrap: "break-word", // Uzun mesajları kır
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Hafif gölge
                        display: "inline-block", // İçeriği bir satırda tut
                        margin: "5px 0", // Mesaj balonları arası boşluk
                        alignSelf: "flex-end", // Gönderilen mesajı sağa hizala
                    }}>
                        {record.data}
                    </div>
                </div>
            )
        },
    ])
    const templateTypeFor = () => {

        if (campaignState.chooseTemplateType == "IMAGE") {
            return <div align="center" className='mb-1' >
                <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
            </div>
        }
        else if (campaignState.chooseTemplateType == "VIDEO")
            return <div align="center" className='mb-1' >
                <img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} />
            </div>
        else if (campaignState.chooseTemplateType == "LOCATION") {
            return <div><img style={{ borderRadius: 7 }} src="https://i.hizliresim.com/7kb2hsj.png" width={"100%"} height={"100%"} /></div>
        };

    };
    const CampaignInput = () => (
        <Row align="middle" justify="space-between" className='mb-4 mt-2'>
            <Col>
                <Row gutter={16} align="middle">
                    {/* Input */}
                    <Col>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                            style={{ marginBottom: 0 }}
                        >
                            <div>
                                <h3>{t('newCampaignName')}</h3>
                            </div>
                            <Col style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Row gutter={12}>
                                    <Input
                                        placeholder={t('enterCampaginName')}
                                        onChange={onCampNameInput}
                                        defaultValue={campaignState.campaignName}
                                        showCount
                                        maxLength={20}
                                        className={campaignNameValidation ? panelErrorStyle : ""}
                                        style={{ width: '300px', cursor: "text" }}
                                        disabled={!inputEnable}
                                    />
                                    <Col>
                                        {inputEnable ? (
                                            <Button onClick={() => InputTrueClick()}>
                                                {<CheckOutlined style={{ fontSize: "18px" }} />}
                                            </Button>
                                        ) : (
                                            <Button onClick={() => setInputEnable(true)}>
                                                <EditOutlined />
                                            </Button>
                                        )}
                                    </Col>
                                    <Col className='flex'>
                                        <ReusableButton
                                            variant="template"
                                            text={t('sendCampaign')}
                                            onClick={() => {
                                                onFinish();

                                            }}
                                            icon={<SendOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                        </Form.Item>
                    </Col>
                </Row>
            </Col>

        </Row>
    );


    const headerFromExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {campaignState.campaignFrom != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>{t('sender')}</h3>
                        </div>
                        <div>
                            {t('numberToSend')}
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className='mt-2 ml-1'>
                <Col span={1}>
                </Col >
                <Tag align="left" color="green">{getCampaignRow().friendlyname}</Tag>

            </Row>
        </div>
    );
    const headerToExtra = () => (
        <div>
            <Row >
                <Col span={1} className='mr-2' >
                    <div>
                        <Row className='ml-1 mt-2'>
                            {campaignState.campaignTo != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>


                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>{t('recipient')}</h3>
                        </div>
                        <div>
                            {t('whichGroupsSendMessage')}
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className="mt-2"  >
                <Col span={1}>
                </Col >
                <Col span={16}>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {selectedCustomerGroupType == customerGroupTypes.eventCustomers?
                        campaignState.campaignEventCustomers.map((item, index) => (
                            <Tag key={index} className="m-1" color={"blue"}>
                                {getCampaignEventName(item) }
                            </Tag>
                        )) :campaignState.campaignTo.map((item, index) => (
                            <Tag key={index} className="m-1" color={"blue"}>
                                {getCampaignTo(item)}
                            </Tag>
                        ))} 
                    </div>
                </Col>


            </Row>
        </div>

    );
    const headerChooseTempExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>

                            {campaignState.chooseTemplateMessage != "" ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}

                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>{t('messageTemplate')}</h3>
                        </div>
                        <div>
                            {t('templateInfo')}
                        </div>
                    </Col>
                </Row>

            </Row>
            <Row className='mt-2 ml-1'>
                <Col span={1}>
                </Col >
                <Tag align="left" color="yellow">{campaignState.chooseTemplateName}</Tag>
            </Row>
        </div>

    );
    const headerContentExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {(campaignState.chooseTemplateId != "" && variable.length == campaignState.chooseTemplateDynamicCount) ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>{t('content')}</h3>
                        </div>
                        <div>
                            {t('designContentMessage')}
                        </div>
                    </Col>
                </Row>

            </Row>


        </div>

    );
    const testContentExtra = () => (
        <div>
            <Row>
                <Col span={1} className='mr-2'>
                    <div>
                        <Row className='ml-1 mt-2'>
                            {(campaignState.chooseTemplateId != "" && variable.length == campaignState.chooseTemplateDynamicCount) ? <CheckCircleFilled style={{ fontSize: "24px", color: 'black' }} /> : <CheckCircleFilled style={{ fontSize: "24px", color: '#dbd9d2' }} />}
                        </Row>
                    </div>
                </Col>
                <Row>
                    <Col >
                        <div>
                            <h3>{t('testMessage')}</h3>
                        </div>
                        <div>
                            {t('testMessageInfo')}
                        </div>
                    </Col>
                </Row>

            </Row>


        </div>

    );
    const genFromExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>

                <UserOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );

    const isOverflowCredit = () => {

        const totalRecipent = getCampaignGrupTotalCount();
        if (totalRecipent > 0 && (totalRecipent > totalCredit)) {
            return true;
        }
        return false;
    }

    const genToExtra = () => {

        const isCreditOverflow = isOverflowCredit();
        let overflowColorStyle = {
        }
        if (isCreditOverflow) {
            overflowColorStyle = {
                color: "red"
            }
        }

        return (
            <Col className='mt-3 mr-3' style={{}}>
                <Row style={{ marginBottom: 20, justifyContent: 'end', alignItems: 'center' }}>
                    <UsergroupAddOutlined style={{ fontSize: "24px" }} />
                </Row>
                <Row>

                </Row>
                <Row className='' style={{ marginTop: 10, justifyContent: 'end', alignItems: 'center' }}>
                    <div
                        style={{
                            fontStyle: "normal",
                            color: "#000000", // Siyah yazı rengi
                            fontWeight: "600",
                            padding: "6px 8px",
                            fontSize: 16,
                            ...overflowColorStyle
                        }}
                    >
                        {t('totalBuyerCount')}:{" "}
                        <span className='wa-color' style={{ fontWeight: "bold", ...overflowColorStyle }}>
                            {getCampaignGrupTotalCount()}

                        </span>

                    </div>
                </Row>
                {isCreditOverflow && (
                    <Tag color='error'>
                        {t("creditOverflow").replace("{total}", totalCredit)}
                    </Tag>
                )}
            </Col>
        )
    };
    const genChooseTempExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>
                <UnorderedListOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const genContentExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>
                <FontSizeOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const genTestContentExtra = () => (
        <div>
            <Row className='mt-3 mr-3'>
                <SendOutlined style={{ fontSize: "24px" }} />
            </Row>
        </div>

    );
    const sendMessage = () => {
        let regex = /{{(\d+)}}/g;
        let text = campaignState.chooseTemplateMessage;
        let matches = text.match(regex);
        let message = [];
        let bracketop = "{{"
        let bracketcl = "}}"
        let strMessage = "";
        let i = 0;
        if (matches) {
            let currentIndex = 0;

            matches.forEach(matchValue => {

                const number = parseInt(matchValue.match(/\d+/)[0], 10);

                const matchIndex = text.indexOf(matchValue);

                const outsideText = text.slice(currentIndex, matchIndex);
                strMessage += outsideText + bracketop + getCampaignParameter(variable[number - 1]) + bracketcl;

                message.push((<span >{outsideText}</span>));
                message.push((<span className=" text-danger font-size-md">{bracketop}{getCampaignParameter(1)}{bracketcl}</span>));
                currentIndex = matchIndex + matchValue.length;

            });

            // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
            if (currentIndex < text.length) {
                const remainingText = text.slice(currentIndex);
                if (remainingText) {
                    strMessage += remainingText;
                    message.push((<span>{remainingText}</span>));
                }
            }
        } else {
            // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
            message.push((<span>{text}</span>));
            strMessage += text;
        }
        message.push(changeButtonEdit())
        strMessage += changeButtonEdit();

        return strMessage;

    };
    const messageEdit = (value) => {

        let regex = /{{(\d+)}}/g;
        let text = campaignState.chooseTemplateMessage;
        let matches = text.match(regex);
        let component = [];
        let content = [];
        if (matches) {
            let currentIndex = 0;

            matches.forEach(matchValue => {

                const number = parseInt(matchValue.match(/\d+/)[0], 10);

                const matchIndex = text.indexOf(matchValue);

                const outsideText = text.slice(currentIndex, matchIndex);

                component.push((<span >{outsideText}</span>));
                content.push((<span >{outsideText}</span>));
                // component.push((<span className="py-1 text-danger font-size-lg" >{buttonVariable}</span>));
                // if (variable[number - 1] == null) {
                component.push((<span className=" text-danger  font-size-md">{getCampaignParameterName(variable[number - 1])}</span>));
                content.push((<span className=" m-1 text-danger font-size-md">
                    {(DynamicSelectList(number - 1))}
                </span>));
                // }
                currentIndex = matchIndex + matchValue.length;

            });

            // Eğer metin, son eşleşme sonrasında devam ediyorsa, kalan kısmı da ekrana yazdır
            if (currentIndex < text.length) {
                const remainingText = text.slice(currentIndex);
                component.push((<span>{remainingText}</span>));
                content.push((<span>{remainingText}</span>));
            }
        } else {
            // Eğer eşleşme bulunamadıysa, metnin tamamını dışındaki metin olarak kabul edebilirsiniz.
            component.push((<span>{text}</span>));
            content.push((<span>{text}</span>))

        }

        return value ? component : content;

    };
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);

        setFilteredTemplates(
            templatesData.filter((template) =>
                template.elementName.toLowerCase().includes(value)
            )
        );
    };

    const getStandCustomers =()=>{
        const selectedEvent = campaignState.campaignEventCustomers;
        const result = standCustomerList.filter(customer => 
            selectedEvent.indexOf(customer.eventId) != -1 
          );
          console.log(selectedEvent);
          return result;
    }

    const customerSelectArea = () => {

        if (selectedCustomerGroupType == customerGroupTypes.eventCustomers) {

            const eventCustomers = getStandCustomers();
            return (
                <Flex>
                    <Select
                        mode="multiple"
                        onChange={handleeventCustomerChange}
                        tagRender={tagRender}
                        style={{
                            width: '100%',
                        }}>
                        {eventCustomerList.map((item, index) => (
                            <Option value={item.eventId} label={item.eventName} >
                                {item.eventName + " (" + item.customerCount + ")"}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        mode="multiple"
                        onChange={handleStandCustomerChange}
                        tagRender={tagRender}
                        style={{
                            width: '100%',
                        }}>
                        {eventCustomers.map((item, index) => (
                            <Option value={item.standId} label={item.standName} >
                                {item.standName + " (" + item.customerCount + ")"}
                            </Option>
                        ))}
                    </Select>

                </Flex>

            );
        } else {
            return (
                <Select
                    mode="multiple"
                    onChange={handleToChange}
                    tagRender={tagRender}
                    style={{
                        width: '100%',
                    }}>
                    {toData.map((item, index) => (
                        <Option value={item.groupid} label={item.groupname} >
                            {item.groupname + " (" + item.customercount + ")"}
                        </Option>
                    ))}
                </Select>
            );
        }


    }



    return (
        <>
            <Spin spinning={loadingPanel} indicator={Icon} >
                <div >
                    {contextHolder}
                    <Row>
                        <Col span={18}>
                            <Card>
                                <Row >
                                    {CampaignInput()}

                                </Row>
                                <Col>
                                    <div>
                                        <Collapse
                                            defaultActiveKey={['2']}
                                            // onChange={onChangeAcco}
                                            expandIconPosition={'start'}
                                            accordion>
                                            <Panel key="1" style={(fromValidation) ? panelErrorStyle : panelInfoStyle} header={headerFromExtra()} extra={genFromExtra()} showArrow={false} >
                                                <Row className='mt-4'>
                                                    <Col span={1}></Col>
                                                    <Col className='' span={7}>
                                                        <div align="left" style={{
                                                            width: "100%",
                                                        }} className=' mt-2' ><h5>{t('senderNumber')}: </h5> </div>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Select
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            value={campaignState.campaignFrom}
                                                            onChange={
                                                                handleFromChange}>
                                                            {fromData.map((item, index) => (
                                                                <Option key={item.firmbusinessnumberid} value={item.firmbusinessnumberid} >
                                                                    {item.friendlyname + "  (" + item.businessnumber + ")"}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                            <Panel key="2" style={(toValidation) ? panelErrorStyle : panelInfoStyle} header={headerToExtra()} extra={genToExtra()} showArrow={false} >

                                                <Row className='mt-4'>
                                                    <Col span={1}></Col>
                                                    <Col span={7} className=''>
                                                        <div align="left" className='mt-2'><h5>{t('targetGroups')}: </h5> </div>
                                                    </Col>

                                                    <Col span={10}>
                                                        {isEvent && (
                                                            <div className='mb-4'>
                                                                <Radio.Group defaultValue={1}
                                                                    onChange={(event) => setSelectedCustomerGroupType(event.target.value)}
                                                                    options={[
                                                                        { value: customerGroupTypes.customerGroups, label: t('customerGroups') },
                                                                        { value: customerGroupTypes.eventCustomers, label: t('eventCustomers') },
                                                                    ]}
                                                                >
                                                                </Radio.Group>
                                                            </div>
                                                        )}

                                                        {customerSelectArea()}
                                                    </Col>
                                                </Row>

                                            </Panel>
                                            <Panel key="3" style={(chooseTemplateValidation) ? panelErrorStyle : panelInfoStyle} header={headerChooseTempExtra()} extra={genChooseTempExtra()} showArrow={false} >
                                                <div>
                                                    <Row>
                                                        <Col span={9} className=' mt-3 pl-3'>
                                                            <div className="mr-md-3 ">
                                                                <Input placeholder={t("searchTemplate")} onChange={handleSearch} prefix={<SearchOutlined />} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="table-responsive  p-3">
                                                        <Table
                                                            pagination={{ pageSize: 6 }}
                                                            columns={columns}
                                                            dataSource={filteredTemplates}
                                                            rowKey='id'
                                                            rowSelection={
                                                                {
                                                                    type: 'radio',
                                                                    columnWidth: 48,
                                                                    onSelect: (record) => {
                                                                        { setVariable("") }

                                                                        try {

                                                                            setCampaignState({
                                                                                ...campaignState,
                                                                                chooseTemplateId: record.id,
                                                                                chooseTemplateMessage: record.headData,
                                                                                chooseTemplateName: record.elementName
                                                                                ,
                                                                                chooseTemplateDynamicCount: record.dynamicCount,
                                                                                chooseTemplateDynamicButtonCount: record.dynamicButtonCount,
                                                                                chooseTemplateButtonsData: record.buttonsData,
                                                                                chooseTemplateType: record.templateType,
                                                                                mediaId: record.mediaId
                                                                            })
                                                                        } catch (error) {
                                                                            alert(error);
                                                                        }


                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Panel>
                                            <Panel key="4" style={(contentValidation) ? panelErrorStyle : panelInfoStyle} header={headerContentExtra()} extra={genContentExtra()} showArrow={false} >
                                                <div>
                                                    <Row className=' d-flex align-items-center justify-content-center'>
                                                        {/* <Col className='d-flex align-items-center justify-content-center'> <b>Mesaj İçeriği</b> */}
                                                        {/* </Col> */}
                                                        <Col span={22} className='ml-2'>
                                                            <div className=" mt-1 ml-4 mb-4   p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                                                                {/* {component} */}
                                                                {/* {content} */}
                                                                {messageEdit(false)}
                                                            </div>
                                                        </Col>

                                                        <Col span={22} className='ml-2'>

                                                            {contentButton()}
                                                        </Col>
                                                        {/* <Col span={22} className='ml-2'>
                                                    <div className=" ml-4 mb-5 pt-0  p-4" style={{ background: "#e8e8e8", borderRadius: 10 }}>
                                                       
                                                        {changeButton(false)}
                                                    </div>
                                                </Col> */}
                                                    </Row>


                                                </div>
                                            </Panel>
                                            <Panel key="5" style={(contentValidation) ? panelErrorStyle : panelInfoStyle} header={testContentExtra()} extra={genTestContentExtra()} showArrow={false} >
                                                <div>
                                                    <Row gutter={24} align="middle">
                                                        <Col span={12} style={{ textAlign: "left" }}>
                                                            <strong style={{ color: "#555555", fontStyle: "italic", fontSize: "0.9rem" }}>
                                                                {t('phoneNumberInstruction')}
                                                            </strong>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <Input
                                                                    value={testPhone}
                                                                    onChange={testPhoneChange}
                                                                    type="tel"
                                                                    pattern="[0-9][0-9][0-9]{10}"
                                                                    maxLength={12}
                                                                    placeholder={t('enterPhoneNumber')}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <ReusableButton
                                                                variant="campaign"
                                                                text={t('sendTestMessage')}
                                                                onClick={sendTestTemplateMessage}
                                                                icon={<SyncOutlined />}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Col style={{ position: "fixed" }} className='pl-2 ml-4 mt-2' span={7}>
                                <div style={{ backgroundColor: "#e5ddd5", alignItems: 'center', justifyContent: 'center' }} className="smartphone">
                                    <br />
                                    <Row>
                                        <Col span={2}></Col>
                                        <Col span={22}>
                                            <div
                                                className="mt-4 mr-2 p-2  content"
                                                style={{
                                                    background: "#d3eebe",
                                                    borderRadius: 10,
                                                    alignItems: 'center', justifyContent: 'center'
                                                }}
                                            >
                                                {templateTypeFor()}
                                                <div className="p-1">{messageEdit(true)}</div>
                                            </div>
                                            <div className="my-1"></div>
                                            <Col>{phoneButton()}</Col>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>


                            </Col>
                        </Col>
                    </Row>
                </div>
            </Spin>
            {
                modalVisible && (
                    <ConfirmationModal
                        visible={modalVisible}
                        onClose={() => setModalVisible(false)}
                        senderNumber={campaignState.campaignFrom}
                        groups={selectedCustomerGroupType == customerGroupTypes.customerGroups? campaignState.campaignToName : (campaignState.campaignStandCustomersName?.length>0?campaignState.campaignStandCustomersName:campaignState.campaignEventCustomersName)}
                        messageContent={campaignState.chooseTemplateMessage}
                        
                    />)
            }

        </>

    );

}
const styles = {
    table: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginTop: '20px',
    },
    cell: {
        padding: '10px',
        textAlign: 'left',
        fontSize: '14px',
        color: '#333',
    },
    cell2: {
        padding: '10px',
        textAlign: 'left',
        fontSize: '14px',
        color: '#333',
    },
    text: {
        fontWeight: '500',
    },
    header: {
        backgroundColor: '#f5f5f5',
        fontWeight: '600',
        color: '#1890ff',
    },
    radioButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    // Row hover style
    rowHover: {
        backgroundColor: '#f0faff',
    },
    selectedRow: {
        backgroundColor: '#e6f7ff',
    },
    // Header styles
    headerTitle: {
        backgroundColor: '#1890ff',
        color: '#fff',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '16px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },
};


export default CreateCampaigns