import { UploadOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { Button, Card, Image, message, Table, Upload } from "antd";
import Title from "antd/es/skeleton/Title";
import { handleInput } from "concurrently/src/defaults";
import { API_FULL_URL, API_BASE_URL } from "constants/ApiConstant";
import { AUTH_TOKEN, FIRM_NAME, FIRMSTAMPCOUNT } from "constants/StorageConstant";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const MediaSettings = () => {
    const { t, i18n } = useTranslation();
    const [StampFiles, setStampFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });



    var mediaNumber = Number(localStorage.getItem(FIRMSTAMPCOUNT)) + 1;

    useEffect(() => {
        selectFiles();
    })

    const selectFiles = async () => {
        var result = await network.get("/admin/CoffeeSettings/GetAllStampImages", {}, false);
        const { data } = result.data;
        setStampFiles(data);
    }

    const handleUpload = (index) => ({
        name: 'file',
        action: API_FULL_URL + `/admin/CoffeeSettings/ReplaceFile/${StampFiles[index]?.mediapath}`,
        method: 'PUT',
        headers: {
            authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
        showUploadList: false,
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(t("mediaSuccess", { mediaPath: StampFiles[index]?.mediapath }));
                var params = {
                    mediaType: info.file.type === 'video/mp4' ? 2 : 1,
                    mediaPath: info.file.name,
                    mediaId: StampFiles[index]?.mediaid
                };
                var result = network.post("/admin/CoffeeSettings/UpdateMedia", params, false);
                result.then((a) => {
                    console.log(a.isSuccess);
                });
            } else if (info.file.status === 'error') {
                message.error(t("mediaError", { mediaPath: StampFiles[index]?.mediapath }));
            }
        }
        
    });

    const handleBeforeUpload = (file) => {
        const imageMaxSize = 5 * 1024 * 1024;
        const videoMaxSize = 16 * 1024 * 1024;
    
        if (file.type.startsWith("image/") && file.size > imageMaxSize) {
            message.error(t("imageMaxSizeError"));
            return Upload.LIST_IGNORE;
        }
    
        if (file.type.startsWith("video/") && file.size > videoMaxSize) {
            message.error(t("videoMaxSizeError"));
            return Upload.LIST_IGNORE;
        }
    
        return true;
    }
    

    const handlePageChange = (newPagination) => {
        setPagination(newPagination)
    }


    const descriptionHandle = (number) => {
        if (number === 1) {
            return t("waitingImage");
        } else if (number === 3) {
            return t("preStampImage");
        } else if(number===4){
            return t("postStampImage");
        }else if(number===2){
            return t("welcomeImage");

        }
    }
    
    const tableColumns = [
        {
            title: t("image"),
            dataIndex: "imageName",
            render: (_, record) => (
                <div>
                    {record.mediatype === 1 ? (
                        <Image
                            key={record.mediaid}
                            src={`${API_BASE_URL + "/Image/" + localStorage.getItem(FIRM_NAME) + "/" + record.mediapath}`}
                            style={{
                                maxWidth: "100px",
                                height: "auto",
                                borderRadius: "8px",
                                objectFit: "contain",
                            }}
                        />
                    ) : (
                        <video autoPlay muted loop key={record.mediaid} style={{ maxWidth: "100px", height: "auto", borderRadius: "8px", objectFit: "contain" }}>
                            <source
                                src={`${API_BASE_URL + "/Image/" + localStorage.getItem(FIRM_NAME) + "/" + record.mediapath}`}
                                type="video/mp4"
                            />
                        </video>
                    )}
                </div>
            )
        },
        {
            title: t("mediaDescription"),
            dataIndex: "",
            render: (_, record) => (
                <div>
                    {(descriptionHandle(record.mediasteptypeid) === t("welcomeImage"))|| (descriptionHandle(record.mediasteptypeid) === t('waitingImage')) ? (
                        <>{descriptionHandle(record.mediasteptypeid)}</>
                    ) : (
                        <>{record.stamporder}. {descriptionHandle(record.mediasteptypeid)}</>
                    )}
                </div>
            )
        },
        {
            title: "",
            dataIndex: "",
            render: (_, record, index) => (
                <div>
                    <Upload beforeUpload={handleBeforeUpload} accept={record.mediasteptypeid === 4 ? ".mp4" : ".jpg, .png"} {...handleUpload((pagination.current - 1) * pagination.pageSize + index)}>
                        <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                    </Upload>
                </div>
            )
        }
    ];
    
    return (
        <div>
            <div
                className="mb-4"
                style={{
                    color: '#595959',
                    fontSize: '12px',
                    marginTop: '8px',
                    padding: '10px',
                    border: '1px solid #f5222d',  // Kırmızı çerçeve
                    borderRadius: '4px',
                    backgroundColor: '#fff2f0',
                    width: "500px"  // Hafif kırmızımsı arka plan
                }}
            >
                <strong>{t('note')}</strong> {t('fileFormatsAndSizes')}
                <ul>
                    <li>
                        <strong>{t('image')}</strong>: <span style={{ color: '#f5222d' }}>{t('jpegPng')}</span>
                        <span style={{ color: '#f5222d' }}> ({t('maxSize')} 5 MB)</span>
                    </li>
                    <li>
                        <strong>Video:</strong> <span style={{ color: '#f5222d' }}>MP4</span>
                        <span style={{ color: '#f5222d' }}>({t('maxSize')} 16 MB)</span>
                    </li>
                </ul >
            </div >
            <Card>
                <Table
                    columns={tableColumns}
                    dataSource={StampFiles}
                    pagination={pagination}
                    onChange={handlePageChange}
                />
            </Card>
        </div>
    )
}

export default MediaSettings