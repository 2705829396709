import { CheckOutlined, CloseSquareOutlined, EditOutlined, ProductOutlined } from "@ant-design/icons";
import { Card, Input, message, Table, Tag } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CoffeeProductGroup = () => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [productGroups, setProductGroups] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        selectProducts();
    })

    const selectProducts = async () => {
        var result = await network.get("/admin/CoffeeProduct/getAllProductGroups");
        const { data } = result.data;

        setProductGroups(data);
    }

    const handleStatusChange = async (value) => {

        var params = {
            productGroupId: value.productgroupid,
            productGroupName: value.productgroupname,
            productGroupDesc: value.productgroupdesc,
            isInitialGroup: value.isinitialgroup,
            status: !value.status
        }

        var result = await network.post("/admin/CoffeeProduct/UpdateProductGroup", params);

        if (result.isSuccess) {
            message.success("Ürün Grubu Başarıyla Değiştirildi");
            selectProducts();
        }
    }


    const dropdownMenu = (record) => {
        return [

            {
                label: (<span >{t('edit')}</span>),
                key: '3',
                icon: <EditOutlined />,
                onClick: () => navigate(`coffeeproductgroup-create/${record.productgroupid}`)
            },
            {
                label: (<span >{!record.status ? t('active') : t('inactive')}</span>),
                key: '4',
                icon: !record.status ? <CheckOutlined /> : <CloseSquareOutlined />,
                onClick: () => handleStatusChange(record)
            }
        ]
    }

    const filteredProduct = productGroups.filter(product =>
        Object.values(product).some(value =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    )

    const tableColumns = [
        {
            title: t("productGroupName"),
            dataIndex: "productgroupname",
            render: (_, record) => (
                <div>
                    {record.productgroupname}
                </div>
            )
        },
        {
            title: t("productGroupDesc"),
            dataIndex: "productgroupdesc",
            render: (_, record) => (
                <div>
                    {record.productgroupdesc}
                </div>
            )
        },
        {
            title: t("status"),
            dataIndex: "status",
            render: (_, record) => (
                <div>
                    <Tag color={record.status ? "green" : "red"}>
                        {record.status ? t("active") : t("inactive")}
                    </Tag>
                </div>
            )
        },
        {
            title: t("actions"),
            dataIndex: "",
            render: (_, record) => (
                <div>
                    <EllipsisDropdown menu={{ items: dropdownMenu(record) }} />
                </div>
            )
        }
    ];


    return (
        <>


            <Card title={t('menu.productGroup')}>

                <div style={{ display: "flex", justifyContent: "space-between" }}>

                    <div className="mb-3" style={{ width: "250px" }}>
                        <Input

                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="mb-3" style={{ width: "200px" }}>
                        <ReusableButton
                            variant="campaign"
                            text={t("newProductGroup")}
                            icon={<ProductOutlined />}
                            href={"coffee-productgroup/coffeeproductgroup-create/0"}
                        />
                    </div>
                </div>

                <Table
                    columns={tableColumns}
                    dataSource={filteredProduct}
                />
            </Card>
        </>
    )
}

export default CoffeeProductGroup