import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from "react-redux";
import tools from 'tools';
import { Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { targetAppContstants } from 'constants/targetAppContstants';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}



const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = (props) => {

  const { t, i18n } = useTranslation();
  const { navCollapsed, logoType, selectedApp } = props;


  const getLogoByApp = () => {

    let logoPath = "";

    switch (selectedApp) {
      case targetAppContstants.campaign:
        logoPath = i18n.language == "tr" ? "/img/logos/campaign-tr.png" : "/img/logos/campaign-en.png"
        break;
      case targetAppContstants.coffeeproject:
        logoPath = i18n.language == "tr" ? "/img/logos/mudavim-tr.png" : "/img/logos/mudavim-en.png"
        break;
      default:
        logoPath = i18n.language == "tr" ? "/img/logos/campaign-tr.png" : "/img/logos/campaign-en.png"
        break;
    }
    if (navCollapsed) {
     return '/img/logo-sm.png'
    }

    return logoPath;
  }
  const getLogo = () => {


    return getLogoByApp();

    // if (logoType === 'light') {
    //   if (navCollapsed) {
    //     return '/img/logo-sm-white.png'
    //   }
    //   return '/img/logo-white.png'
    // }

    // if (navCollapsed) {
    //   return '/img/logo-sm.png'
    // }
    // return '/img/logo.png'
  }


  const isMobile = !tools.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}>
      <a href='/'><img src={getLogo(props)} alt={`${APP_NAME} logo`} width={navCollapsed ?50:200} /></a>
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType }
};

export default connect(mapStateToProps)(Logo);
