import React, { useEffect } from "react"
import { Row, Col, Button, Card, Avatar, Dropdown, Table, Menu, Tag, Skeleton } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectPrices } from "utils/redux/actions/defaultDashboardActions";
import StatisticWidget from "components/shared-components/StatisticWidget";


const PriceList = (props) => {

    useEffect(()=>{

        props.selectPricesAction();
    },[]);
    const getPriceList = () => {

        if (props.priceListLoading) {
            return (
                <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                        <Skeleton loading paragraph={true} round />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                        <Skeleton loading paragraph={true} round />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8} >
                        <Skeleton loading paragraph={true} round />
                    </Col>
                </>
            );
        } else {
            return props.priceList.map((elm, i) => (
   
                <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
                    <StatisticWidget
                        title={elm.title}
                        value={elm.value}
                        status={elm.status}
                        subtitle={elm.subtitle}
                    />
                </Col>
            ));
        }
    }

    return getPriceList();
}


function mapStateToProps(state) {

    return {
        priceList: state.dashboardReducer.priceList,
        priceListLoading: state.dashboardReducer.priceListLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectPricesAction: bindActionCreators(selectPrices, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceList);
