

const actionTypes = {

    SELECT_PRODUCT_LIST_SUCCESS: "SELECT_PRODUCT_LIST_SUCCESS",
    SELECT_PRODUCT_LIST_ERROR: "SELECT_PRODUCT_LIST_ERROR",
    SELECT_PRODUCT_LIST_LOADING: "SELECT_PRODUCT_LIST_LOADING",

    SELECT_ORDER_LIST_SUCCESS: "SELECT_ORDER_LIST_SUCCESS",
    SELECT_ORDER_LIST_ERROR: "SELECT_ORDER_LIST_ERROR",
    SELECT_ORDER_LIST_LOADING: "SELECT_ORDER_LIST_LOADING",

    SELECT_ORDER_DETAIL_SUCCESS: "SELECT_ORDER_DETAIL_SUCCESS",
    SELECT_ORDER_DETAIL_ERROR: "SELECT_ORDER_DETAIL_ERROR",
    SELECT_ORDER_DETAIL_LOADING: "SELECT_ORDER_DETAIL_LOADING",
    UPDATE_ORDER_MASTER_INFO: "UPDATE_ORDER_MASTER_INFO",

    ORDER_ITEM_ADD: "ORDER_ITEM_ADD",
    ORDER_ITEM_DELETE: "ORDER_ITEM_DELETE",
    ORDER_ITEM_UPDATE: "ORDER_ITEM_UPDATE",

    SELECT_CHAT_LIST_SUCCESS: "SELECT_CHAT_LIST_SUCCESS",
    SELECT_CHAT_LIST_ERROR: "SELECT_CHAT_LIST_ERROR",
    SELECT_CHAT_LIST_LOADING: "SELECT_CHAT_LIST_LOADING",
    RESET_UNREAD_COUNT: "RESET_UNREAD_COUNT",
    CHAT_MASTER_UPDATE_PREV_TEXT: "CHAT_MASTER_UPDATE_PREV_TEXT",

    SELECT_CHAT_DETAIL_LIST_SUCCESS: "SELECT_CHAT_DETAIL_LIST_SUCCESS",
    SELECT_CHAT_DETAIL_LIST_ERROR: "SELECT_CHAT_DETAIL_LIST_ERROR",
    SELECT_CHAT_DETAIL_LIST_LOADING: "SELECT_CHAT_DETAIL_LIST_LOADING",

    FINISH_ADMIN_CHAT: "FINISH_ADMIN_CHAT",
    START_ADMIN_CHAT: "START_ADMIN_CHAT",

    ON_CHAT_LISTENER: "ON_CHAT_LISTENER",
    ON_ORDER_LISTENER: "ON_ORDER_LISTENER",
    ON_NEWCUSTOMER_LISTENER: "ON_NEWCUSTOMER_LISTENER",
    ON_MESSAGEEVENT_LISTENER: "ON_MESSAGEEVENT_LISTENER",
    ON_CUSTOMER_SERVICE_LISTENER: "ON_CUSTOMER_SERVICE_LISTENER",
    ON_RESERVATION_LISTENER: "ON_RESERVATION_LISTENER",
    ON_REQUEST_INFORMATION_LISTENER: "ON_REQUEST_INFORMATION_LISTENER",

    SELECT_CUSTOMER_LIST_SUCCESS: "SELECT_CUSTOMER_LIST_SUCCESS",
    SELECT_CUSTOMER_LIST_ERROR: "SELECT_CUSTOMER_LIST_ERROR",
    SELECT_CUSTOMER_LIST_LOADING: "SELECT_CUSTOMER_LIST_LOADING",
    CHANGE_CUSTOMER_ACTIVITY_STATUS: "CHANGE_CUSTOMER_ACTIVITY_STATUS",

    SELECT_FIRM_SELLER_LIST_SUCCESS: "SELECT_FIRM_SELLER_LIST_SUCCESS",
    SELECT_FIRM_SELLER_LIST_ERROR: "SELECT_FIRM_SELLER_LIST_ERROR",
    SELECT_FIRM_SELLER_LIST_LOADING: "SELECT_FIRM_SELLER_LIST_LOADING",

    SELECT_NOTIFICATION_LIST_SUCCESS: "SELECT_NOTIFICATION_LIST_SUCCESS",
    SELECT_NOTIFICATION_LIST_ERROR: "SELECT_NOTIFICATION_LIST_ERROR",
    SELECT_NOTIFICATION_LIST_LOADING: "SELECT_NOTIFICATION_LIST_LOADING",

    SELECT_HOME_NOTIFICATION_LIST_SUCCESS: "SELECT_HOME_NOTIFICATION_LIST_SUCCESS",
    SELECT_HOME_NOTIFICATION_LIST_ERROR: "SELECT_HOME_NOTIFICATION_LIST_ERROR",
    SELECT_HOME_NOTIFICATION_LIST_LOADING: "SELECT_HOME_NOTIFICATION_LIST_LOADING",
    SELECT_UNREAD_NOTIFICATION_COUNT: "SELECT_UNREAD_NOTIFICATION_COUNT",


    SELECT_D_MESSAGE_CHART_SUCCESS: "SELECT_D_MESSAGE_CHART_SUCCESS",
    SELECT_D_MESSAGE_CHART_ERROR: "SELECT_D_MESSAGE_CHART_ERROR",
    SELECT_D_MESSAGE_CHART_LOADING: "SELECT_D_MESSAGE_CHART_LOADING",

    SELECT_D_CUSTOMER_CHART_SUCCESS: "SELECT_D_CUSTOMER_CHART_SUCCESS",
    SELECT_D_CUSTOMER_CHART_ERROR: "SELECT_D_CUSTOMER_CHART_ERROR",
    SELECT_D_CUSTOMER_CHART_LOADING: "SELECT_D_CUSTOMER_CHART_LOADING",

    SELECT_D_TRANSACTION_ORDER_PERCENT_SUCCESS: "SELECT_D_TRANSACTION_ORDER_PERCENT_SUCCESS",
    SELECT_D_TRANSACTION_ORDER_PERCENT_ERROR: "SELECT_D_TRANSACTION_ORDER_PERCENT_ERROR",
    SELECT_D_TRANSACTION_ORDER_PERCENT_LOADING: "SELECT_D_TRANSACTION_ORDER_PERCENT_LOADING",

    SELECT_D_PRICES_SUCCESS: "SELECT_D_PRICES_SUCCESS",
    SELECT_D_PRICES_ERROR: "SELECT_D_PRICES_ERROR",
    SELECT_D_PRICES_LOADING: "SELECT_D_PRICES_LOADING",

    SELECT_D_LATEST_CUSTOMERS_SUCCESS: "SELECT_D_LATEST_CUSTOMERS_SUCCESS",
    SELECT_D_LATEST_CUSTOMERS_ERROR: "SELECT_D_LATEST_CUSTOMERS_ERROR",
    SELECT_D_LATEST_CUSTOMERS_LOADING: "SELECT_D_LATEST_CUSTOMERS_LOADING",

    SELECT_D_LATEST_ORDERS_SUCCESS: "SELECT_D_LATEST_ORDERS_SUCCESS",
    SELECT_D_LATEST_ORDERS_ERROR: "SELECT_D_LATEST_ORDERS_ERROR",
    SELECT_D_LATEST_ORDERS_LOADING: "SELECT_D_LATEST_ORDERS_LOADING",

    SELECT_D_LATEST_RESERVATIONS_SUCCESS: "SELECT_D_LATEST_RESERVATIONS_SUCCESS",
    SELECT_D_LATEST_RESERVATIONS_ERROR: "SELECT_D_LATEST_RESERVATIONS_ERROR",
    SELECT_D_LATEST_RESERVATIONS_LOADING: "SELECT_D_LATEST_RESERVATIONS_LOADING",



    SELECT_ROOM_LIST_SUCCESS: "SELECT_ROOM_LIST_SUCCESS",
    SELECT_ROOM_LIST_ERROR: "SELECT_ROOM_LIST_ERROR",
    SELECT_ROOM_LIST_LOADING: "SELECT_ROOM_LIST_LOADING",

    SELECT_RESERVATION_LIST_SUCCESS: "SELECT_RESERVATION_LIST_SUCCESS",
    SELECT_RESERVATION_LIST_ERROR: "SELECT_RESERVATION_LIST_ERROR",
    SELECT_RESERVATION_LIST_LOADING: "SELECT_RESERVATION_LIST_LOADING",

    SELECT_RESERVATION_DETAIL_SUCCESS: "SELECT_RESERVATION_DETAIL_SUCCESS",
    SELECT_RESERVATION_DETAIL_ERROR: "SELECT_RESERVATION_DETAIL_ERROR",
    SELECT_RESERVATION_DETAIL_LOADING: "SELECT_RESERVATION_DETAIL_LOADING",
    UPDATE_RESERVATION_MASTER_INFO: "UPDATE_RESERVATION_MASTER_INFO",

    SELECT_REQUEST_INFO_LIST_SUCCESS: "SELECT_REQUEST_INFO_LIST_SUCCESS",
    SELECT_REQUEST_INFO_LIST_ERROR: "SELECT_REQUEST_INFO_LIST_ERROR",
    SELECT_REQUEST_INFO_LIST_LOADING: "SELECT_REQUEST_INFO_LIST_LOADING",

    SELECT_MENU_LIST_SUCCESS: "SELECT_MENU_LIST_SUCCESS",
    SELECT_MENU_LIST_ERROR: "SELECT_MENU_LIST_ERROR",
    SELECT_MENU_LIST_LOADING: "SELECT_MENU_LIST_LOADING",


    SELECT_ACTIVITY_STATUS_LIST_SUCCESS: "SELECT_ACTIVITY_STATUS_LIST_SUCCESS",
    SELECT_ACTIVITY_STATUS_LIST_ERROR: "SELECT_ACTIVITY_STATUS_LIST_ERROR",
    SELECT_ACTIVITY_STATUS_LIST_LOADING: "SELECT_ACTIVITY_STATUS_LIST_LOADING",

    STAMP_LISTENER: "STAMP_LISTENER",
    LISTENER_ACTIVATE: "LISTENER_ACTIVATE",

    SELECT_BRANCHLIST_SUCCESS: "SELECT_BRANCHLIST_SUCCESS",
    SELECT_BRANCHLIST_LOADING: "SELECT_BRANCHLIST_LOADING",

    CAMPAIGN_CUSTOMER_LIST_SUCCESS: "CAMPAIGN_CUSTOMER_LIST_SUCCESS",
    CAMPAIGN_CUSTOMER_LIST_ERROR: "CAMPAIGN_CUSTOMER_LIST_ERROR",
    CAMPAIGN_CUSTOMER_LIST_LOADING: "CAMPAIGN_CUSTOMER_LIST_LOADING",
}

export default actionTypes;