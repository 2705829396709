import { Card, Table, Select, Input, Button, Badge, Menu, MenuProps, Tag, notification, Spin, Modal } from 'antd';
import React from 'react'
import OrderListData from "assets/data/order-list.data.json"
import { EyeOutlined, UserDeleteOutlined, CaretRightOutlined, SearchOutlined, PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import tools from 'tools'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import globalConstant from 'constants/globalConstant';
import { selectCustomerList, selectFirmSellerList } from 'utils/redux/actions/customerActions';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faUserMinus } from '@fortawesome/free-solid-svg-icons'
import helperFunc from 'helpers/helperFunc';
import network from 'network/network';



import { LoadingOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { changeActivityStatus } from 'utils/redux/actions/activityStatusActions'
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { render } from '@testing-library/react';
const { Option } = Select;

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />



const getCustomerStatus = status => {

	if (status == globalConstant.customerStatus.active) {
		return 'cyan'
	}
	if (status == globalConstant.customerStatus.passive) {
		return 'red'
	}
	if (status == globalConstant.customerStatus.waitConfirmation) {
		return 'yellow'
	}
}
const getShippingStatusText = (status) => {
	if (status == globalConstant.customerStatus.active) {
		return 'Aktif'
	}
	if (status == globalConstant.customerStatus.passive) {
		return 'Pasif'
	}
	if (status == globalConstant.customerStatus.waitConfirmation) {
		return 'Beklemede'
	}
}

const Customers = (props) => {

	const navigate = useNavigate();
	const { activityStatusList } = props;

	const selectedCustomerId = useRef(-1);
	const [loadingPanel, setLoadingPanel] = useState(false)
	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		selectCustomerList();
	}, []);

	const selectCustomerList = () => {
		var params = {
		}
		props.selectCustomerListAction(params);
	}
	const onSearch = e => {
		const value = e.currentTarget.value
		setSearchValue(value);
	}
	const viewDetails = row => {
		navigate(`/customer/detail/${row.CLIENTID}`)
	}

	const addCustomer = () => {

		navigate(`/customer/detail/0`)
	}

	const getCustomerActivityStatus = (row) => {

		var index = activityStatusList.findIndex(w => w.id == row.ACTIVITYSTATUS);
		if (index != -1) {
			return {
				text: activityStatusList[index].statusdesc,
				color: activityStatusList[index].statuscolor,
			};
		}
		return {
			color: "",
			text: ""
		}
	}

	const deleteCustomerConfirm = async (row) => {
		setLoadingPanel(true);
		var params = {
			customerId: row.CUSTOMERID
		}

		var result = await network.get("/admin/Client/deleteClient", params, false);

		setTimeout(() => {
			setLoadingPanel(false);
		}, 250);

		if (result.isSuccess) {

			var data = result.data;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Müşteri silme işlemi başarılı bir şekilde gerçekleştirildi",
					placement: "topRight",
				});
				selectCustomerList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage,
				placement: "topRight",
			});
		}
	}
	const deleteCustomerPrevious = (row) => {

		helperFunc.showConfirm({
			content: `${row.CUSTOMERNAME} adlı Müşteri kalıcı olarak silinecek. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => deleteCustomerConfirm(row)
		});
	}

	const changeCustomerStatusConfirm = async (row, newStatus) => {

		setLoadingPanel(true);
		var params = {
			customerId: row.CUSTOMERID,
			status: newStatus
		}

		var result = await network.get("/admin/client/updateClientStatus", params, false);

		setTimeout(() => {
			setLoadingPanel(false);
		}, 250);

		if (result.isSuccess) {

			var data = result.data;
			if (data.isSuccess) {

				notification.success({
					message: `Başarılı`,
					description: "Müşteri durumu başarılı bir şekilde değiştirildi.",
					placement: "topRight",
				});
				selectCustomerList();

			} else {
				notification.error({
					message: `Hata!`,
					description: data.errorMessage,
					// placement:"topRight",
				});
			}
		} else {
			notification.error({
				message: `Hata!`,
				description: result.errorMessage,
				placement: "topRight",
			});
		}

	}
	const changeCustomerStatusPrevious = (row, newStatus) => {

		selectedCustomerId.current = row.CUSTOMERID;
		var title = "Hatırlatma"
		var content = `${row.CUSTOMERNAME} adlı Müşteri durumu Aktif olarak değiştirilecek. Devam edilsin mi?`;
		if (newStatus == globalConstant.customerStatus.passive) {
			content = `${row.CUSTOMERNAME} adlı Müşteri durumu Pasif olarak değiştirilecek. Devam edilsin mi?`;
		}
		else {
			helperFunc.showConfirm({
				content: content,
				title: title,
				onConfirm: () => changeCustomerStatusConfirm(row, newStatus)
			});
		}
	}

	const changeCustomerActivityStatus = (row, activityStatus) => {

		helperFunc.showConfirm({
			content: `${row.CLIENTNAME} Aktivite durumu değiştirilecek. Devam edilsin mi?`,
			title: "Hatırlatma", onConfirm: () => changeCustomerActivityStatusConfirm(row, activityStatus)
		});

	}

	const changeCustomerActivityStatusConfirm = (row, activityStatus) => {

		const params = {
			activityStatus: activityStatus,
			customerId: row.CLIENTID
		}
		setLoadingPanel(true);
		props.changeActivityStatusAction(params);
		setTimeout(() => {
			setLoadingPanel(false);
		}, 500);
	}

	const getCustomerStatusDropdown = (row) => {
		var status = row.CLIENTSTATUS;
		const activeText = row.firmSellerId > 0 ? "Aktife Al" : "Bayiye Ata";

		if (status == globalConstant.customerStatus.active) {
			return {

				label: "Pasife Al",
				key: '5',
				icon: (<FontAwesomeIcon icon={faUserMinus} />),
				onClick: () => changeCustomerStatusPrevious(row, globalConstant.customerStatus.passive)
			}
		}
		if (status == globalConstant.customerStatus.passive) {
			return {
				label: activeText,
				key: '4',
				icon: <PlusCircleOutlined />,
				onClick: () => changeCustomerStatusPrevious(row, globalConstant.customerStatus.active)
			}
		}
		if (status == globalConstant.customerStatus.waitConfirmation) {
			return {
				label: activeText,
				key: '3',
				icon: <PlusCircleOutlined />,
				onClick: () => changeCustomerStatusPrevious(row, globalConstant.customerStatus.active)
			}
		}
	}
	const dropdownMenu = row => {
		return [
			{
				key: 'edit',
				icon: <EditOutlined />,
				label: (
					<Flex alignItems="center">
						<span className="ml-2">Düzenle</span>
					</Flex>
				),
				onClick: () => navigate(`/customer/detail/${row.CLIENTID}`)
			},
			// {
			//     key: 'resend',
			//     icon: <CopyOutlined />,
			//     label: (
			//         <Flex alignItems="center">
			//             <span className="ml-2">Tekrar Gönder</span>
			//         </Flex>
			//     ),
			// },
			//   {
			// 	 key: 'delete',
			// 	 icon: <DeleteOutlined />,
			// 	 label: (
			// 		<Flex alignItems="center">
			// 		    <span className="ml-2">Sil</span>
			// 		</Flex>
			// 	 ),
			// 	 onClick: () => deleteRowPrevious(row)
			//   },
		];
	};
	const getItems = elm => (
		<Menu>
			<Menu.Item onClick={() => navigate(`/customer/detail/${elm.CLIENTID}`)}>
				<Flex alignItems="center">
					<EditOutlined />
					<span className="ml-2">Düzenle</span>
				</Flex>
			</Menu.Item>
			<Menu.Item onClick={() => ""}>
				<Flex alignItems="center">
					<EditOutlined />
					<span className="ml-2">Sil</span>
				</Flex>
			</Menu.Item>

		</Menu>
	)

	const getCustomerDetail = elm => {

	}
	// return [
	// 		{
	// 		  label:"Detayı Göster",
	// 		  key: '0',
	// 		  icon: <EyeOutlined />,
	// 		  onClick: ()=> viewDetails(elm)
	// 		},
	// 		getCustomerStatusDropdown(elm),
	// 		{
	// 		  label: 'Müşteriyi Sil',
	// 		  key: '3',
	// 		  icon: <DeleteOutlined />,
	// 		  onClick: () => deleteCustomerPrevious(elm)
	// 		},
	// 		{
	// 			label: (<a target={"_blank"} href={`https://www.google.com/maps/search/?api=1&query=${elm.LAT},${elm.LNG}`}>
	// 			<span>Konumu Gör</span>
	// 		</a>),
	// 			key: '4',
	// 			icon: <CaretRightOutlined />
	// 		}
	// 	  ];


	const getItemsActivity = elm => {
		// <Menu>
		//     <Menu.Item >
		//         <Flex alignItems="center">
		//             <EditOutlined />
		//             <span className="ml-2">Düzenle</span>
		//         </Flex>
		//     </Menu.Item>

		// </Menu>
		// var list = [];
		// list.push({
		// 	label : "Lütfen Aktivite Durumunu Seçin",
		// 	key : "-1",
		// 	disabled: true
		// })
		// for (let i = 0; i < activityStatusList.length; i++) {
		// const element = activityStatusList[i];
		// list.push({
		//       label:(<span className="ml-2" style={{ color: element.statuscolor }}>{element.statusdesc}</span>),
		// 	  key: i+"",
		// 	  onClick: ()=> changeCustomerActivityStatus(elm, element.id)
		// });
		// }
		// return list;

	}

	const tableColumns = [
		{
			title: 'id',
			dataIndex: 'CLIENTID',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'CUSTOMERID')
		},
		{
			title: 'Müşteri Adı',
			dataIndex: 'CLIENTNAME',
			// render: (_, record) => (
			// 	<div className="d-flex">
			// 		<AvatarStatus size={30}
			// 			src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, record.CUSTOMERIMAGE || "")}
			// 			name={record.CLIENTNAME} />
			// 	</div>
			// ),
			render: (_, record) => (
				<>
					<div className="d-flex">
						<AvatarStatus size={30}
							src={globalConstant.imageTypes.getImageUrl(globalConstant.imageTypes.customerImage.type, record.CUSTOMERIMAGE || "")}
							name={record.CLIENTNAME} />
					</div>
					{/* <div className="ml-2 mt-2">
					<Tag color="blue">{record.INSERTDATE}</Tag>
			  	</div> */}

				</>
			),
			sorter: (a, b) => tools.antdTableSorter(a, b, 'PRODUCTNAME')
		},
		{
			title: 'Telefon No',
			dataIndex: 'CLIENTNUMBER',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'CUSTOMERNUMBER')
		},
		{
			title: 'Müşteri Adresi',
			dataIndex: 'CUSTOMERADDRESS',
			sorter: (a, b) => tools.antdTableSorter(a, b, 'CUSTOMERADDRESS'),
			render: (text, record) => (
				<div className="d-flex">
					{record.LAT && record.LNG ? (
						<>
							<a href={`https://www.google.com/maps/search/?api=1&query=${record.LAT},${record.LNG}`}
								target="_blank"
								rel="noopener noreferrer">
								<FontAwesomeIcon icon={faMap} style={{ marginRight: '4px' }} />
							</a>
						</>
					) : (
						<span>-</span>
					)}
				</div>
			),
		},

		{
			title: 'Aktivite Durumu',
			dataIndex: 'CLIENTSTATUS',
			// sorter: (a, b) => utils.antdTableSorter(a, b, 'ACTIVITYSTATUS')
			render: (_, record) => (
				<div className='row'>
					<Tag color={getCustomerActivityStatus(record).color}>{getCustomerActivityStatus(record).text}</Tag>
					<div className="text-right" style={{ float: "right" }}>
						<EllipsisDropdown menu={{ items: getItemsActivity(record) }} />
					</div>
				</div>
			),
			filters: props.activityFilterList,
			onFilter: (value, record) => {
				return record.ACTIVITYSTATUS == (value);
			}
		},
		{
			title: 'Durumu',
			dataIndex: 'CLIENTSTATUS',
			render: (_, record) => (
				<Tag color={getCustomerStatus(record.CLIENTSTATUS)}>{getShippingStatusText(record.CLIENTSTATUS)}</Tag>
			)
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<span className='mb-2 ml-1'>
					<EllipsisDropdown className="mb-3" menu={{ items: dropdownMenu(elm) }} />
				</span>
			)

		},
		{
			title: '',
			dataIndex: '',
			render: (_, elm) => (
				<div>
					{getCustomerDetail(elm.CLIENTID)}
				</div>
			)
		}
	];

	const { customerList } = props;




	var data = JSON.parse(JSON.stringify(customerList));
 

 

	if (searchValue.length > 0) {
		data = data.filter(function (el) {
			return (el.CLIENTNAME.toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
				(el.CLIENTID || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1 ||
				(el.CLIENTNUMBER || "").toString().toUpperCase().indexOf(searchValue.toUpperCase()) != -1)
		});
	}

	return (
		<Spin spinning={loadingPanel} indicator={Icon} >
			<Helmet>
				<title>Müşteri Listesi</title>
			</Helmet>
			<Card>
				<Flex alignItems="center" justifyContent="between" mobileFlex={false}>
					<Flex className="mb-1" mobileFlex={false}>
						<div className="mr-md-3 mb-3">
							<Input placeholder="Müşterilerde Ara" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
						</div>
					</Flex>
					<Flex alignItems="center" justifyContent="between" mobileFlex={false}>

						<div>
							<Button onClick={addCustomer} type="primary" icon={<PlusCircleOutlined />} block>Müşteri Ekle</Button>
						</div>

					</Flex>
				</Flex>
				<div className="table-responsive">
					<Table
						columns={tableColumns}
						dataSource={data}
						rowKey='customerid'
					/>
				</div>
			</Card>
		</Spin>
	)
}

function mapStateToProps(state) {

	return {
		isLoading: state.customerReducer.isLoading,
		customerList: state.customerReducer.customerList,
		hasError: state.customerReducer.hasError,
		activityStatusList: state.activityStatusReducer.activityList,
		activityFilterList: state.activityStatusReducer.activityFilterList,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		selectCustomerListAction: bindActionCreators(selectCustomerList, dispatch),
		changeActivityStatusAction: bindActionCreators(changeActivityStatus, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);