import network from "network/network"
import actionTypes from "./actionTypes"



export function selectBranchList(user) {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SELECT_BRANCHLIST_LOADING,
            isLoading: true
        })


        network.get("/admin/Stamp/GetAllBranch", user, false).then((result) => {
            dispatch({
                type: actionTypes.SELECT_BRANCHLIST_LOADING,
                isLoading: false
            })

            const { data } = result


            if (result.isSuccess && data.isSuccess) {

                var branches = data.data[0].branchList;
        

                dispatch({
                    type: actionTypes.SELECT_BRANCHLIST_SUCCESS,
                    payload: branches
                });
            }
        })
    }
}