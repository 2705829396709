

import { Button, Card, Col, Dropdown, Input, message, Row, Select, Spin, Table, Tag } from "antd";
import network from "network/network";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, FileExcelOutlined, PlusCircleOutlined, QrcodeOutlined, SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tools from 'tools';
import * as XLSX from 'xlsx';
import AvatarStatus from "components/shared-components/AvatarStatus";
import ReusableButton from "components/Elements/ReusableButton";
import helperFunc from "helpers/helperFunc";
import { useTranslation } from "react-i18next";
import globalConstant from "constants/globalConstant";
import QrCodeModal from "components/Elements/QrCodeModal";
import moment from "moment";
import ReactApexChart, { ApexOptions } from "react-apexcharts";
import Flex from "components/shared-components/Flex";
const { Option } = Select;

function generateRandomColors(numberOfColors) {
	let colors = [];

	for (let i = 0; i < numberOfColors; i++) {
		let randomColor = '';

		// Beyazdan uzak renkler için RGB değerlerini kontrol ediyoruz
		while (true) {
			// Rastgele RGB değerleri oluştur (0-255 arası)
			let r = Math.floor(Math.random() * 256);
			let g = Math.floor(Math.random() * 256);
			let b = Math.floor(Math.random() * 256);

			// Renk çok açık (beyaza yakın) olmasın
			if (r + g + b < 400) {
				randomColor = `rgb(${r}, ${g}, ${b})`;
				break;
			}
		}

		colors.push(randomColor);
	}

	return colors;
}

const EventList = (props) => {

	const qrCodeModalRef = useRef();
	const isFirstEventChange = useRef(false);

	const [pageData, setPageData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [dashboardLoading, setDashboardLoading] = useState(true);
	const [searchValue, setSearchValue] = useState("");
	const [selectedEventId, setSelectedEventId] = useState(-1);
	const [eventDashboardInfo, setEventDashboardInfo] = useState({
		eventVisitCount: 0,
		standVisitCount: 0,
		totalVisitCount: 0,
		standVisits: []
	});
	const navigate = useNavigate();
	const { t, } = useTranslation();

	const [graphState, setGraphState] = useState({

		series: [],
		options: {
			chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '55%',
					endingShape: 'rounded'
				},
			},
			dataLabels: {
				enabled: false
			},
			colors: ["#FF6A00", "#F3C623", "#10375C", "#10375C"],
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			bar: {
				columnWidth: 2, // Burada çubuğun genişliğini %100 yapıyoruz
				distributed: true, // Her seriye farklı renk atama
			},

			xaxis: {
				categories: [],
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return val
					}
				}
			}
		},


	});


	useEffect(() => {
		selectEventList(); 

	}, []);

	useEffect(() => { 
		if (isFirstEventChange.current == true) {
			isFirstEventChange.current = false;
		}else {
			selectEventDashboard();
		}
	

	}, [selectedEventId]);
	const selectEventDashboard = async () => {
		setDashboardLoading(true);
		const params = {
			eventId: selectedEventId
		};
		var result = await network.get("/admin/event/event-dashboard", params, false);
		if (result.isSuccess) {


			setEventDashboardInfo(result.data);

			const eventStandList = result.data.standVisits;
			var standNames = eventStandList.map((item) => item.standName);
			const colors = generateRandomColors(standNames.length);
			const standData = eventStandList.map((item) => item.visitCount);
			const series = standNames.map((stand, index) => ({
				name: stand,
				data: [standData[index]], // Her bir stand için veriyi buraya ekliyoruz
			}));
			console.log(series);
			setGraphState((prevState) => ({
				...prevState,
				series: series,
				options: {
					...prevState.options,
					colors: colors,
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: standData?.length<3? '15%':"55%",
							endingShape: 'rounded'
						},
					},
					xaxis: {
						...prevState.options.xaxis,
						categories: standNames,
						labels: {
							rotate: -40,
							rotateAlways: true,
							style: {
								fontSize: '12px',
							},
							offsetY: 0,
							textAnchor: 'middle'
						}
					}
				}
			}));
		}
		setDashboardLoading(false);
	}

	const selectEventList = async () => {
		var params = {};

		var result = await network.get("/admin/event/events", params, false);


		if (result.isSuccess) {
			setSelectedEventId(result.data[0].eventId);
			isFirstEventChange.current = true;
			setPageData(result.data);

		}
		setLoading(false);

	}
	const changeEventStatusPrev = (row) => {
		const newStatus = row.status == false ? true : false

		const message = newStatus !== true ? t("makeEventPassiveConfirm") : t("makeEventActiveConfirm")

		helperFunc.showConfirm({
			content: message,
			title: t("warning"), onConfirm: () => changeEventStatusConfirm(row, newStatus)
		});
	}
	const changeEventStatusConfirm = async (row, newStatus) => {

		setLoading(true);

		var params = {
			eventId: row.eventId,
			status: newStatus
		}

		var result = await network.get("/admin/event/update-event-status", params, false);

		setTimeout(() => {
			setLoading(false);
		}, 250);

		if (result.isSuccess) {


			message.success(t("changeEventStatusSuccess"))


			var index = pageData.findIndex(w => w.eventId == row.eventId);
			var data = JSON.parse(JSON.stringify(pageData));
			if (index != -1) {

				data[index].status = newStatus;
			}

			setPageData(data);

		} else {
			message.error(t("changeEventStatusError"))
		}

	}

	const onSearch = (e) => {
		const value = e.currentTarget.value;
		setSearchValue(value);


	}
	const onOpenQrCode = (row) => {
		const link = `https://wa.me/${row.firmNumber}?text=${row.eventMessageKey}`
		const desc = `${row.eventDesc}`
		qrCodeModalRef.current.openModal(link, desc);
	}

	const handleeventCustomerChange = (value, label) => {

		try {
			setSelectedEventId(value);
		} catch (error) {
			alert(error);
		}

	};

	const getItems2 = (record) => [
		{
			key: 'detail',
			label: (
				<div onClick={() => navigate(`/event-edit/${record.eventId}`)}>
					<EditOutlined />
					<span className="ml-2">{t('edit')}</span>
				</div>
			)
		},
		{
			key: 'delete',
			label: (
				<div onClick={() => changeEventStatusPrev(record)}>
					<DeleteOutlined />
					<span className="ml-2">{record.status !== false ? t('makePassive') : t('makeActive')}</span>
				</div>
			)
		}
	];

	const tableColumns = [
		{
			title: t('EventName'),
			dataIndex: "eventName",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'eventName'),
			shouldCellUpdate: (record, prevRecord) => record.eventName !== prevRecord.eventName,
		},
		{
			title: t('EventDescription'),
			dataIndex: "eventDesc",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'eventDesc'),
			shouldCellUpdate: (record, prevRecord) => record.eventDesc !== prevRecord.eventDesc,

		},
		{
			title: t('EventMessageKey'),
			dataIndex: "eventMessageKey",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'eventMessageKey'),
			shouldCellUpdate: (record, prevRecord) => record.eventMessageKey !== prevRecord.eventMessageKey,
		},
		{
			title: t("StartDate"),
			dataIndex: "startDate",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'startDate'),
			render: (_, record) => (
				<div className="font-weight-normal">
					{moment(record.startDate).format('DD.MM.YYYY')}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.startDate !== prevRecord.startDate,
		},
		{
			title: t("EndDate"),
			dataIndex: "endDate",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'endDate'),
			render: (_, record) => (
				<div className="font-weight-normal">

					{moment(record.endDate).format('DD.MM.YYYY')}
				</div>
			),
			shouldCellUpdate: (record, prevRecord) => record.endDate !== prevRecord.endDate,
		},

		{
			title: t("status"),
			dataIndex: "status",
			sorter: (a, b) => tools.antdTableSorter(a, b, 'status'),
			render: (_, record) => (
				record.status !== false ? (

					<div className='my-4 '>
						<><Tag color={"green"}>{t('active')}</Tag></>
					</div>


				) : (

					<div className='my-4 '>
						<><Tag color={"red"}>{t('inactive')}</Tag></>
					</div>

				)
			),
			shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email,
		},
		{
			title: t('qrCodeCol'),
			dataIndex: "",
			render: (_, record) => (
				<div className="row-align-center">
					<Button variant="text" style={{
						boxShadow: "none",
						border: 0
					}} onClick={() => onOpenQrCode(record)} icon={<QrcodeOutlined style={{ fontSize: "25px" }} />}>

					</Button>
				</div>

			)
		},
		{
			title: t('eventDetail'),
			dataIndex: "",
			render: (_, record) => (
				<div className="row-align-center">
					<Dropdown menu={{ items: getItems2(record) }} trigger={['click']}>
						<div className="ellipsis-dropdown" style={{ cursor: 'pointer' }}>
							<EllipsisOutlined />
						</div>
					</Dropdown>
				</div>

			)
		}
	]

	const getStatusText = (record) => {

		return record.status !== false ? t('active') : t('inactive');
	}

	const handleExcelExport = () => {
		const excelData = filteredData.map((record) => ({
			[t('EventName')]: record.eventName,
			[t('EventDescription')]: record.eventDesc,
			[t('EventMessageKey')]: record.eventMessageKey,
			[t('StartDate')]: new Date(record.startDate).toLocaleDateString() + " " + record.startTime,
			[t('EndDate')]: new Date(record.endDate).toLocaleDateString() + " " + record.endTime,
			[t('status')]: getStatusText(record),
		}));

		// Create a new worksheet and add the data
		const worksheet = XLSX.utils.json_to_sheet(excelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Events");

		// Export to Excel
		XLSX.writeFile(workbook, "EventData.xlsx");
	}

	const getFilteredData = () => {

		if (searchValue.length > 0) {
			const filtered = pageData.filter((item) => {
				return (
					item.eventName.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.eventDesc.toUpperCase().includes(searchValue.toUpperCase()) ||
					item.eventMessageKey?.toUpperCase().includes(searchValue.toUpperCase())
				);
			});
			return filtered;
		} else {
			return pageData
		}
	}
	const filteredData = getFilteredData();

	const addEvent = () => {

		navigate(`/event-edit/0`)
	}
	return (
		<Spin size="large" spinning={loading}>
			<div>
				<Col span={24} className='height-100' xs={24} sm={24} md={24} lg={25}>
				<Col size="large" spinning={dashboardLoading}>
					<Card title={t("eventReportCardTitle")} 
					>
				 
						<Select
							onChange={handleeventCustomerChange}
							placeholder={t("selectEvent")}
							style={{
								width: 300,
							}}
							value={selectedEventId}
							className="mb-4"
							>
							{pageData.map((item, index) => (
								<Option value={item.eventId} label={item.eventName} >
									{item.eventName}
								</Option>
							))}
						</Select> 
						
						<Row justify="space-between" style={{ width: "100%" }} gutter={[16, 16]}>
							<Col flex="1" key={1}>
								<Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
									<Row className='mr-1'>
										<Col span={19}><h4 className='ml-1 ' >{t('totalVisitCount')}</h4>
										</Col>
										<Col span={4} >
											<TeamOutlined className='mt-1 ml-2 d-flex align-items-center justify-content-center' style={{ fontSize: '21px', color: "#52c41a" }} />
										</Col>
									</Row>
									<br></br>
									<h1 className=' ml-2 mb-3'>{eventDashboardInfo?.totalVisitCount || 0} </h1>

								</Card>
							</Col>
							<Col flex="1" key={2}>
								<Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
									<Row className='mr-1'>
										<Col span={19}><h4 className='ml-1 ' >{t('eventVisitCount')}</h4>
										</Col>
										<Col span={4} >

											<h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 12 11" height="19" width="19" preserveAspectRatio="xMidYMid meet" className="" fill="none"><title>msg-check</title><path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor"></path></svg> </h2>
										</Col>
									</Row>
									<br></br>
									<h1 className=' ml-2 mb-3'>{eventDashboardInfo?.eventVisitCount || 0}</h1>

								</Card>
							</Col>
							<Col flex="1" key={3}>
								<Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
									<Row className='mr-1'>
										<Col span={19}><h4 className='ml-1 ' >{t('standVisitCount')}</h4>
										</Col>
										<Col span={4} >

											<h2 className='ml-3' style={{ color: "#697780", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>
										</Col>
									</Row >
									<br></br>
									<h1 className=' ml-2 mb-3'>{eventDashboardInfo?.standVisitCount || 0}</h1>

								</Card>
							</Col>
							{/* <Col flex="1" key={4}>
								<Card bodyStyle={{ padding: 8, boxShadow: "5px 8px 24px 5px rgba(105, 119, 128, 0.1)" }} >
									<Row className='mr-1'>
										<Col span={19}><h4 className='ml-1 ' >{t('totalRead')} </h4>
										</Col>
										<Col span={4} >
											<h2 className='ml-3' style={{ color: "#53bdea", margin: 0, height: "33px", textAlign: 'center' }}><svg viewBox="0 0 18 18" height="21" width="21" preserveAspectRatio="xMidYMid meet" className="dh5rsm73 hpdpob1j" version="1.1" x="0px" y="0px" enable-background=""><title>status-dblcheck</title><path fill="currentColor" d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"></path></svg></h2>

										</Col>
									</Row >
									<br></br>
									<h1 className=' ml-2 mb-3'>{eventDashboardInfo?.readedCount || 0
									}</h1>

								</Card>
							</Col> */}

						</Row>
						<Card title={t("standVisits")}>
							<ReactApexChart options={graphState.options} series={graphState.series} type='bar' height={400} />
						</Card>
					</Card>
</Col>

				</Col>

				<Row className="mb-3" justify={"space-between"}>

					<Col span={4}>
						<Input
							placeholder={t('searchEvent')}
							prefix={<SearchOutlined />}
							onChange={onSearch}
						/>
					</Col>
					<div className="row-justify-between ">
						<ReusableButton
							variant="campaign"
							text={t('Export')}
							onClick={handleExcelExport}
							icon={<ExportOutlined />}
							className={"mr-2"}

						/>
						<ReusableButton
							variant="campaign"
							text={t('addEvent')}
							onClick={addEvent}
							icon={<PlusCircleOutlined />}
						/>
					</div>

				</Row>

				<Card>
					<div className="table-responsive">
						<Table
							columns={tableColumns}
							dataSource={filteredData}
							size="middle"
							rowKey={"eventId"}
							key={JSON.stringify(pageData)}
						/>
					</div>
				</Card>
			</div>
			<QrCodeModal ref={qrCodeModalRef} />
		</Spin>




	);
}

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
