import AppLocale from 'lang'
import LoginForm from 'pages/Auth/LoginForm'
import AppLayout from 'pages/Dashboard/AppLayout'
import Home from 'pages/Dashboard/home/index'
import ErrorPage from 'pages/ErrorPage'
import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Customers from 'pages/Dashboard/customers/index'
import Product from 'pages/Dashboard/product/index'
import Orders from 'pages/Dashboard/orders/index'
import Sellers from 'pages/Dashboard/sellers/index'
import Chat from 'pages/Dashboard/chat/index'
import Notification from 'pages/Dashboard/notifications/index'
import EditCustomer from 'pages/Dashboard/customers/edit-customer' 
import OrderDetail from 'pages/Dashboard/orders/order-detail'
import EditOrder from 'pages/Dashboard/orders/edit-order'
import AddProduct from 'pages/Dashboard/product/add-product'
import Campaigns from 'pages/Dashboard/campaigns/campaigns'
import CampaignDetail from 'pages/Dashboard/campaigns/campaign-detail'
import CampaignCreate from 'pages/Dashboard/campaigns/campaign-create'
import CampaignCustomers from 'pages/Dashboard/campaigns/campaign-customer'
import CampaignCustomersInsertUpdate from 'pages/Dashboard/campaigns/insertupdate-customer'
import CampaignCustomersImportExcel from 'pages/Dashboard/campaigns/customer-import-excel'
import Groups from 'pages/Dashboard/groups/'
import GroupsCreate from 'pages/Dashboard/groups/groups-create'
import BaristaScreen from 'pages/Dashboard/barista-screen'
import KodsanReport from 'pages/Dashboard/kodsan-report'
import CoffeeDashboard from 'pages/Dashboard/coffee-dashboard'
import DashboardCustomer from 'pages/Dashboard/dashboard-customer'
import DetailDashboardCustomer from 'pages/Dashboard/dashboard-customer/detail-customer'
import CreateTemplate from 'pages/Dashboard/template/template-create'
import Templates from 'pages/Dashboard/template'
import EditTemplate from 'pages/Dashboard/template/template-edit'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import ResetPassword from 'pages/Auth/ResetPassword'
import EventCreate from 'pages/Dashboard/events/event-create'
import EventStandCreate from 'pages/Dashboard/events/event-stand-create'
import EventList from 'pages/Dashboard/events/event-list'
import EventStandList from 'pages/Dashboard/events/event-stand-list'
import EventAtteendList from 'pages/Dashboard/events/event-visits'
import Settings from 'pages/Dashboard/settings'
import CoffeeProduct from 'pages/Dashboard/coffee-product'
import CoffeeProductCreate from 'pages/Dashboard/coffee-product/coffeeproduct-create'
import CoffeeProductGroup from 'pages/Dashboard/coffee-productgroup'
import CoffeeProductGroupCreate from 'pages/Dashboard/coffee-productgroup/coffeeproductgroup-create'



function Router(props) {

  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
    const [appName, setAppName] = useState(""); 

    useEffect(() => {
      const storedMenuList = JSON.parse(localStorage.getItem("LEFT_MENU_LIST")) || [];
 
      if (storedMenuList.length > 0) {
        setAppName(storedMenuList[0].appName);
      }
    }, []);

    const isCampaign = appName  == "campaign";
    const isCoffeeApp = appName  == "coffeeproject";
    const isEventApp = appName  == "event";
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <BrowserRouter >
        <Routes  >
          <Route path='/' element={<PrivateRoute><AppLayout /></PrivateRoute>}>
            <Route index={isCoffeeApp} path={isCoffeeApp ? "":'coffee-dashboard'} element={<CoffeeDashboard />} />
            <Route path={isCampaign ? "":'campaigns'} index={isCampaign} element={<Campaigns />}/>
            <Route path={isEventApp?"": 'events'} index={isEventApp} element={<EventList/>}/>
            <Route path={"events"} element={<EventList/>}/>
            <Route path={'campaigns'} element={<Campaigns />}/>
            <Route path='chat' element={<Chat />} />
            <Route path='customers' element={<Customers />} />
            <Route path='customer/detail/:id' element={<EditCustomer />} />
            <Route path='product-list' element={<Product />} />
            <Route path='product/add/:id' element={<AddProduct />} />
            <Route path='orders' element={<Orders />} />
            <Route path='order/detail/:id' element={<OrderDetail />} />
            <Route path='order/edit/:id' element={<EditOrder />} />
            <Route path='sellers' element={<Sellers />} />
            <Route path='notifications' element={<Notification />} />

            <Route path='campaign-detail/:id' element={<CampaignDetail />}/>
            <Route path='campaign-create' element={<CampaignCreate />}/>
            <Route path='campaign-customer' element={<CampaignCustomers />}/>
            <Route path='campaign-customer-excel' element={<CampaignCustomersImportExcel />}/>
            <Route path='customer-edit/:id' element={<CampaignCustomersInsertUpdate />}/>
            <Route path='groups' element={<Groups/>}/>
            <Route path='groups/groups-create/:id' element={<GroupsCreate/>}/>
            <Route path='barista-screen' element={<BaristaScreen/>}/>
            <Route path='kodsan-report' element={<KodsanReport/>}/>
            <Route path='coffee-dashboard' element={<CoffeeDashboard/>}/>
            <Route path='dashboard-customer' element={<DashboardCustomer/>}/>
            <Route path='dashboard-customer/detail/:id' element={<DetailDashboardCustomer/>}/>
            <Route path='template-create' element={<CreateTemplate/>}/>
            <Route path='template-edit' element={<EditTemplate/>}/>
            <Route path='templates' element={<Templates/>}/> 
            <Route path='settings' element={<Settings/>} />
            <Route path='event-stands' element={<EventStandList/>}/>
            <Route path='event-visits' element={<EventAtteendList/>}/>
            <Route path='event-edit/:id' element={<EventCreate/>}/>
            <Route path='stand-edit/:id' element={<EventStandCreate/>}/>
            <Route path='settings' element={<Settings/>}/>
            <Route path='coffee-product' element={<CoffeeProduct/>}/>
            <Route path='coffee-product/coffeeproduct-create/:id' element={<CoffeeProductCreate/>}/>
            <Route path='coffee-productgroup' element={<CoffeeProductGroup/>}/>
            <Route path='coffee-productgroup/coffeeproductgroup-create/:id' element={<CoffeeProductGroupCreate/>}/>


          </Route>
          <Route path='login' element={<LoginForm />} />
          <Route path='forgot-pass' element={<ForgotPassword />} />
          <Route path='reset-pass' element={<ResetPassword />} />
          <Route path='*' element={<ErrorPage />}></Route>
          
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  )
}


const mapStateToProps = ({ theme, auth, leftMenuReducer }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  const { initialRouteName } = leftMenuReducer;
  return { locale, direction, token }
};

export default (connect(mapStateToProps)(Router));

