import { SaveOutlined } from "@ant-design/icons";
import { Card, Checkbox, Col, Form, Input, message, Row, Select } from "antd";
import ReusableButton from "components/Elements/ReusableButton";
import network from "network/network";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
const ADD = 'ADD'


const CoffeeProductCreate = props => {

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { mode = ADD, param } = props

    const [form] = Form.useForm();

    const [productState, setProductState] = useState({
        productId: 0,
        productName: "",
        productShortName: "",
        productCode: "",
        productGroup: "",
        productGroupId: undefined,
        isGiftProduct: false,
        status: true,
        productImagePath: "https://static.vecteezy.com/system/resources/thumbnails/023/513/862/small_2x/hot-coffee-cup-with-coffee-beans-wallpaper-coffee-photo.jpg"

    });
    const [productGroupData, setProductGroupData] = useState([]);
    const [getDetailLoading, setGetDetailLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        if (id > 0) {
            selectProductDetail();
            selectProductGroupDetail();
        } else {
            setGetDetailLoading(false);
            selectProductDetail();
            selectProductGroupDetail();

        }
    }, [form, mode, param, props])

    const selectProductGroupDetail = async () => {
        var resultProductGroup = await network.get("/admin/CoffeeProduct/getAllProductGroups", false);
        const { data } = resultProductGroup.data;

        setProductGroupData(data);

    }

    const selectProductDetail = async () => {
        setGetDetailLoading(true);

        var result = await network.get("/admin/CoffeeProduct/getAllProductsWithProductName", false);

        setTimeout(() => {
            setGetDetailLoading(false);
        }, 100);

        const { data } = result.data;


        var index = data.findIndex(w => w.productId == id);
        if (result.isSuccess) {
            setProductState({
                productId: id,
                productGroup: data[index].productGroup,
                productGroupId: data[index].productGroupId,
                productName: data[index].productName,
                productCode: data[index].productCode,
                productShortName: data[index].productShortName,
                isGiftProduct: data[index].isGiftProduct
            });


        }
    }


    const onFinish = async () => {

        if (productState.productName.length == 0 || productState.productGroupId == undefined) {
            message.error(t('requiredFields'));
            return;
        }


        var params = {
            productId: parseInt(productState.productId),
            productName: productState.productName,
            productShortName: productState.productShortName,
            isGiftProduct: productState.isGiftProduct,
            productCode: productState.productCode,
            productImagePath: productState.productImagePath,
            productGroupId: productState.productGroupId,
            status: true
        }

        var method = "AddProduct";
        if (params.productId > 0) {
            method = "UpdateProduct";
        }

        var result = await network.post(`/admin/CoffeeProduct/${method}`, params);


        if (result.isSuccess) {
            var postResult = result.data;
            if (postResult.isSuccess) {

                message.success(t('groupSavedSuccess'));

                setTimeout(() => {
                    navigate(`/coffee-product`)
                }, 250);

            } else {
                alert(postResult.errorMessage);
            }

        } else {
            alert(result.errorMessage);
        }


    };

    return (
        <>
            <Helmet title={id > 0 ? t("updateProduct") : t("addProduct")} />
            <Form layout="vertical" name="advanced_search" className="ant-advanced-search-form">
                <div>
                    <Row gutter={24} style={{ marginTop: 20 }}>
                        <Col xs={24} sm={24} md={24}>
                            <Card title={t("productInfo")}>
                                <Col span={10} className="mb-3">
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-item-required" htmlFor="customerName">{t("productName")}</label>
                                    </div>
                                    <Input
                                        value={productState.productName}
                                        defaultValue={productState.productName}
                                        onChange={(val) => {
                                            setProductState({
                                                ...productState,
                                                productName: val.target.value,
                                            });
                                        }}
                                        placeholder={t("enterProductName")}
                                    />
                                </Col>
                                <Col span={10} className="mb-3">
                                    <div className="ant-col ant-form-item-label">
                                        <label htmlFor="customerName">{t("productDesc")}</label>
                                    </div>
                                    <Input
                                        value={productState.productShortName}
                                        defaultValue={productState.productShortName}
                                        onChange={(val) => {
                                            setProductState({
                                                ...productState,
                                                productShortName: val.target.value,
                                            });
                                        }}
                                        placeholder={t("enterProductDesc")}
                                    />
                                </Col>

                                <Col span={10} className="mb-3">
                                    <div className="ant-col ant-form-item-label">
                                        <label htmlFor="customerName">{t("productCode")}</label>
                                    </div>
                                    <Input
                                        value={productState.productCode}
                                        defaultValue={productState.productCode}
                                        onChange={(val) => {
                                            setProductState({
                                                ...productState,
                                                productCode: val.target.value,
                                            });
                                        }}
                                        placeholder={t("enterProductCode")}
                                    />
                                </Col>
                                <Col span={10} className="mb-3">
                                    <div className="row">
                                        <div className="ant-col ant-form-item-label">
                                            <label className="ant-form-item-required" htmlFor="customerName" style={{ marginRight: '10px' }}>{t("productGroup")}</label>
                                            <Select
                                                style={{ width: "100%" }}
                                                showSearch
                                                placeholder={t("selectProductGroup")}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                value={productState.productGroupId}
                                                options={productGroupData.map((i) => ({ value: i.productgroupid, label: i.productgroupname }))}
                                                onChange={(value) => {
                                                    setProductState({
                                                        ...productState,
                                                        productGroupId: value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col span={10} className="mb-3">
                                    <div className="row">
                                        <div className="ant-col ant-form-item-label">
                                            <label  htmlFor="customerName" style={{ marginRight: '10px' }}>{t("giftProduct")}</label>
                                            <Checkbox
                                                checked={productState.isGiftProduct}
                                                onChange={(val) => {
                                                    setProductState({
                                                        ...productState,
                                                        isGiftProduct: val.target.checked,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
                                    <div>
                                        <ReusableButton
                                            variant="campaign"
                                            text={t('save')}
                                            htmlType="submit"
                                            icon={<SaveOutlined />}
                                            onClick={() => onFinish()}
                                        />
                                    </div>

                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>

        </>
    )
}

export default CoffeeProductCreate