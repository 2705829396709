import React, { useState, useEffect } from "react";
import { Layout, Select } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import CustomSelect from "./CustomSelect";

const { Sider } = Layout;



export const SideNav = ({ navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true,  selectedValue, handleChange}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization }
  // const [selectedValue, setSelectedValue] = useState("");
  const [menuListData, setMenuListData] = useState([]);

  useEffect(() => {
    const storedMenuList = JSON.parse(localStorage.getItem("LEFT_MENU_LIST")) || [];

  
    setMenuListData(storedMenuList);
    if (storedMenuList.length > 0) {
      handleChange(storedMenuList[0].appName, storedMenuList);
    }
  }, []);

  // const handleChange = (value) => {
  //   setSelectedValue(value);
  //   const selectedApp = menuListData.find((app) => app.appName === value);
  //   if (selectedApp) {
  //     localStorage.setItem('selectedApp', JSON.stringify(selectedApp));
  //   }
  // };

  // useEffect(() => {
  //   const storedSelectedApp = JSON.parse(localStorage.getItem('selectedApp'));
  //   if (storedSelectedApp != null) {
  
  //     setSelectedValue(storedSelectedApp.appName);
  //   }
  // }, []);

  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', padding: '8px', backgroundColor: "white" }}>
          {(!menuListData.length || menuListData.length === 1) ? null : (<CustomSelect menuListData={menuListData} selectedValue={selectedValue} 
          handleChange={(value)=>handleChange(value, menuListData)} />)}
          <MenuContent
            type={NAV_TYPE_SIDE}
            selectedValue={selectedValue}
            {...props}
          />
        </div>
      </Scrollbars>

    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme }
};

export default connect(mapStateToProps)(SideNav);
