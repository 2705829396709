
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, message, Input, Row, Col, Card, DatePicker, TimePicker } from 'antd';

import network from 'network/network';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReusableButton from 'components/Elements/ReusableButton';
import { SaveOutlined } from '@ant-design/icons';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeTr from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';


import { DATETIME_FORMAT } from 'constants/DateConstant';
import Flex from 'components/shared-components/Flex';

const ADD = 'ADD'

const EventCreate = props => {
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();



	const { mode = ADD, param } = props

	const [form] = Form.useForm();

	const [submitLoading, setSubmitLoading] = useState(false);
	const [getDetailLoading, setGetDetailLoading] = useState(true);
	const [firmSellerList, setFirmSellerList] = useState([]);
	const [selectedFirmSeller, setSelectedFirmSeller] = useState(-1);

	const { id } = useParams();

	const [eventState, setEventState] = useState({
		eventName: "",
		eventDesc: "",
		eventMessageKey: "",
		startDate: "",
		endDate: "",
		startTime: "",
		endTime: "",
		eventMessage:"",
		eventStandMessage:""
	});

	useEffect(() => {
		if (id > 0) {
			selectEventDetail();
		} else {
			setGetDetailLoading(false);
		}

	}, [form, mode, param, props]);

	const selectEventDetail = async () => {

		setGetDetailLoading(true);
		const params = {
			eventId: parseInt(id) || 0
		}
		var result = await network.get("/admin/event/event-detail", params);

		setTimeout(() => {
			setGetDetailLoading(false);
		}, 100);

		if (result.isSuccess) {
			console.log(result);
			setEventState(result.data);
		} else {
			message.error(t("networkError"));
		}
	}

	const getTimeFromDate = (date) => {

		let hours = date.hour();    // Saat
		let minutes = date.minute();  // Dakika

		// Saat ve dakikayı : ile ayırarak birleştir
		let timeString = hours + ":" + (minutes < 10 ? "0" + minutes : minutes);

		return timeString;
	}

	const onFinish = async (values) => {


		if (!eventState.startTime || !eventState.endTime || !eventState.startDate || !eventState.endDate) {
			message.error(t("requiredFields"));
			return;
		}

		setSubmitLoading(true)

		const params = {
			...values,
			eventId: parseInt(id) || 0,
			startTime: (eventState.startTime),
			endTime: (eventState.endTime),
			startDate: new Date(eventState.startDate),
			endDate: new Date(eventState.endDate)
		}

		var result = await network.post(`/admin/event/insert-update-event`, params);

		setSubmitLoading(false)
		if (result.isSuccess) {
			message.success(t('groupSavedSuccess'));

			setTimeout(() => {
				navigate(`/events`)
			}, 250);

		} else {
			message.error(t("networkError"));
		}


	};

	const onChangeDate = (date, dateString, name) => {

		const result = name.indexOf("Time") != -1 ? dateString : date
		form.setFieldValue(name, result);
		setEventState({
			...eventState,
			[name]: result
		});
	};
	if (getDetailLoading) {
		return (
			<div>

			</div>
		);
	}


	const handleChange = (e) => {
		// Kullanıcının boşluk girmesini engelleme
		const value = e.target.value;
		const name = e.target.name;
		if ((!value.includes(' ')) && name =="eventMessageKey") {
			form.setFieldsValue({ eventMessageKey: value });
		}else {
			form.setFieldsValue({ [name]: value });
		}
	};

	const formatDayJsDate = (formValue) => {
		const format = 'DD.MM.YYYY';
		var value = dayjs((formValue), format)
		if (!value.day()) {
			value = dayjs((new Date(formValue)))
		}
		return value;
	}
	const getDatePicker = (name, title, requiredMessage) => {

		const formValue = eventState[name] || null;
		const format = 'DD.MM.YYYY';
		const value = typeof formValue === 'string' ? formatDayJsDate(formValue) : formValue

		return (
			<Col span={8} className="mb-3 mr-3">
				<div className="ant-col ant-form-item-label">
					<label className="ant-form-item-required" htmlFor="customerName">{title}</label>
				</div>


				<DatePicker needConfirm={false} allowClear={false} value={value} className='ant-input'
					placeholder={title} format={"DD.MM.YYYY"} picker='date'
					onChange={(date, dateString) => onChangeDate(date, dateString, name)}

				/>


			</Col>
		);
	}

	const getTimePicker = (name, title, requiredMessage) => {
		const format = 'HH:mm';
		const formValue = eventState[name] || null;
		const value = typeof formValue === 'string' ? dayjs(formValue, format) : formValue
		return (
			<Col span={8} className="mb-3">
				<div className="ant-col ant-form-item-label">
					<label className="ant-form-item-required" htmlFor="customerName">{title}</label>
				</div>

				<TimePicker showNow={false} minuteStep={5} allowClear={false} value={value} className='ant-input'
					placeholder={title} format={format} picker='date'
					onChange={(date, dateString) => onChangeDate(date, dateString, name)}
					popupStyle={{
						width: 150
					}}
				/>


			</Col>
		);
	}

	const addParameterToStandMessage =(paramName)=>{

		const message = form.getFieldValue("eventStandMessage") ||"";

		const textarea = document.getElementById('eventStandMessage');
		const cursorPosition = textarea.selectionStart;
		const beforeText = message.substring(0, cursorPosition);
		const afterText = message.substring(cursorPosition);
		
		// Yeni metin
		const newMessage = `${beforeText} ${paramName} ${afterText}`;

 
		form.setFieldValue("eventStandMessage", newMessage);

	}

	return (
		<>
			<Helmet title={id > 0 ? t('updateEvent') : t('addEvent')} />
			<Form layout="vertical" name="advanced_search" className="ant-advanced-search-form"
				initialValues={eventState}
				onFinish={onFinish}
				form={form}

			>
				<div>
					<Row gutter={24} style={{ marginTop: 20 }}>
						<Col xs={24} sm={24} md={24}>
							<Card title={t('eventInformations')}>
								<Row>
									<Col span={8} className="mr-3">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="eventName">{t('EventName')}</label>
										</div>
										<Form.Item name={"eventName"}
											rules={[
												{
													required: true,
													message: t('eventNameReq'),
												}
											]}
										>
											<Input name={"eventName"} 
												placeholder={t('EventName')}
											/>
										</Form.Item>

									</Col>
									<Col span={8} className="">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="eventDesc">{t('EventDescription')}</label>
										</div>
										<Form.Item name={"eventDesc"}
											rules={[
												{
													required: true,
													message: t('eventDescReq'),
												}
											]}>
											<Input name={"eventDesc"}
												placeholder={t('EventDescription')}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>

									{getDatePicker("startDate", t("StartDate"), t("startDateReq"))}
									{getTimePicker("startTime", t("StartTime"), t("startTimeReq"))}
								</Row>


								<Row>
									{getDatePicker("endDate", t("EndDate"), t("endDateReq"))}
									{getTimePicker("endTime", t("EndTime"), t("endTimeReq"))}
								</Row>


								<Row>
									<Col span={8} className="mb-3 mr-3">

										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="eventMessageKey">{t('EventMessageKey')}</label>
											<label className="" style={{
												fontSize: 13,
												color: "gray",
												fontWeight: "normal"
											}} >{t('eventMessageKeyDesc')}</label>
										</div>
										<Form.Item name={"eventMessageKey"}
											rules={[
												{
													required: true,
													message: t('eventMessageKeyReq'),
												},
												{
													pattern: /^[^\s]+$/, // Boşluk karakterini engelleyen bir regex
													message: t("allowSingleWordError"),
												},
											]}
										>
											<Input onChange={handleChange} name={"eventMessageKey"}
												placeholder={t('EventMessageKey')}
											/>
										</Form.Item>
									</Col>
									<Col span={8} className="mb-3 mr-3">
										<div className="ant-col ant-form-item-label">
											<label className="ant-form-item-required" htmlFor="eventMessage">{t('eventMessage')}</label>
										</div>
										<Form.Item name={"eventMessage"}
											rules={[
												{
													required: true,
													message: t('eventMessageReq'),
												},
											]}
										>
											<Input.TextArea style={{ minHeight: 83 }} name={"eventMessage"}
												placeholder={t('eventMessage')}
											/>
										</Form.Item>
									</Col>

								</Row>
								<Col span={8} className="mb-3 mr-3">
									<div className="ant-col ant-form-item-label">
										<label className="ant-form-item-required" htmlFor="eventStandMessage">{t('eventStandMessage')}</label>
									</div>
									<Form.Item  name={"eventStandMessage"}
										rules={[
											{
												required: true,
												message: t('eventStandMessageReq'),
											},
										]}
									>
										<Input.TextArea id='eventStandMessage' name={"eventStandMessage"} style={{ minHeight: 83 }}
											placeholder={t('eventStandMessage')}
										/>
									</Form.Item>


									<Flex flexDirection='column' >
									 
										<ReusableButton
											variant="default"
											text={t('standCode')}
											htmlType="button"
											className={"max-w-250"}
											hideIcon={true}
											onClick={()=>addParameterToStandMessage("{standCode}")}
										/>
									 
										<div className='mt-2' />
										<ReusableButton
											variant="default"
											text={t('standDesc')}
											className={"max-w-250"}
											htmlType="button"
											hideIcon={true}
											onClick={()=>addParameterToStandMessage("{standDesc}")}
										/>
										<div className='mt-2' />
										<ReusableButton
											variant="default"
											text={t('attendeeFirmName')}
											className={"max-w-250"}
											htmlType="button"
											hideIcon={true}
											onClick={()=>addParameterToStandMessage("{firmName}")}
										/>
										<div className='mt-2' />
										<ReusableButton
											variant="default"
											text={t('attendeeContactName')}
											className={"max-w-250"}
											htmlType="button"
											hideIcon={true}
											onClick={()=>addParameterToStandMessage("{contactName}")}
										/>
										<div className='mt-2' />
										<ReusableButton
											variant="default"
											text={t('attendeeContactPhone')}
											className={"max-w-250"}
											htmlType="button"
											hideIcon={true}
											onClick={()=>addParameterToStandMessage("{contactPhone}")}
										/>
									</Flex>
								</Col>
								<Col span={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', justifyItems: 'flex-end' }}>
									<div>
										<ReusableButton
											variant="campaign"
											text={t('save')}
											htmlType="submit"
											icon={<SaveOutlined />}
										/>
									</div>

								</Col>
							</Card>
						</Col>
					</Row>
				</div>
			</Form>
		</>
	)
}

export default EventCreate